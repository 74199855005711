import styled from "styled-components";
const EgyptSubscribePageDisplay = styled.div`
// background-image: linear-gradient(to bottom left,#101c03 0,#373736 100%); 
background: ${p => p.bgImg};

background-size: cover;
  .formsLayoutjawal
  {
    min-height: 114vh;
  }
  .formsLayoutmondia
  {
    min-height: 125vh;
    // background-image: linear-gradient(to bottom left,#101c03 0,#373736 100%);
    // background-size: cover;
    // margin : 0!important;

  }
  .formsLayoutEgypt
  {
    min-height: 166vh;
    .egyVerfiySection
    {
      width: 80%;
    margin: auto;
    }
    }
  .gamezoneSignup
  {
    background: rgb(238 90 17);
  }
  .header_gamezone
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid #65378b;
  }
  .links-Section{
    position: absolute;
    //border: 1px solid red;

      top: 30%;
  }
  .links-Sectionjawwalgamezorid
  {
    top: 9%!important;
    width: 75%;
  
    input
    {
      margin-top: 10px;
    }
    .gamezoneInput
    {
      background: white;
      color: black;
      padding: 1px 8px;
    }
    .btnmondia
    {
      text-transform: uppercase;
    width: 50%;
    font-size: 1.2em;
    //background-color: #8ce6e6;
    line-height: 2.5em;
    border: 2px solid #528383;
    border-radius: 5em;
    font-weight: 700;
    margin: 8px auto !important;
    }
    
  }

  .links-SectionMondia
  {
    top: 0%!important;
    width: 40%;
    height: 100%;
    background-color: #fff;
    background-image: linear-gradient(to bottom left,#101c03 0,#373736 100%);
   

  }

  .links-SectionEgy
  {
    // background-color: #ab2eb5;
    background: url("../../assets/images/background/eypbanner.png") no-repeat center center;

    .EgyiconSelect
    {
      .input
      {
        width: 47%;
    border: 1px solid #fff;
    border-radius: 15px;
    padding: 0px 8px;
    margin: 22px 0;
      }
    }
  }
 
  
 
  .links-Section a {
    color:white
  }
  .links-Section button{
    margin-bottom: 15px;
    display:block;
  }
  .links-Sectionjawwalgamezorid button{
    display: block;
    margin: 30px auto;
    min-width: 190px;
  }
  .formsLayout {
    margin: 0 20px;

  
    .mondiaFooter
    {
      p , h3
      {
        // font-family: duCo_Headline16_iOS_Lt;
        //  margin: 0;
        //  color: #dadada;

         color: #949494;
    font-size: 12px;
   // font-family: duCo_Headline16_iOS_Lt;
    font-weight: 500;

    padding: 0;
    margin: 0;

      }
      h4
      {
        font: inherit;
    color: #dadada;
    padding: 0 5px;
      }
    }
    .verfiySection
    {
      top: 26%!important;
    width: 61%;
    position: absolute;
    }
    .gamezoneLogo
    {
      max-width: 163px!important;
    }
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }

    }

    .mondiancellTos
    {
      h3 , p
      {
        color: #dadada;
      }
    }


    .duFooter
    {
      h4
      {
        color: #f8f2f2;
    font-size: 1.5em;
    font-weight: inherit;
    margin-top: 0.5em;
      }
      
    }
  

  

   
  }
`; export default EgyptSubscribePageDisplay;
