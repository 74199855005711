/* eslint-disable no-undef */
import React, { Component, useState, useEffect } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "axios";
import subscribe from "../../auth/subscribe";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import { setAgencyData, logtracker ,saveTracker} from "../../helpers/agencyTracker";
import "./NewAsiacellSubscribePage.scss";
import * as localLang from "../../i18n/i18n";
import NewAsiacellSubscribePageDisplay from "./NewAsiacellSubscribePage.styled";

import { getPhoneByHeader } from "./services"
import { isMobile } from 'react-device-detect';
import Modal from "react-modal";

function NewAsiacellSubscribePage({ t }) {

  const [enriched, setenriched] = useState(false);
  // const [activeData, setActiveData] = useState('');
  // const [headers, setHeaders] = useState('');
  const [clickId, setClickId] = useState('');


  const [headerData, setHeaderData] = useState({});


  const [showverify, setShowverify] = useState(false);
  const [showsubscribeSucess, setShowsubscribeSucess] = useState(false);

  const [showLoading, setshowLoading] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [visibleModal, setvisibleModal] = useState(false);

  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };






  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang !== 'ar' || !lang) {
      localStorage.setItem("i18nextLng", 'ar');
      localLang.setlangSubscribe();
    }
    if (urlsearch !== "") {
      //console.log("tracker")
       setAgencyData(urlsearch);
     }
     const companyName = localStorage.getItem("trackerCompany");
    fetchData('pv',companyName);
    fetchit();
    
    const urlsearch = window.location.search;
    

  }, [])
  const fetchData = async (event ,company ) => {
    try {
       await axios.get(`http://api.playit.mobi/api/v2/asia-cell-iraq/monitor?event=${event}&company=${company}`);
      //setData(response.data);
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };
  function gtag_report_conversion() {
    let tracker_value = localStorage.getItem('tracker');
      if(localStorage.getItem(tracker_value) != '1'){
        window.gtag('event', 'conversion', {
          'send_to': 'AW-619421651/C_ScCIHe_qcZENO_rqcC'
      });
      localStorage.setItem(tracker_value,'1')
    }
    return false;
  }



  async function fetchit() {
    var linkheader = "http://aciq.playit.mobi/checkheaderasiacell.php";
    const headerData = await getPhoneByHeader(linkheader);
    ////console.log('headerData', headerData.Headers);




    // HE
    if (headerData.Msisdn) {
      setHeaderData(headerData)
      setenriched(true);
      // setActiveData(phone_h);

      // var url = `http://api.playit.mobi/api/v2/asia-cell-iraq/antiFraud?headers=${headerData.Headers}&userip=${headerData.UserIP}&msisdn=${headerData.Msisdn}`;
      // setshowLoading(true);
      // axios.get(url)
      //   .then(res => {
      //    //console.log(res.data.status);
      //     if (res.data.status == true) {
      //       const addScript = document.createElement('script');
      //       //addScript.id = "addScript";
      //       addScript.innerHTML = res.data.message;
      //       // addScript.src = res.data.message;
      //       document.body.appendChild(addScript);
      //       setClickId(res.data.clickId)
      //       setshowLoading(false);
      //       setShowverify(true)
      //       // window.location.href = res.data.url
      //       // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
      //     }

      //     else {
      //       setErrorMesg(true);
      //       setErrorMesgSubscribe(res.data.message)
      //       // setshowagin(true)
      //     }

      //     setshowLoading(false);


      //   })
      //   .catch(function (error) {
      //     // setErrorMesg(true)
      //   })
      //   .finally(function () {
      //    //console.log('complete');
      //   });

    }


  }


      // save tracker
  const tracker = async (GMSISDN) => {
        var datag = {};
        if (localStorage.getItem('tracker')) {
          var op_id = 'mOR5fK6fLj';
    
    
          datag = {
            key: 'parameter',
            company: localStorage.getItem("trackerCompany"),
            value: localStorage.getItem('tracker'),
            identifierKey: 'id',
            identifierValue: op_id,
            phoneNum: GMSISDN
          };
         //console.log(datag)
    
        }
        await saveTracker(datag);
    
  }

  const signup = async (e) => {
    e.preventDefault();
   //console.log("Subscribe", isMobile);
    // window.gtag('event', 'conversion', {
    //   'send_to': 'AW-619421651/h391CMWV4dcYENO_rqcC'
    // });
   //console.log("after conversion");
    if (isMobile) {
      window.location.href = 'sms:2231;?&body=1';
    }
    else{
      
      setvisibleModal(true);
      setTimeout(() => {
        setvisibleModal(false);

      }, 5000);

    }

    // fetchit();
  }

  const headerSignup = async (e) => {
    e.preventDefault();
    const companyName = localStorage.getItem("trackerCompany");

    fetchData('1st_click', companyName);

   //console.log("header Subscribe");
    var url = `http://api.playit.mobi/api/v2/asia-cell-iraq/antiFraud?headers=${headerData.Headers}&userip=${headerData.UserIP}&msisdn=${headerData.Msisdn}`;
    setshowLoading(true);
    axios.get(url)
      .then(res => {
       //console.log(res.data.status);
        if (res.data.status == true) {
          const addScript = document.createElement('script');
          // addScript.id = "addScript";
          addScript.innerHTML = res.data.message;
          document.head.appendChild(addScript);
          setClickId(res.data.clickId)
          setshowLoading(false);

          setShowverify(true)
          // window.location.href = res.data.url
          // window.location.href = 'http://login.mondiamediamena.com/billinggw-lcm/billing?merchantId=197&operatorId=22&redirect=http://dzo.playit.mobi/mondiaverify/W&method=subscribe&imgPath=http%3A%2F%2Fapi.playit.mobi%2Fgames-photos%2Fmondia.png&productCd=PLAYITALGERIA&subPackage=W';
        }

        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
          // setshowagin(true)
        }

        setshowLoading(false);


      })
      .catch(function (error) {
        // setErrorMesg(true)
      })
      .finally(function () {
       //console.log('complete');
      });
  }

  // function gtag_report_conversion() {
  //   window.gtag('event', 'conversion', {
  //       'send_to': 'AW-619421651/h391CMWV4dcYENO_rqcC'
  //   });
  // }
  
  const verfiy = async (e) => {
    e.preventDefault();
    const companyName = localStorage.getItem("trackerCompany");

    fetchData('2nd_click', companyName);
    var url = `http://api.playit.mobi/api/v2/asia-cell-iraq/subscribe?msisdn=${headerData.Msisdn}&clickID=${clickId}`;
    setshowLoading(true);
    axios.get(url)
      .then(res => {
       //console.log(res);
       //console.log(res.data.status);
        // window.gtag('event', 'conversion', {
        //   'send_to': 'AW-619421651/h391CMWV4dcYENO_rqcC'
        // });
        
        if (res.data.status == true) {
          gtag_report_conversion();
          tracker(headerData.Msisdn);
          setshowLoading(false);
          setShowsubscribeSucess(true)

          if (res.data.user_id) {
            setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            setTimeout(() => {
              window.location.href = "/";

            }, 3000);
          }

          // setShowverify(true)

        }

        else {
          setErrorMesg(true);
          setErrorMesgSubscribe(res.data.message)
          // setshowagin(true)
        }

        setshowLoading(false);


      })
      .catch(function (error) {
       //console.log(error);
        // setErrorMesg(true)
      })
      .finally(function () {
       //console.log('complete');
      });
  }
  // const { t } = this.props;

  return (
    <NewAsiacellSubscribePageDisplay className="" bgImg="">

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {showLoading ?
        <div className="load">
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : null}

      {/*} {verfiErrorMesg ? (
      <FloatingAlert type={"error"}>
        <Trans>register.VERIFYERROR</Trans>
      </FloatingAlert>
   ) : null} */}

      <div className="formPage">
        <form
        // onSubmit={(e) => {
        //   signup(e);
        // }}
        >
          <div className="formsLayout">
            <div className="header">
                <div className="logo" />
            </div>
            <div className="links-Section links-SectionGamezorid">
              <div className="games_img"></div>



              <div className="normBtn">
                <p className="free_text"><Trans>asiacellSubscribe.part1</Trans></p>





                {showverify ?
                  <div>
                    <button className="btn AFsubmitbtn" type="button" onClick={(e) => verfiy(e)}>
                      <Trans>asiacellSubscribe.confirm</Trans>
                    </button>
                    {showsubscribeSucess ?

                      <p className="sub-sucess">
                        <Trans>asiacellSubscribe.subscribeSucess</Trans>
                      </p>
                      : ""}
                  </div>

                  :
                  <div>
                  <button className="btn" type="button"
                    onClick={(e) => {
                      enriched ? headerSignup(e) :  signup(e)

                    }}
                  >
                    <Trans>asiacellSubscribe.subscribe</Trans>

                  </button>

                 

                <Modal
                isOpen={visibleModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
               
  
                <div className="btnsModal btnsMobModal">
                <p className="messageModal">
                <Trans>asiacellSubscribe.message</Trans>
              </p>
                 
                </div>
              </Modal>
                </div>



                }
              </div>
              <div>
                <p className="sub_text"><Trans>asiacellSubscribe.part2</Trans></p>
              </div>


            </div>


            <div className="footer">

              <p>
                <Trans>accept_subscribe</Trans>{" "}
                <Link to="tos">
                  <Trans>terms_and_conditions</Trans>
                </Link>
              </p>
              <div className="header header-ksa">
                <Link to="/">
                  <div className="logo singupLogo" />
                </Link>
              </div>
            </div>


          </div>
        </form>
      </div>
    </NewAsiacellSubscribePageDisplay>
  );

}
export default withTranslation()(NewAsiacellSubscribePage);
