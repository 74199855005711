import styled from "styled-components";
import sad from "../../../assets/images/sad.svg";

const SharedUnsubscribeDisplay = styled.div`

`;
export default SharedUnsubscribeDisplay;

export const Unsubscribe = styled.div`
    min-height: 80vh;
    display:flex;
    flex-direction: column;
    .linkJawal
    {
      color: #fff;
      border: 1px solid #fff;
      width: 40%;
      margin: auto;
      padding: 18px 16px;
      margin-top: 26px;
      border-radius: 35px;
      max-width: 229px;
    }
  .sad{
    background: url(${sad}) center center no-repeat;
    height: 82px;
    padding: 56px 0;
  }
  p.confirmQues{
    font-size: 16px;
    font-weight: bold;
    color: var(--unSubscribeColor);
  }
  a.unsubscribe,a.unsubscribe:hover{
    text-decoration: none;
    opacity: 0.46;
    font-size: 16px;
    
  }
`;
