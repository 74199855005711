import styled from "styled-components";
const FreePointPage = styled.div`
min-height: 119vh;
background: var(--insideBg);
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top: 0;
width: 100%;
@media(max-width: 1024px){
  background-position: center;
}
@media(max-width: 768px){
  min-height: 130vh;
}

.freepoints
{
  display: flex;
    flex-flow: column;
    width: 30%;
    margin: auto;
    margin-top: 85px;
    align-items: center;
   
    @media(max-width: 768px){
      width: 46%;
      margin-top: 151px;
    }
    @media(max-width: 600px){
      width: 66%;
      margin-top: 85px;

    }
    @media(max-width: 500px){
      width: 85%;
      margin-top: 85px;

    }
.friendtext
    {
      line-height: 26px;
    margin: 7px;
    font-weight: bold;
    font-family: revert;
    }
    .free
    {
      width: 219px;
    height: 191px;
    }
    .sound
    {
      margin-bottom: 13px;
    }
    .freebtn
    {
      width: 75%;
      border-radius: 40px;
      background-color: #fff;
    }
    .whats
    {
    background-color: #00D948;

    }
}


`;
export default FreePointPage;



