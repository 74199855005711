import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./NcellSignupPage.styled";
import NcellSignupPageDisplay from "./NcellSignupPage.styled";
import { getRedirectUrl, ncellLogin, subscribe } from "./services";
import { setAgencyData , logtracker} from "../../../helpers/agencyTracker";


const randomBg = bgSliders.getRandomBg();
const plans = [
  {
    plan: "1",
    name: "Weekly Renewal@Rs 15.95+4.42(taxes)"
  },
  {
    plan: "2",
    name: "Weekly Onetime@Rs 15.95+4.42(taxes)"
  },
  {
    plan: "3",
    name: "Daily Onetime@Rs 3.99+1.10(taxes)"
  }
];
export default function NcellSignupPage(props) {
  const [activeData, setActiveData] = useState("");
  const [signupPlan, setSignupPlan] = useState("");
  const [alert, setAlert] = useState(false);
  useEffect(() => {
    //agency tracker
    const url = window.location.href
    if (url !== "") {
      setAgencyData(url);
    }
    async function fetchit() {
      const num = await ncellLogin();
      if (num) {
        setActiveData(num);
      } else {
        setAlert(true);
      }
    }
    fetchit();
    async function subscribeUser() {
      if (props.location.search) {
        const values = queryString.parse(props.location.search);
        if (values.cpTransactionID) {
          const phone = values.phone ? values.phone : activeData;
          const subscribed = await subscribe(phone, values.cpTransactionID);
          if (subscribed) props.history.push("/");
        }
      }
    }
    subscribeUser();
  }, []);

  const signup = async e => {
    e.preventDefault();
    if (activeData && signupPlan) {
      
      var trackerCompany = localStorage.getItem('trackerCompany');
      var tracker =  localStorage.getItem('tracker');
      logtracker(trackerCompany , tracker);

      const redirectUrl = await getRedirectUrl(activeData, signupPlan);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  return (
    <NcellSignupPageDisplay className="" bgImg={randomBg}>
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}
      <div className="operatorSignup">
        <div className="formsLayout">
          <div className="header">
            <Link to="/">
              <div className="logo" />
            </Link>
          </div>
          <div className="center">
            <form>
              <div className="inputs">
                <select className="select">
                  <option value="">Nepal (+977)</option>
                </select>
                <input
                  type="text"
                  className="input"
                  placeholder="Enter your phone number"
                  value={activeData}
                  onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                />
              </div>
              <div className="iconSelect">
                <i className="icon money" />

                <select
                  className="select"
                  defaultValue={signupPlan}
                  onChange={e => setSignupPlan(e.target.value)}
                >
                  <option>Subscribtion Plan</option>
                  {plansOptions}
                </select>
              </div>
            </form>
          </div>
          <div className="footer">
            <div>
              <button
                className="btn"
                disabled={activeData ? "" : "disabled"}
                onClick={e => {
                  signup(e);
                }}
              >
                Signup
              </button>
            </div>
            <div className="ncellTos">
              <p>TERMS & CONDITIONS</p>
              <p>
                On subscribing to "PlayIt" you will be charged as per the pack
                selected. Subscription will be renewed automatically in Renewal
                packs until you unsubscribe. You can unsubscribe from this
                service anytime by sending "STOP" to 17146. Data charges apply
                For support, please contact
              </p>
              <a href="mailto:playit@grandmstg.com">playit@grandmstg.com</a>
            </div>
          </div>
        </div>
      </div>
    </NcellSignupPageDisplay>
  );
}
