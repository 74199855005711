import styled from "styled-components";
const FloatingAlertDisplay = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  // height: 40px;
  width: 100%;
  background: ${p => {
    if(p.type === "error"){
      return "#d33131"
    } else if(p.type === "success") {
      return "#28a745"
    }else{
      return "var(--primaryColor)"
    }
  }};
  font-family: SourceSansPro;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default FloatingAlertDisplay;
