import axios from 'axios';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import FloatingAlert from '../../components/FloatingAlert/FloatingAlert';
import './AdsPage.scss';
import AdsPageDisplay from './AdsPage.styled';
const apiUrl = envo.apiUrl;
class AdsPage extends Component {
	constructor() {
		super();
		this.state = {
			
		};
	}

	componentDidMount() {
		
	}
	// async getTerms(operatorId, lang) {
	// 	const params = {
	// 		"operator_id": operatorId,
	// 		"locale": lang
	// 	};
	// 	try {
	// 		const res = await axios.get(`${apiUrl}/playit/terms`, { params });
	// 		if (!res.data) return false;
	// 		const data = res.data;
	// 		if (data.status !== "success") {
	// 			return false;
	// 		}
	// 		if(data.data){
	// 			return this.setState({
	// 				content: data.data.term
	// 			});
	// 		}
	// 	} catch (err) {
	// 		if (err.response && err.response.status !== 200) {
	// 			this.setState({
	// 				errors: true,
	// 				errorMsg: this.props.t("network_failed")
	// 			});
	// 		}
	// 		console.log(err);
	// 		return false;
	// 	}
	// }
	render() {
		const { } = this.state;
		return (
			<>
				<AdsPageDisplay>
					
					<div className="Page-bgImg"></div>
					
				</AdsPageDisplay>
			</>
		)
	}
}
export default withTranslation()(AdsPage)