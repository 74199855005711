import React, { Component } from 'react';
import './VideosPage.scss';
import VideosPageDisplay from './VideosPage.styled';
import ReactPlayer from "react-player";

import { Trans, withTranslation } from "react-i18next";

var d = new Date();

export default class VideosPage extends Component {
	state = {
		ar_videoUrl_1: "https://www.youtube.com/watch?v=8uFBvK_eLhE",
		ar_videoUrl_2: "https://www.youtube.com/watch?v=DMoAsa0MZjU",
		en_videoUrl_1: "https://www.youtube.com/watch?v=hB0QNR4JuSc",
		en_videoUrl_2: "https://www.youtube.com/watch?v=hzH4SRRuIM0",
	}
	componentDidMount() {	
		if(localStorage.getItem("i18nextLng") == "ar"){
			this.setState({en_videoUrl_1:this.state.ar_videoUrl_1,en_videoUrl_2:this.state.ar_videoUrl_2});
		}else{
			this.setState({en_videoUrl_1:this.state.en_videoUrl_1,en_videoUrl_2:this.state.en_videoUrl_2});
		}
	}
	
	render() {
		
		console.log( d.getDate())
		return (

			<VideosPageDisplay>

				<div className="video-block">
					<h2 className="title"><Trans>video.Didacticiel</Trans></h2>
					<ReactPlayer
						height="200px"
						width="100%"
						controls={true}
						url={this.state.en_videoUrl_1} controls={true}
					/>
				</div>
				<div className="video-block">
					<h2 className="title"><Trans>video.tutorial</Trans></h2>
					<ReactPlayer
						height="200px"
						width="100%"
						controls={true}
						url={this.state.en_videoUrl_2} controls={true}/>
				</div>
			</VideosPageDisplay>
		)
	}
}
