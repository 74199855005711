import styled from "styled-components";
import starStorke from "../../assets/icons/favourite-stroke.svg";
import starSolid from "../../assets/icons/favourite.svg";

const EgyLoginPageDisplay = styled.div`
.continer{
  position: fixed;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.logo{
  margin: 0 auto;
  height: 95px;
    width: 148px;
}
.msg-block{
 
  font-size: 20px;
}
.msg-block button  {
  margin: 10px
}
`;
export default EgyLoginPageDisplay;


