import styled from "styled-components";
const CompetitionGamesDisplay = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //padding-right: 10px;
  .containerComp
  {
    background: var(--homepageBgCompetition) no-repeat center center;
    width: 100%;
   // height: 965px;
    position: absolute;
    top: 0;
    z-index: 100;
    min-height: 125vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-size: cover

  }
  .compHome
  {
    //background: url('../../assets/images/competition/bg.jpg') no-repeat center center;

      
  }
  .contentComp
  {
    width: 75%;
    height: 100px;
    border: 1px solid green;
    position: absolute;
    top: 12%;
  }
  .links-Section
  {
    position: absolute;
    width: 75%;
  }
  .links-SectionCompetition
  {
    top: 2%;
    width: 86%;
    .compbtn
    {
      width: 50%;
      min-width: 50%;
      margin: 0 auto;
      display: flex;
    justify-content: space-between;
    div
    {
      height: 51px;
    }
    }

  }
  .headerCompetion
  {
    margin-top: 8px;
    .logoComp
    {
        background: var(--competitionLogo)center center no-repeat;
        height: 51px;
        width: var(--navbarcomLogoWidth);
        max-width: 350px;
        //background-size: var(--background-size);
        margin-left: 0;
        background-size: contain;

    }
  }
  
`;
export default CompetitionGamesDisplay;
