import styled from "styled-components";
const PaymentPageDisplay = styled.div`
  p {
    font-size: 13px;
    line-height: 1.25;
    letter-spacing: 0.12px;
    margin: 0 0 7px 0;
    color:var(--textColor);
    min-height: 35px;
  }
  .header {
    
    text-align: left;
    .title {
      font-size: 18px;
      line-height: 1.22;
      margin: 20px 0px 7px;
      color: var(--paymentTextcolor)!important
    }
    .describe {
      font-size: 12px;
      line-height: 1.22;
      letter-spacing: 0.03px;
      opacity: 0.5;
      color: var(--paymentTextcolor)!important
    }
  }
  .flex {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .selectGame
  {
    background-color: #2e353b;
    width: calc(100% - 13px);
    display:block;
    margin : 30px 0 14px 8px;
    border-radius: 5px;
    padding-left: 10px;
    height: 48px;

    selectGame::-ms-expand {
     color:red
    }


  }
  
  .formsLayout {
    margin: 0 20px;
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      margin-bottom: 10px!important;
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }
  }
`;
export default PaymentPageDisplay;

export const Payment = styled.div`
  padding: 20px;
`;

export const Card = styled.div`
  background: var(--lightBgColor);
  padding: 15px 12px;
  border-radius: 8px;
  max-width: 129px;
  max-height: 123px;
  margin: 18px 0px 0px;
  a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
