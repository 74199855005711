import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";
import { setAgencyData, callTrackerCompany, saveTracker } from "../../helpers/agencyTracker";

import "./Thank-you-page.scss";


class asiacellThankYouPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    setTimeout(() => {
      this.fireAPI();
    }, 3000);

  }

  fireAPI = async () => {
    window.location.href = "/";

  }
  render() {

    return (
      <div className="container-loadaing">
        <div className="loadingLayout">
          <div className="keep-waiting">
            <div>
              <div className="sk-chase">

                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
              </div>
            </div>
            <div style={{ marginTop: '80px' }}>
              <h1>برجاء الانتظار</h1>
              <p>سيتم تحويلك الي الصفحه خلال لحظات</p>
            </div>
          </div>


        </div>

      </div>


    );
  }
}
export default withTranslation()(asiacellThankYouPage);
