import React, { Component } from 'react';
import './TutorialsPage.scss';
import TutorialsPageDisplay from './TutorialsPage.styled';

export default class TutorialsPage extends Component {
	render() {
		return (
			<TutorialsPageDisplay>
				TutorialsPage_Component
			</TutorialsPageDisplay>
		)
	}
}
