import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;

async function unsubscribe(code) {
  const options = {
    headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
    params: {
      code: code
    }
  };
  try {
    const res = await axios.post(`${apiUrl}/playit/unsubscribe`, options);
    if (!res.data) return false;
    const data = res.data;
    return data;
  } catch (err) {
   //console.log(err);
    return false;
  }
}
export default unsubscribe;
