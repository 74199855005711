import React, { Component } from "react";
import Img from "react-image";
import { Link } from "react-router-dom";
import failedImg from "../../assets/trux.png";
import h from "../../helpers/helpers";
import "./SingleGame.scss";
import SingleGameDisplay, { Title } from "./SingleGame.styled";
export default class SingleGame extends Component {
  render() {
    const { img, name, nameAr, gameID } = this.props;
    const gameName = h.getNameByLang(name, nameAr, "");
    return (
      <SingleGameDisplay>
        <Link to={`game/${gameID}`}>
          {/* <Thumb img={encodeURI(img)}></Thumb> */}
          <Img
            className="imgloader"
            src={img}
            alt={'loader'}
            loader={
              <div className="imgloader ">
                <div className="loadingPlayit"></div>
              </div>
            }
            unloader={
              <div className="imgloader">
                <img className=" onFail" src={failedImg} alt="failed" />
              </div>
            }
          />
          <Title className="gamesName">{gameName}</Title>
        </Link>
      </SingleGameDisplay>
    );
  }
}
