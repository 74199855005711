import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const InputDisplay = styled.input`
  background: transparent;
  border: 1px solid #707070;
  border-radius: 13px;
  margin: 0.32em;
  width: 1.2em;
  height: 2em;
  color: var(--textColor);
  padding: 0.24em;
  outline: none;
  font-size: 1.2em;
  text-align: center;
`;
const codeLength = 6;
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function CodeInputs({ setCodeNumbers }) {
  const [inputsState, setInputsState] = useState([
    ...Array(codeLength)
      .fill()
      .map(i => "")
  ]);
  const itemsRef = useRef([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, inputsState.length);
  }, [inputsState]);

  const handleKeyPress = (e, k) => {
    const nextRef = itemsRef.current[k + 1];
    const updatedInputs = [...inputsState];
    const enteredNumber = parseInt(e.key);
    if (numbers.includes(enteredNumber)) {
      updatedInputs[k] = enteredNumber;
      setInputsState([...updatedInputs]);
      setCodeNumbers([...updatedInputs]);
      k < codeLength - 1 && nextRef.focus();
    } else {
      // updatedInputs[k] = undefined;
      setInputsState([...updatedInputs]);
    }
  };
  return (
    <div className="inputs">
      {Array(codeLength)
        .fill()
        .map((i, k) => {
          return (
            <InputDisplay
              key={`input-${k}`}
              type="text"
              className="verifyInput"
              id={`verifyInput-${k}`}
              ref={el => (itemsRef.current[k] = el)}
              onKeyPress={e => handleKeyPress(e, k)}
              onChange={() => {}}
              value={inputsState[k]}
            />
          );
        })}
    </div>
  );
}
