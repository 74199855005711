import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmationCodeComponent from "../../components/ConfirmationCodeComponent/ConfirmationCodeComponent";
import bgSliders from "../../helpers/bgSliders";
import "./VerifyPage.scss";
import { VerifyPageDisplay } from "./VerifyPage.styled";
const randomBg = bgSliders.getRandomBg();
function VerifyPage({ t, history }) {
  return (
    <VerifyPageDisplay className="" bgImg={randomBg}>
      <ConfirmationCodeComponent
        pageName={"verify"}
        topHeader={"logo"}
        history={history}
        actionSuccessEndPoint={"/complete"}
        transText={{
          verification_code: t("verfication.verification_code"),
          didnotRecieve: t("verfication.didn't_receive_code"),
          resend_code: t("verfication.resend_code"),
          actionBtn: t("sign_up"),
          accept: t("accept_subscribe"),
          terms_and_conditions: t("terms_and_conditions")
        }}
      />
    </VerifyPageDisplay>
  );
}

export default withTranslation()(VerifyPage);
