var globalIframe, globalUrl;

var level , score;
var scoreBox = document.createElement("div");
var container = document.createElement("div");
  container.id ="container";
  var text = document.createElement("div");
  text.id = "scoreGame";

  document.getElementsByTagName("body")[0].appendChild(container);
////////// 3) Create the methods to listen from the game and send to the game. //////////
/**
 * This function will pause the game
 * @return {[type]} [description]
 */
function pauseTheGame() {
  // globalIframe.contentWindow.postMessage(
  //   {
  //     message: "pause",
  //   },
  //   globalUrl
  // );
}
/**
 * This function will resume the game
 * @return {[type]} [description]
 */
function resumeTheGame() {
  // globalIframe.contentWindow.postMessage(
  //   {
  //     message: "resume",
  //   },
  //   globalUrl
  // );
}
/**
 * This function will unmute the game
 * @return {[type]} [description]
 */
function soundOff() {
  // globalIframe.contentWindow.postMessage(
  //   {
  //     message: "soundOn",
  //   },
  //   globalUrl
  // );
}
/**
 * This function will be fired each time the game sends score at the end or between levels
 * @param {[type]} object = {
 * type : 'String' ['level_complete','game_over']
 * level : 'String' or number,
 * score : number
 * }
 * @return {[type]}
 */
function sendScore(object) {
  // here you have access to type, level and score
 //console.log("task" ,object , object.type);
  if(object.type== 'level_complete' || object.type == "game_over")
  {
    level = object.level;
    score = object.score;
    //var txt = document.getElementById("scoreGame");

    //document.getElementsByClassName("compiframe-container")[0].appendChild(txt)

    /*document.getElementsByClassName("compiframe-container")[0].style.top="35%"
    document.getElementsByClassName('PlayingComp')[0].style.height="34vh"
    document.getElementById('scoreBox').classList.add("scoretext")
    document.getElementById("text").innerHTML = "<span>score : </span>" + score;*/
    
  }
 //console.log(level , score)
}
/**
 * This function its' called once the game has completed it's loading and let the game starts.
 * @param {[type]} url [description]
 * @return {[type]} [description]
 */
function playGame(url) {
  globalUrl = url;
  //here's the function that tell the game starts once loaded
  //you can fire this function, for istance, after you've verified that the user is logged.
  // globalIframe.contentWindow.postMessage(
  //   {
  //     message: "callbackExecuted",
  //   },
  //   url
  // );
}
/**
 * Do what you want to do with percentage
 */
function loading(percentage) {
  // do your stuff
}
/**
 * Let's append the iframe with the game
 * @return {[type]} [description]
 */
 function init(url)
{
  if(localStorage.getItem('close') == 'true' || !localStorage.getItem('close'))
  {
    document.getElementById("container").innerHTML="";
    document.getElementsByClassName("compiframe-container")[0].style.top="0"
    document.getElementsByClassName('PlayingComp')[0].style.height="0"
    document.getElementById("container").classList.remove('iframe-container');
    document.getElementById("container").classList.remove('compiframe-container');

    


    //document.getElementById("game-frame").remove();

    //document.getElementById("container").remove();

    return;

  }

  if(document.getElementById('game-frame'))  return;
 //console.log('Iam here')

  //////// 1) Create the iframe that will contains the game ////////
  //<object type="text/html" data={url} scrolling="no" tabindex="0" id="object" allowFullScreen></object>
  
  document.getElementById("container").classList.add('iframe-container');

  var iframe = document.createElement("iframe");
        iframe.id = "game-frame";
       // iframe.src = "http://www.games.playit.mobi/oddbods-ice-cream-fight-grandtech/?user_id=ohFEAa8BfL&game_id=8mv92kcZbJ&user_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYzMjA1MDMsImV4cCI6MTYzNzg1NjUwMywic3ViIjoiVXNlciBMb2dpbiIsInVzZXIiOiJvaEZFQWE4QmZMIiwic2Vzc2lvbiI6IjVmYmU4MTc3NjA3NWUifQ.oIDAG-MhJcLBkFSLyeV66n53M161GhGchs9MfgGNgJnQbAXLOHc1pJm-BUHoj-pqv0gCpq4BAExdyyXwyp6F3g";
        iframe.src = url;

        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("name", window.location.href);
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("height", "100%");
        iframe.setAttribute("scrolling", "auto");
        //iframe.contentWindow.scrollTo(250, 250);     
         
        iframe.setAttribute("allowFullScreen",'');
        iframe.setAttribute("mozallowfullscreen",'');
        iframe.setAttribute("oallowfullscreen",'');
        iframe.setAttribute("msallowfullscreen",'');
        iframe.setAttribute("webkitallowfullscreen",'');


        iframe.style.top = "0%";
        iframe.style.left = "0%";
  //console.log("lib" , iframe)





  //////// 2) Create the parent-child communication ////////
  var eventMethod = window.addEventListener
    ? "addEventListener"
    : "attachEvent";
  var eventer = window[eventMethod];
  var messageEvent =
    eventMethod == "attachEvent" ? "onmessage" : "message";
  // Listen to message from child window
  eventer(
    messageEvent,
    function (e) {
      switch (e.data.type) {
        case "loading":
          // this event may not arrives linear
          // e.g. not all values from 0 to 100 may be sent
          loading(e.data.percentage);
          break;
        case "loaded":
          playGame(e.data.url);
          break;
        case "send":
         //console.log("message" ,e);
          sendScore({
            type: e.data.label,
            level: e.data.level,
            score: e.data.score,
          });
          rc(e, e.data.score, e.data.level);
          break;
      }
    },
    false
  );
 // var iframeGame =

//console.log("appned");
 if(document.getElementById('game-frame')) 
 { return;
 }
  container.className ="iframe-container";
  container.classList.add('compiframe-container');
 // document.getElementById("fullscreen").append(iframe);
 document.getElementById("container").append(iframe);
 
 
 

  

  // //console.log(iframeGame)
  //console.log(document.getElementsByClassName('game-Iframe')[0])
   globalIframe = iframe;
   return iframe
}
//init();

function wc(a, b, obj) {
 //console.log("api")
  // var g = new XMLHttpRequest();
  // g.onerror = function () {
  //   e(d, {});
  // };
  // g.onload = function () {
  //   var a = JSON.parse(g.responseText);
  //   e(d, a);
  // };

  // // "GET" == b && (g.open("GET", a, !0), g.send());
  // // "POST" == b &&
  // //   (g.open("POST", a, !0),
  // g.open("POST", a);
  // g.setRequestHeader("Content-Type", "application/json"),
  // g.send(JSON.stringify(d));

 //console.log(b);

  var xhr = new XMLHttpRequest();

  xhr.onload = () => {
    // process response
    if (xhr.status == 200) {
      // parse JSON data
     //console.log(JSON.parse(xhr.response));
    } else {
      console.error("Error!");
    }
  };

  xhr.open("POST", a, true);
  xhr.setRequestHeader('Content-type', 'application/json');
  xhr.send(JSON.stringify(obj));
}

function rc(a, score, lvl) {
  // condsole.log(this._endCallback);
  ////console.log(a.score);

  var obj = {
    gameid: localStorage.getItem("gameID"),
    userid: localStorage.getItem("userId"),
    operatorid: localStorage.getItem("operatorId"),
    score: score,
    level: lvl,
  };

  wc(
    "http://api.playit.mobi/api/v2/playit/addscore",
    "POST",
    obj
    // function (a, d) {
    //  //console.log("save data", d);
    // }
  );
}


export default init;
