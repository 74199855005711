import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import "./FreePointPage.scss";
import FreePointPageDisplay from "./FreePointPage.styled";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import Modal from 'react-awesome-modal';
import {isMobile} from 'react-device-detect';


class FreePointPage extends Component {

  constructor() {
    super();
    this.state = {
      errors: false,
      loading: false,
      showPoint: false,
      visible: false,
      mobile: false,
      shareLink: "",
      whatsLink: "https://web.whatsapp.com/send?text=textToshare",
      Img: "",
      errorMsg: ""
    }
  }

  componentDidMount() {

    if (localStorage.getItem('i18nextLng') == 'ar') {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/free.png"
      })
    }
    else {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/free-en.png"
      })
    }
   //console.log(isMobile)
    this.checkAuth();
    this.apishare();
  }




  apishare = async () => {
    let msisdn = this.state.phone;
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)



    const options = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product: "affiliate",
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/generateLink';
    try {
      const res = await axios.post(apiUrl, options);
     //console.log("res", res.data)
      if (res.data.status == true) {
        this.setState({
          shareLink: res.data.urlCode
        })
      }

    } catch (err) {
     //console.log(err)

    }

  }


  openModal = () => {
    //e.preventDefault();
    this.setState({
      visible: true
    })
    var self = this
    setTimeout(() => {
      document.getElementById('linkscontent').addEventListener('click', function (event) {
        // do something
       //console.log("do")
        self.setState({
          visible: false
        })
      });
      
    }, 2000);
   


  }
 


  checkAuth = async () => {

    const authorized = await client.auth();
    if (!authorized) {
      this.props.history.push("/login");
    }
  }
  render() {
    const { errors, errorMsg, visible, Img, shareLink } = this.state;

    return (
      <FreePointPageDisplay>
        <div className="freepoints" id="freepoints">
          <img src={Img} className="free" width="200" height="140" />
          <p className="friendtext"><Trans>friends.invite</Trans> <br></br> <Trans>friends.point</Trans> </p>
          {/* <p><Trans>نقطة عند اشتراكهم</Trans></p> */}
          <img src="./assets/images/HomepagecomGamezroid/sound.png" className="sound" width="200" height="200"/>
          {localStorage.getItem('showdouble') == 'true' ?
                <div className="globalcoins" style={{ top: '22%' }}>
                  <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
                </div>
                : ""}
          <button className="btn freebtn"
            onClick={(e) => { this.openModal() }}>
            <img src="./assets/images/HomepagecomGamezroid/shareapps.png"  />
          </button>
          <br></br>

          <a href={isMobile ? `whatsapp://send?text=${shareLink}` : `https://web.whatsapp.com/send?text=${shareLink}`} style={{ width: "100%" }} target={!isMobile ? "_blank" : ""}>
            <button className="btn freebtn whats">
              <img src="./assets/images/HomepagecomGamezroid/whats.png" />
            </button>
          </a>

        </div>
        
        
        {visible ?
          <div className="linkscontent" id="linkscontent">
            <div className="contents">
              <div className="sharepen">
                <p style={{color:'white'}}><Trans>Sharefriend</Trans></p>
              </div>
              <div className="linksshare">
                <a href={`https://www.facebook.com/sharer.php?u=${shareLink}`} target="_black">
                  <button className="sharelinkbtn">
                    <span>Facebook</span>
                    <img src="./assets/images/HomepagecomGamezroid/facebook.png" />
                  </button>
                </a>
                <a href={`https://twitter.com/intent/tweet?url=${shareLink}&text=Gemzroid`} target="_black">

                  <button className="sharelinkbtn">
                    <span>twitter</span>

                    <img src="./assets/images/HomepagecomGamezroid/twitter.png" />
                  </button>
                </a>

                <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=Gemzroid`} target="_black">


                  <button className="sharelinkbtn">
                    <span>linkedin</span>

                    <img src="./assets/images/HomepagecomGamezroid/linkedin.png" />
                  </button>
                </a>

                <a href={`mailto:?subject=Gemzroid Play and Win&body=${shareLink}`} target="_black">

                  <button className="sharelinkbtn">
                    <span>email</span>

                    <img src="./assets/images/HomepagecomGamezroid/email.png" />
                  </button>
                </a>

              </div>

              {/* <div className="copylink">
        <button>Copy Link</button>

            <p>links</p>
          </div> */}
            </div>
          </div>
          : ""}

        

        {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}

      </FreePointPageDisplay>
    );
  }
}
export default withTranslation()(FreePointPage)
