import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import { setAgencyData, logtracker } from "../../../helpers/agencyTracker";
import "./SignupPage.styled";
import "./KsaSignupPage.scss";
import KsaSignupPageDisplay from "./SignupPage.styled";
// import Modal from 'react-awesome-modal';
import i18n from "../../../i18n/i18n";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Modal from "react-modal";

const urlSignup = window.location.href;

var mobLinkdaily = "http://digitalcg.net/484/1Dd6";
var mobLinkWeekly = "http://digitalcg.net/484/0R1Z";
var mobLink = "http://digitalcg.net/484/1Dd6";

// var stcLinkdaily = "http://galaxylp.mobi-mind.net/?Id=918,b,966,2695,465,http://ksa.playit.mobi/catch-ksa,0,42001,10949";
var stcLinkdaily = "http://digitalcg.net/481/WaRQ";
var stcLinkWeekly = "http://digitalcg.net/481/vyn3";
var stcLink = "http://digitalcg.net/481/zG8z";
// var zainLinkdaily = "http://galaxylp.mobi-mind.net/?Id=918,b,966,2695,465,http://ksa.playit.mobi/catch-ksa,0,42004,10912";
var zainLinkdaily = "http://digitalcg.net/481/gKLN";
var zainLinkWeekly = "http://digitalcg.net/481/DvyO";
var zainLink = "http://digitalcg.net/481/00ey";

//Virgin New Company
var virginLink = "http://digitalcg.net/481/6N2E";

var randomBg = bgSliders.randomBgksa();
const localCountryCode = localStorage.getItem("countryCode");
const countryCode = localCountryCode ? localCountryCode : "EG";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const url = window.location.search;
if (url !== "") {
  setAgencyData(url);
  if (localStorage.tracker) {
    mobLinkdaily =
      mobLinkdaily +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    mobLinkWeekly =
      mobLinkWeekly +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    mobLink =
      mobLink +
      "?clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    stcLinkdaily =
      stcLinkdaily +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    stcLinkWeekly =
      stcLinkWeekly +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    stcLink =
      stcLink +
      "?clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    zainLinkdaily =
      zainLinkdaily +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
    zainLinkWeekly =
      zainLinkWeekly +
      "&clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;

    zainLink =
      zainLink +
      "?clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;

    virginLink =
      virginLink +
      "?clickid=" +
      localStorage.tracker +
      "&clickcompany=" +
      localStorage.trackerCompany;
  }
}

class KsaSignupPage extends Component {
  constructor() {
    super();
    this.state = {
      countryCode: countryCode,
      phone: "",
      password: "",
      plan: "",
      network: "",
      error: false,
      errorMsg: "",
      windowlocation: "",
      signup: "signup",
      visibleModalStc: false,
      visibleModalZain: false,
      visibleModalMobily: false,
      urlDir: "",
    };
  }

  componentDidMount() {
    if (this.props.location.search !== "") {
      setAgencyData(this.props.location.search);
    }
    if (urlSignup.includes("signup1")) {
      randomBg = "";
      this.setState({
        signup: "signup1",
      });
    }

    if (urlSignup.includes("signup2")) {
      randomBg = "";
      this.setState({
        signup: "signup2",
      });
    }

    if (urlSignup.includes("signup3")) {
      randomBg = "";
      this.setState({
        signup: "signup3",
      });
    }
    if (urlSignup.includes("signup4")) {
      randomBg = "";
      this.setState({
        signup: "signup4",
      });
    }

    if (urlSignup.includes("signup5")) {
      randomBg = "";
      this.setState({
        signup: "signup5",
      });
    }
    if (urlSignup.includes("signup6")) {
      randomBg = "";
      this.setState({
        signup: "signup6",
      });
    }
  }

  signup(e, link) {
    e.preventDefault();

    var trackerCompany = localStorage.getItem("trackerCompany");
    var tracker = localStorage.getItem("tracker");
    //logtracker(trackerCompany , tracker);
    if (link) {
     //console.log("tracker");
      logtracker(trackerCompany, tracker, link);
    }
    //window.location.href = link
  }

  handelCheck(e, link) {
   //console.log(link);
    this.setState({
      urlDir: link,
    });
    let trackerCompany = localStorage.getItem("trackerCompany");
    let tracker = localStorage.getItem("tracker");
    //logtracker(trackerCompany , tracker);
    if (link) {
     //console.log("tracker");
      logtracker(trackerCompany, tracker, link);
    }
    // this.signup(e, this.state.urlDir);
  }

  openModalStc() {
    this.setState({
      visibleModalStc: true,
    });
  }

  closeModalStc() {
    this.setState({
      visibleModalStc: false,
    });
  }

  openModalZain() {
    this.setState({
      visibleModalZain: true,
    });
  }

  closeModalZain() {
    this.setState({
      visibleModalZain: false,
    });
  }

  openModalMobily() {
    this.setState({
      visibleModalMobily: true,
    });
  }

  closeModalMobily() {
    this.setState({
      visibleModalMobily: false,
    });
  }

  render() {
    const { t } = this.props;

    const { countryCode, error, errorMsg, signup } = this.state;

    return (
      <KsaSignupPageDisplay
        className=""
        bgImg={signup === "signup" ? "" : randomBg}
      >
        <div className="formPage">
          <div className="formsLayout">
            {signup === "signup"
              ? ""
              : // <div className="header">

                //   <Link to="/">
                //     <div className="logo" />
                //   </Link>
                // </div>

                ""}

            {signup === "signup1" || signup === "signup2" ? (
              <div className="header  header_mobily">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
            ) : (
              ""
            )}

            {signup === "signup3" || signup === "signup4" ? (
              <div className="header  header_stc">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
            ) : (
              ""
            )}
            {signup === "signup5" || signup === "signup6" ? (
              <div className="header  header_zain">
                <Link to="/">
                  <div className="logo" />
                </Link>
              </div>
            ) : (
              ""
            )}
            {signup === "signup" ? (
              <div className="links-Section links-Section-ksa">
                <div className="games_img"></div>

                <div className="header header-ksa">
                  <Link to="/">
                    <div className="logo singupLogo" />
                  </Link>
                </div>
                <h3>
                  <Trans>SIM_service_provider_ksa</Trans>
                </h3>

                <button
                  className="btn mob mob-Ksa"
                  type="button"
                  onClick={(e) => this.signup(e, mobLink)}
                >
                  <img
                    src="/assets/images/ksa_btn/arrow.png"
                    alt="arrow"
                    style={{ visibility: "hidden" }}
                  />
                  <img src="/assets/images/ksa_btn/mob.png" alt="mob" />
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />
                </button>
                {/* <button
                  className="btn stc stc-Ksa"
                  type="button"
                  onClick={(e) => this.signup(e, stcLink)}
                >
                  <img
                    src="/assets/images/ksa_btn/arrow.png"
                    alt="arrow"
                    style={{ visibility: "hidden" }}
                  />
                  <img src="/assets/images/ksa_btn/stc.png" alt="stc" />
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />
                </button> */}
                <button
                  className="btn zain zain-Ksa"
                  type="button"
                  onClick={(e) => this.signup(e, zainLink)}
                >
                  <img
                    src="/assets/images/ksa_btn/white-arrow.png"
                    alt="arrow"
                    style={{ visibility: "hidden" }}
                  />
                  <img src="/assets/images/ksa_btn/zain.png" alt="zain" />
                  <img
                    src="/assets/images/ksa_btn/white-arrow.png"
                    alt="arrow"
                  />
                </button>

                <button
                  className="btn virgin virgin-ksa "
                  type="button"
                  onClick={(e) => this.signup(e, virginLink)}
                > 
                  <img
                    src="/assets/images/ksa_btn/white-arrow.png"
                    alt="arrow"
                    style={{ visibility: "hidden" }}
                  />
                  <img src="https://virginmobile.sa/web/template/assets/img/colored-logo@2x.png" alt="virgin" />
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />
                </button>
              </div>
            ) : (
              ""
            )}

            {signup === "signup1" ? (
              <div className="links-Section links-Sectionksa">
                <h3
                  style={{ paddingBottom: "1px" }}
                  className="gamezoridcontent"
                  id="gamezoridcon"
                >
                  <Trans>Your content is ready!</Trans>
                </h3>

                <div className="play"></div>

                <button
                  id="signup"
                  className="btn mob"
                  type="button"
                  onClick={(e) => {
                    this.signup(e, mobLinkdaily);
                  }}
                >
                  <Trans>Subdaily_ksa</Trans>
                </button>

                <div className="row text-center">
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span>
                      <img
                        src="/assets/images/subscribegamezriod/like.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>evaluation</Trans>
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span>
                      <img
                        src="/assets/images/subscribegamezriod/Repeat.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>game</Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span>
                      <img
                        src="/assets/images/subscribegamezriod/group.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>user</Trans>
                      </p>
                    </div>
                  </div>
                </div>

                <p className="freesubscribe">
                  <Trans>subscribeksadaily.FREE</Trans>{" "}
                  <span>
                    <Trans>subscribeksadaily.PRICE_mob</Trans>
                  </span>{" "}
                  <Trans>subscribeksadaily.AUTO</Trans>
                </p>
              </div>
            ) : (
              ""
            )}

            {signup === "signup2" ? (
              <div className="links-Section links-Sectionksa">
                <h3
                  style={{ paddingBottom: "1px" }}
                  className="gamezoridcontent"
                  id="gamezoridcon"
                >
                  <Trans>Your content is ready!</Trans>
                </h3>

                <div className="play"></div>

                <button
                  id="signup"
                  className="btn mob"
                  type="button"
                  onClick={(e) => {
                    this.signup(e, mobLinkWeekly);
                  }}
                >
                  <Trans>Subweekly_ksa</Trans>
                </button>

                <div className="row text-center">
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span>
                      <img
                        src="/assets/images/subscribegamezriod/like.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>evaluation</Trans>
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span>
                      <img
                        src="/assets/images/subscribegamezriod/Repeat.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>game</Trans>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span>
                      <img
                        src="/assets/images/subscribegamezriod/group.png"
                        alt="img"
                        className="iconsimg"
                      />
                      <p>
                        <Trans>user</Trans>
                      </p>
                    </div>
                  </div>
                </div>

                <p className="freesubscribe">
                  <Trans>subscribeksaweekly.FREE</Trans>{" "}
                  <span>
                    <Trans>subscribeksaweekly.PRICE_mob</Trans>
                  </span>{" "}
                  <Trans>subscribeksaweekly.AUTO</Trans>
                </p>
              </div>
            ) : (
              ""
            )}

            {signup === "signup3" ? (
              <>
                <div className="links-Section links-Sectionksa">
                  <h3
                    style={{ paddingBottom: "1px" }}
                    className="gamezoridcontent"
                    id="gamezoridcon"
                  >
                    <Trans>Your content is ready!</Trans>
                  </h3>
                  <div className="play"></div>

                  <button
                    id="signup"
                    className="btn stc"
                    onClick={(e) => {
                      this.signup(e, stcLinkdaily);
                    }}
                    type="button"
                  >
                    <Trans>Subdaily_ksa</Trans>
                  </button>
                  <div className="row text-center">
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>98%</span>
                        <img
                          src="/assets/images/subscribegamezriod/like.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>evaluation</Trans>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>1000+</span>
                        <img
                          src="/assets/images/subscribegamezriod/Repeat.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>game</Trans>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>500k+</span>
                        <img
                          src="/assets/images/subscribegamezriod/group.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>user</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="freesubscribe">
                    <Trans>subscribeksadaily.FREE</Trans>{" "}
                    <span>
                      <Trans>subscribeksadaily.PRICE_stc</Trans>
                    </span>{" "}
                    <Trans>subscribeksadaily.AUTO</Trans>
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            {signup === "signup4" ? (
              <>
                <div className="links-Section links-Sectionksa">
                  <h3
                    style={{ paddingBottom: "1px" }}
                    className="gamezoridcontent"
                    id="gamezoridcon"
                  >
                    <Trans>Your content is ready!</Trans>
                  </h3>
                  <div className="play"></div>
                  <button
                    id="signup"
                    className="btn stc"
                    onClick={(e) => {
                      this.signup(e, stcLinkWeekly);
                    }}
                    type="button"
                  >
                    <Trans>Subweekly_ksa</Trans>
                  </button>

                  <div className="row text-center">
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>98%</span>
                        <img
                          src="/assets/images/subscribegamezriod/like.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>evaluation</Trans>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>1000+</span>
                        <img
                          src="/assets/images/subscribegamezriod/Repeat.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>game</Trans>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>500k+</span>
                        <img
                          src="/assets/images/subscribegamezriod/group.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>user</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="freesubscribe">
                    <Trans>subscribeksaweekly.FREE</Trans>{" "}
                    <span>
                      <Trans>subscribeksaweekly.PRICE_stc</Trans>
                    </span>{" "}
                    <Trans>subscribeksaweekly.AUTO</Trans>
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            {signup === "signup5" ? (
              <>
                <div className="links-Section links-Sectionksa">
                  <h3
                    style={{ paddingBottom: "1px" }}
                    className="gamezoridcontent"
                    id="gamezoridcon"
                  >
                    <Trans>Your content is ready!</Trans>
                  </h3>
                  <div className="play"></div>

                  <button
                    id="signup"
                    className="btn zain"
                    onClick={(e) => {
                      this.signup(e, zainLinkdaily);
                    }}
                    type="button"
                  >
                    <Trans>Subdaily_ksa</Trans>
                  </button>
                  <div className="row text-center">
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>98%</span>
                        <img
                          src="/assets/images/subscribegamezriod/like.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>evaluation</Trans>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>1000+</span>
                        <img
                          src="/assets/images/subscribegamezriod/Repeat.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>game</Trans>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>500k+</span>
                        <img
                          src="/assets/images/subscribegamezriod/group.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>user</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="freesubscribe">
                    <Trans>subscribeksadaily.FREE</Trans>{" "}
                    <span>
                      <Trans>subscribeksadaily.PRICE_zain</Trans>
                    </span>{" "}
                    <Trans>subscribeksadaily.AUTO</Trans>
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            {signup === "signup6" ? (
              <>
                <div className="links-Section links-Sectionksa">
                  <h3
                    style={{ paddingBottom: "1px" }}
                    className="gamezoridcontent"
                    id="gamezoridcon"
                  >
                    <Trans>Your content is ready!</Trans>
                  </h3>
                  <div className="play"></div>
                  <button
                    id="signup"
                    className="btn zain"
                    onClick={(e) => {
                      this.signup(e, zainLinkWeekly);
                    }}
                    type="button"
                  >
                    <Trans>Subweekly_ksa</Trans>
                  </button>

                  <div className="row text-center">
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>98%</span>
                        <img
                          src="/assets/images/subscribegamezriod/like.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>evaluation</Trans>
                        </p>
                      </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>1000+</span>
                        <img
                          src="/assets/images/subscribegamezriod/Repeat.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>game</Trans>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-4 px-5">
                      <div className="games-info">
                        <span>500k+</span>
                        <img
                          src="/assets/images/subscribegamezriod/group.png"
                          alt="img"
                          className="iconsimg"
                        />
                        <p>
                          <Trans>user</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <p className="freesubscribe">
                    <Trans>subscribeksaweekly.FREE</Trans>{" "}
                    <span>
                      <Trans>subscribeksaweekly.PRICE_zain</Trans>
                    </span>{" "}
                    <Trans>subscribeksaweekly.AUTO</Trans>
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            <Modal
              isOpen={this.state.visibleModalMobily}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="header-model">
                <button
                  type="button"
                  className=""
                  onClick={() => this.closeModalMobily()}
                >
                  <Trans>x</Trans>
                </button>
              </div>

              <div className="btnsModal btnsMobModal">
                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, mobLinkdaily)}
                >
                  <label className="custom-control-label" htmlFor="daily">
                    <Trans>ksadaily</Trans>
                  </label>

                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck1"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, mobLinkdaily)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, mobLinkWeekly)}
                >
                  <label className="custom-control-label" htmlFor="weakly">
                    <Trans>Weekly_plan</Trans>
                  </label>
                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck2"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, mobLinkWeekly)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                {/* <button className="btn btn-next" type='button' onClick={e => { this.signup(e, this.state.urlDir) }} >
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" style={{ visibility: 'hidden' }} />
                  <span><Trans>next</Trans></span>
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />
    
    
                </button>
                */}
              </div>
            </Modal>

            <Modal
              isOpen={this.state.visibleModalStc}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="header-model">
                <button
                  type="button"
                  className=""
                  onClick={() => this.closeModalStc()}
                >
                  <Trans>x</Trans>
                </button>
              </div>

              <div className="btnsModal btnsStcModal">
                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, stcLinkdaily)}
                >
                  <label className="custom-control-label" htmlFor="daily">
                    <Trans>ksadaily</Trans>
                  </label>

                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck1"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, stcLinkdaily)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, stcLinkWeekly)}
                >
                  <label className="custom-control-label" htmlFor="weakly">
                    <Trans>Weekly_plan</Trans>
                  </label>
                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck2"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, stcLinkWeekly)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                {/*  <button className="btn btn-next" type='button' onClick={e => { this.signup(e, this.state.urlDir) }} >
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" style={{ visibility: 'hidden' }} />
                  <span><Trans>next</Trans></span>
                  <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />


              </button> */}
              </div>
            </Modal>

            <Modal
              isOpen={this.state.visibleModalZain}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="header-model">
                <button
                  type="button"
                  className=""
                  onClick={() => this.closeModalZain()}
                >
                  <Trans>x</Trans>
                </button>
              </div>

              <div className="btnsModal btnsZainModal">
                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, zainLinkdaily)}
                >
                  <label className="custom-control-label" htmlFor="daily">
                    <Trans>ksadaily</Trans>
                  </label>

                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck1"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, zainLinkdaily)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                <div
                  className="custom-control custom-checkbox"
                  onClick={(e) => this.handelCheck(e, zainLinkWeekly)}
                >
                  <label className="custom-control-label" htmlFor="weakly">
                    <Trans>Weekly_plan</Trans>
                  </label>
                  <label className="checkbox">
                    <input
                      type="radio"
                      className="checkbox__input"
                      id="customCheck2"
                      name="customCheck"
                      onChange={(e) => this.handelCheck(e, zainLinkWeekly)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>

                {/* <button className="btn btn-next" type='button' onClick={e => { this.signup(e, this.state.urlDir) }} >
                <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" style={{ visibility: 'hidden' }} />
                <span><Trans>next</Trans></span>
                <img src="/assets/images/ksa_btn/arrow.png" alt="arrow" />


            </button>*/}
              </div>
            </Modal>
          </div>
          {error ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
        </div>
      </KsaSignupPageDisplay>
    );
  }
}
export default withTranslation()(KsaSignupPage);
