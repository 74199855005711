import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import queryString from "query-string";

let url = window.location.href;


export function setlangSubscribe() {

  if(url.includes("egypt") && url.includes("?"))
  {
    ////console.log('setlangSubscribe',localStorage.getItem("i18nextLng") , typeof(params.language))

    const fallbackLng = [localStorage.getItem('i18nextLng')];
    const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  //  .use(LanguageDetector) // detect user language

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

    if(localStorage.getItem('i18nextLng')== 'ar')
    {
      document.body.dir='rtl'
    }
    else
    {
      document.body.dir='ltr'

    }

  }
  else
  {
  //var fallbackLng
  const fallbackLng = [localStorage.getItem('i18nextLng')];
  //localStorage.setItem("i18nextLng", "ar");

  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });
  }


}



export function checklocalLanguage(lang) {


  if (url.includes("signup11") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup21") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup31") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup41") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")

    || url.includes("signup") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup2") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup3") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")
    || url.includes("signup4") && url.includes("gamezroid") && url.includes("?") && !url.includes("ethio")

    || url.includes("aciq.playit") ||  url.includes("sabafon") ||  url.includes("zainiq.gamezones") ||  url.includes("gamezworld")) {
    // var fallbackLng
    // if (localStorage.getItem("i18nextLng") == 'ar') {
    //  //console.log("ar")
    //   fallbackLng = ["ar"];
    //   localStorage.setItem("i18nextLng", "ar");

    // }
    // else {
    //  //console.log("en")
    //   fallbackLng = ["en"];
    //   localStorage.setItem("i18nextLng", "en");

    // }
   //console.log('lang>');
    if (!localStorage.getItem("i18nextLng")) {
      localStorage.setItem("i18nextLng", "ar");
    }
  
    const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];
    const fallbackLng = [localStorage.getItem("i18nextLng")];
    
    i18n
      .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
  
      .use(initReactI18next) // pass the i18n instance to react-i18next.
  
      .init({
        fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
        debug: false,
        whitelist: availableLanguages,
  
        interpolation: {
          escapeValue: false
        }
      });
  

  }

  else if (url.includes("signup") && url.includes("dzo") && url.includes("?")) {
    var fallbackLng
    if (localStorage.getItem("i18nextLng") == 'ar') {
     //console.log("ar")
      fallbackLng = ["ar"];
      localStorage.setItem("i18nextLng", "ar");

    }

    else if (localStorage.getItem("i18nextLng") == 'fr') {
     //console.log("fr")
      fallbackLng = ["fr"];
      localStorage.setItem("i18nextLng", "fr");

    }
    else {
     //console.log("en")
      fallbackLng = ["en"];
      localStorage.setItem("i18nextLng", "en");

    }

    const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

    i18n
      .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

      .use(initReactI18next) // pass the i18n instance to react-i18next.

      .init({
        fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
        debug: false,
        whitelist: availableLanguages,

        interpolation: {
          escapeValue: false
        }
      });

  }

}





if (url.includes("jawwal")) {
  //const fallbackLng = ["ar"];
  //localStorage.setItem("i18nextLng","ar");

  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "ar");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];
  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

}

else if (url.includes("ao.playit") && !url.includes('ethio')) {
  //const fallbackLng = ["pt"];

  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "pt");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];
  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

}


else if (url.includes("dzo")) {
  //const fallbackLng = ["pt"];

  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "fr");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];
  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

}


else if (url.includes("aciq") || url.includes("asiacell")  ||  (url.includes("egypt") && !url.includes("?")) || url.includes("competition") || url.includes("gamesstore") || url.includes("gamezroid") && !url.includes("ethio") && !url.includes("?") && !url.includes("ramdank") || url.includes("stckuwait") || url.includes("ksa") || url.includes('ymobile') || url.includes('zainiq.gamezones') || url.includes('gamezworld')) {
  /* const fallbackLng = ["ar"];
   localStorage.setItem("i18nextLng","ar");*/

  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "ar");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];

  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];
  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

}

else if (url.includes("telkomsel") )
{
 
  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "indo");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];

  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];
  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });
}
/* 
else if (url.includes("gamezones") && !url.includes("zainiq.gamezones") )
{
  localStorage.setItem("i18nextLng", "en");
  if (!localStorage.getItem("i18nextLng")) {
    localStorage.setItem("i18nextLng", "en");
  }
  const fallbackLng = [localStorage.getItem("i18nextLng")];

  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];
  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });
} */


else {
  const fallbackLng = ["en"];
  const availableLanguages = ["en", "fr", "pt", "ar" , "indo"];

  i18n
    .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

    .use(LanguageDetector) // detect user language

    .use(initReactI18next) // pass the i18n instance to react-i18next.

    .init({
      fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
      debug: false,
      whitelist: availableLanguages,

      interpolation: {
        escapeValue: false
      }
    });

}
export default i18n


