import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import Operators from "../../operators.json";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import VivaSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getRedirectUrl, subscribe } from "./services";
import { setAgencyData, callTrackerCompany, saveTracker } from "../../../helpers/agencyTracker";

const plans = [
  {
    plan: "d",
    name: "Daily"
  },
  {
    plan: "w",
    name: "Weekly"
  },
  {
    plan: "m",
    name: "Monthly"
  }
];

const randomBg = bgSliders.getRandomBg();
function VivaSignupPage(props) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [signupPlan, setSignupPlan] = useState("w");
  const urlBh = window.location.hostname.includes("omantel");

  const APImsisdn = {
    bhmsisdn: ''
  }

  if (props.location.search !== "") {
    setAgencyData(props.location.search);
  }
  const [alert, setAlert] = useState(false);
  useEffect(() => {

    async function subscribeUser() {
      if (props.location.search) {
        const values = queryString.parse(props.location.search);
        if (values.cpTransactionID) {
          const phone = values.phone ? values.phone : activeData;
          const subscribed = await subscribe(phone, values.cpTransactionID);
          if (subscribed) props.history.push("/");
        }
      }
    }
    checkHeader()
    subscribeUser();
  }, []);


  const checkHeader = () => {
    const phoneNum = queryString.parse(props.location.search);
    if (phoneNum.msisdn) {
      APImsisdn.bhmsisdn = phoneNum.msisdn;
      convertPass(phoneNum.msisdn)
    } else if (phoneNum.redirected) {
     //console.log('No phone number');
    } else {
      getMsisdn();
    }
  }


  const convertPass = (msisdn) => {
    return axios.get(`http://www.api.playit.mobi/api/v2/omantel/decryptMsisdn2?msisdn=${msisdn}`)
      .then(res => {
        if (res.data.msisdn) {
          setActiveData(res.data.msisdn)
        }
      })
      .catch(function (error) {
        // handle error
       //console.log(error);
      })
      .finally(function () {
       //console.log('complete');
      });

  }

  const getMsisdn = () => {
    window.location.replace('http://helm.tekmob.com/pim/omantelmahe?redirectURL=http://omantel.playit.mobi/signup?redirected=true&user=grandit&pass=gt66698');

  }

  const signup = async e => {
   //console.log("true component")
    e.preventDefault();
    if (activeData) {
      axios.get(`http://www.api.playit.mobi/api/v2/omantel/optinRequest?msisdn=${activeData}&plan=${signupPlan}&isEncrypted=${isEncrypted}`)
        .then(res => {
         //console.log(res);
          setShowverif(true)
          setErrorMesg(false)
        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };
  const verify = async e => {
    e.preventDefault();
    if (activeData) {
      axios.get(`http://api.playit.mobi/api/v2/omantel/optinConfirmRequest?msisdn=${activeData}&otp=${verifyCode}`)
        .then(res => {
         //console.log(res);
         //console.log(res['status']);
          var datag = {};
          if (res.data.status) {
            setVerfiErrorMesg(false)
            if (res.data.userId) {
              if (localStorage.getItem('tracker')) {
                switch (localStorage.getItem("trackerCompany")) {
                  case 'InhouseSource1':
                    datag = {
                      key: 'inhouse1_click_id',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
      
                    break;
                  case 'InhouseSource2':
                    datag = {
                      key: 'inhouse2_click_id',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'OlimobCPA':
                    datag = {
                      key: 'eoliclick',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'digiTantra':
                    datag = {
                      key: 'erid',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'krayons':
                    datag = {
                      key: 'kcclick',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'youmi':
                    datag = {
                      key: 'yerid',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'trafficCompany':
                    datag = {
                      'key': 'etracker',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'ArmorAds':
                    datag = {
                      key: 'earmclick_id',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
                  case 'MOBIPIUM':
                    datag = {
                      key: 'merid',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
      
                    break;
                  case 'Propellar':
                    datag = {
                      key: 'visitor_id',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
      
                    break;
                  case 'Mobidea':
                    datag = {
                      key: 'click_id',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
      
                    break;
                  case 'Rich':
                    datag = {
                      key: 'key',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };
                    break;
      
                  default:
                    break;
                }
                datag = {
                      key: 'key',
                      company: localStorage.getItem("trackerCompany"),
                      value: localStorage.getItem('tracker'),
                      identifierKey: 'id',
                      identifierValue: Operators.omantel,
                      phoneNum: activeData
                    };

              }
             //console.log(datag);
              saveTracker(datag);
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              window.location.href = "/";
            }
            window.location.href = "/";
          } else {
            setVerfiErrorMesg(true)
          }
        })
        .catch(function (error) {
         //console.log(error);
          setVerfiErrorMesg(true)
        })
        .finally(function () {
         //console.log('complete');
        });
    }
  };

  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
       {c.name}
      </option>
    );
  });

  return (
    <VivaSignupPageDisplay className="" bgImg={randomBg}>
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBE_ERROR</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup">
        <div className="formsLayout">
          <div className="header">
            <Link to="/">
              <div className="logo" />
            </Link>
          </div>

              <div className="center">
                <form>
                  {!showverif ? 
                  <div>
                  <div className="inputs">
                    <select className="select">
                      <option value="">Oman (+968)</option>
                    </select>
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter your phone number"
                      value={activeData}
                      onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                    />
                  </div>
                     <div className="iconSelect">
                     <Trans>Subscribtion_Plan</Trans>
                     <i className="icon money" />
                     <select
                     style={{width:'40%'}}
                       className="select"
                       defaultValue={signupPlan}
                       onChange={e => setSignupPlan(e.target.value)}
                      >
                       <Trans>{plansOptions}</Trans> 
                     </select>
                    
                   </div></div> : null}
               

                  {showverif ?
                    <div className="">
                      <p>verify code</p>
                      <input
                        type="text"
                        className="input"
                        placeholder="Enter verify code"
                        onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

                      />
                    </div> : null
                  }

                </form>
              </div>
              <div className="footer">
                {!showverif ? <div>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      signup(e);
                    }}
                  >
                    Signup
                  </button>
                </div> : null}
                {showverif ? <div>
                  <button
                    className="btn"
                    disabled={activeData ? "" : "disabled"}
                    onClick={(e) => {
                      verify(e);
                    }}
                  >
                    Verify
                  </button>
                </div> : null}

                <div className="ncellTos">
                  <h3><Trans>terms_and_conditions</Trans></h3>

                  <p><Trans>OMSUBSCRIBE_DAILY</Trans></p>
                  <p> <Trans>OMSUBSCRIBE_TAX</Trans> </p>
                  <p> <Trans>OM_UNSUBSCRIBE</Trans> </p>


                </div>
              </div>

        </div>
      </div>

    </VivaSignupPageDisplay>
  );
}
export default withTranslation()(VivaSignupPage)
