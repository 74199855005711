import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import h from "../../helpers/helpers";
import "./Loader.scss";
import LoaderDisplay from "./Loader.styled";
export default class Loader extends Component {
  render() {
    const { type } = this.props;
    return (
      <LoaderDisplay className={h.isRTL() ? "rtl-loader" : ""}>
        {type === "home" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            {/* First Slider  */}
            {/* For category title and see all */}
            <rect x="25" y="15" rx="0" ry="0" width="120" height="10" />
            <rect x="300" y="15" rx="0" ry="0" width="75" height="10" />
            {/* For games slider*/}
            <rect x="25" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="30" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="120" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="210" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="300" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="130" rx="0" ry="0" width="75" height="10" />

            {/* Second Slider  */}
            <rect x="25" y="220" rx="0" ry="0" width="120" height="10" />
            <rect x="300" y="220" rx="0" ry="0" width="75" height="10" />

            <rect x="25" y="245" rx="5" ry="5" width="75" height="75" />
            <rect x="30" y="340" rx="0" ry="0" width="75" height="10" />
            <rect x="120" y="245" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="340" rx="0" ry="0" width="75" height="10" />
            <rect x="210" y="245" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="340" rx="0" ry="0" width="75" height="10" />
            <rect x="300" y="245" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="340" rx="0" ry="0" width="75" height="10" />

            {/* Third Slider  */}
            <rect x="25" y="430" rx="0" ry="0" width="120" height="10" />
            <rect x="300" y="430" rx="0" ry="0" width="75" height="10" />

            <rect x="25" y="455" rx="5" ry="5" width="75" height="75" />
            <rect x="30" y="550" rx="0" ry="0" width="75" height="10" />
            <rect x="120" y="455" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="550" rx="0" ry="0" width="75" height="10" />
            <rect x="210" y="455" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="550" rx="0" ry="0" width="75" height="10" />
            <rect x="300" y="455" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="550" rx="0" ry="0" width="75" height="10" />

            {/* Fourth Slider  */}
            <rect x="25" y="630" rx="0" ry="0" width="120" height="10" />
            <rect x="300" y="630" rx="0" ry="0" width="75" height="10" />

            <rect x="25" y="655" rx="5" ry="5" width="75" height="75" />
            <rect x="30" y="750" rx="0" ry="0" width="75" height="10" />
            <rect x="120" y="655" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="750" rx="0" ry="0" width="75" height="10" />
            <rect x="210" y="655" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="750" rx="0" ry="0" width="75" height="10" />
            <rect x="300" y="655" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="750" rx="0" ry="0" width="75" height="10" />
          </ContentLoader>
        )}
        {type === "home-rtl" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={200}
            rtl={true}
          >
            {/* First Slider  */}
            {/* For category title and see all */}
            <rect x="30" y="15" rx="0" ry="0" width="200" height="10" />
            <rect x="300" y="15" rx="0" ry="0" width="75" height="10" />

            {/* For games slider*/}
            <rect x="30" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="30" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="120" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="210" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="130" rx="0" ry="0" width="75" height="10" />
            <rect x="300" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="130" rx="0" ry="0" width="75" height="10" />
          </ContentLoader>
        )}
        {type === "Faq" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            <rect x="30" y="10" rx="5" ry="5" width="100" height="15" />
            <rect x="30" y="40" rx="5" ry="5" width="250" height="15" />
            <rect x="30" y="70" rx="5" ry="5" width="350" height="80" />

            <rect x="30" y="200" rx="5" ry="5" width="300" height="15" />
            <rect x="30" y="230" rx="5" ry="5" width="100" height="15" />
            <rect x="30" y="260" rx="5" ry="5" width="350" height="80" />

            <rect x="30" y="380" rx="5" ry="5" width="300" height="15" />
            <rect x="30" y="410" rx="5" ry="5" width="350" height="80" />

            <rect x="30" y="560" rx="5" ry="5" width="300" height="15" />
            <rect x="30" y="590" rx="5" ry="5" width="100" height="15" />
            <rect x="30" y="620" rx="5" ry="5" width="350" height="60" />
          </ContentLoader>
        )}
        {type === "gamePage" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            <rect x="30" y="10" rx="5" ry="5" width="80" height="80" />
            <rect x="130" y="30" rx="5" ry="5" width="140" height="12" />
            <rect x="130" y="50" rx="5" ry="5" width="210" height="10" />
            <rect x="30" y="140" rx="5" ry="5" width="350" height="300" />
          </ContentLoader>
        )}
        {type === "gamesListing" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            <rect x="25" y="15" rx="0" ry="0" width="120" height="10" />
            <rect x="25" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="40" rx="5" ry="5" width="75" height="75" />
            <rect x="25" y="150" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="150" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="150" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="150" rx="5" ry="5" width="75" height="75" />
            <rect x="25" y="260" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="260" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="260" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="260" rx="5" ry="5" width="75" height="75" />
            <rect x="25" y="370" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="370" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="370" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="370" rx="5" ry="5" width="75" height="75" />
            <rect x="25" y="480" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="480" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="480" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="480" rx="5" ry="5" width="75" height="75" />
            <rect x="25" y="590" rx="5" ry="5" width="75" height="75" />
            <rect x="120" y="590" rx="5" ry="5" width="75" height="75" />
            <rect x="210" y="590" rx="5" ry="5" width="75" height="75" />
            <rect x="300" y="590" rx="5" ry="5" width="75" height="75" />
          </ContentLoader>
        )}
        {type === "profile" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            <rect x="130" y="10" rx="140" ry="140" width="140" height="140" />
            <rect x="100" y="170" rx="5" ry="5" width="200" height="20" />
            <rect x="100" y="200" rx="5" ry="5" width="200" height="20" />
            <rect x="50" y="350" rx="5" ry="5" width="120" height="75" />
            <rect x="250" y="350" rx="5" ry="5" width="120" height="75" />
          </ContentLoader>
        )}
        {type === "ranking" && (
          <ContentLoader
            primaryOpacity={0.1}
            secondaryOpacity={0.5}
            speed={2}
            height={800}
          >
            {/* Ranking header */}

            {/* Rank 2 */}
            <rect x="40" y="70" rx="140" ry="140" width="60" height="60" />
            <rect x="30" y="150" rx="5" ry="5" width="80" height="40" />
            {/* Rank 1 */}
            <rect x="160" y="30" rx="140" ry="140" width="80" height="80" />
            <rect x="150" y="120" rx="5" ry="5" width="100" height="40" />
            {/* Rank 3 */}
            <rect x="310" y="70" rx="140" ry="140" width="60" height="60" />
            <rect x="300" y="150" rx="5" ry="5" width="80" height="40" />

            {/* Ranking footer  */}
            <rect x="30" y="350" rx="140" ry="140" width="60" height="60" />
            <rect x="110" y="380" rx="5" ry="5" width="150" height="10" />
            <rect x="290" y="380" rx="5" ry="5" width="80" height="10" />
            <rect x="30" y="450" rx="140" ry="140" width="60" height="60" />
            <rect x="120" y="480" rx="5" ry="5" width="150" height="10" />
            <rect x="290" y="480" rx="5" ry="5" width="80" height="10" />
            <rect x="30" y="550" rx="140" ry="140" width="60" height="60" />
            <rect x="120" y="580" rx="5" ry="5" width="150" height="10" />
            <rect x="290" y="580" rx="5" ry="5" width="80" height="10" />
            <rect x="30" y="650" rx="140" ry="140" width="60" height="60" />
            <rect x="120" y="680" rx="5" ry="5" width="150" height="10" />
            <rect x="290" y="680" rx="5" ry="5" width="80" height="10" />
          </ContentLoader>
        )}
      </LoaderDisplay>
    );
  }
}
