import styled from "styled-components";
const RamdankGamesroidSignupPageDisplay = styled.div`
  background: var(--insideBg);
  background-repeat: no-repeat;
  background-size: cover;

  @media(max-width: 1024px){
    background-position: center;
  }


  .operatorramdankSignup
  {
    background: transparent;
    .decilimer
    {
      font-size: 11px !important;
    }
  }
  .operatorSignupGamez
  {
    min-height: 117vh !important;
  }
  .links-Section{
    position: absolute;
      top: 7%;
      width: 75%;

     
      .iconinputphone
      {
        background-color: white;
        border-radius: 7px;
      }
  }
  .links-SectionGamezorid
  {
    top: 9%!important;
    // .ramdankplay
    // {

    //  background: var(--play) center center no-repeat;
    //  height: 190px;
    //  background-size: var(--background-size)!important;
    // }
  }

  .links-Sectionramdan
  {
    width : 85%
  }
 
  .links-Section a {
    color:white
  }
  .links-SectionGamezorid button{
    margin-bottom:  42px;
    display: block;
  }
  .links-Section h3
  {
    font-weight: 200;
  }
  .selectGame
  {
    border-bottom: 1px solid #868282;
    margin-top: 10px;
    display:block;
  }
  
  .formsLayoutgamezorid
  {
    min-height: 107vh;

    .footer p
    {
      line-height: 18px;
    }

    .ramdankheader
    {
      margin-top: 10px;
    }
  }
  .formsLayoutramdankgamezorid
  {
    min-height: 134vh;
  }
  .formsLayout {
    margin: 0 20px;
    .header
    {
      margin-top: 5px;
      width: 100%;
      border-bottom: 2px solid #f2c830;
     .logogamezroid
     {
      height: 50px;
     }
    
    }
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      margin-bottom: 10px!important;
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }
  }
`; export default RamdankGamesroidSignupPageDisplay;
