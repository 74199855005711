import styled from "styled-components";
const GamezroidComLoginPageDisplay = styled.div`
  background: var(--loginBg);
  background-size: cover;
  background-repeat: no-repeat;
  @media(max-width: 1024px){
    background-position: center;
  }

  .formpagecom
  {
    background: none;
  }
  a {
    font-size: 14px;
  }
  .forgotpass {
    display: flex;
    justify-content: flex-end;
  }
  .formsLayout .center .form {
    margin: 30px;
  }
  .ramdanformsLayout
  {
    min-height: 85vh!important;
  }
`;
export default GamezroidComLoginPageDisplay;
