import React, { Component } from "react";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";




class EgyCatchPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    this.fireAPI();

  }

  fireAPI = async () => {
    const data = queryString.parse(window.location.search)
   //console.log(data)
    if (data.msisdn) {
      const res = await axios.get(`http://www.api.playit.mobi/api/v1/getToken/${data.msisdn}/1`)
        .then(res => {
          setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          window.location.href = "/";
        }).catch(error => {
         //console.log(error);
          window.location.href = "/";
        }
        )
    } else {
      window.location.href = "/";
    }
  }

  render() {

    return (
       <div>
          <p>Egypt login</p>
        </div>

    
    );
  }
}
export default EgyCatchPage;
