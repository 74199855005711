import React, { Component } from "react";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";
import Operators from "../../operators/operators.json";
import "./EgyLoginPage.scss";
import EgyLoginPageDisplay from "./EgyLoginPage.styled";
import { Trans, withTranslation } from "react-i18next";



import { setAgencyData, callTrackerCompany, saveTracker } from "../../helpers/agencyTracker";



class EgyLoginPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    this.fireAPI();

  }

  fireAPI = async () => {
    const data = queryString.parse(window.location.search);
   //console.log(data);

    const dataOject = {
      MSISDNCG: data.CGMSISDN
    }
    const headers = {
      headers: { "egauthorization": "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==", "Content-Type": "application/json" }
    }

    if (data.CGMSISDN) {

      if (data.CGStatus == 0) {
        var datag = {};
        if (localStorage.getItem('tracker')) {
          var op_id = Operators.egypt010;
          var phoneid = data.CGMSISDN;
          if (phoneid.startsWith("011") || phoneid.startsWith("2011")) {
            op_id = Operators.egypt011;
          }
          if (phoneid.startsWith("012") || phoneid.startsWith("2012")) {
            op_id = Operators.egypt012;
          }
          switch (localStorage.getItem("trackerCompany")) {
            case 'InhouseSource1':
              datag = {
                key: 'inhouse1_click_id',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };

              break;
            case 'InhouseSource2':
              datag = {
                key: 'inhouse2_click_id',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'OlimobCPA':
              datag = {
                key: 'eoliclick',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'digiTantra':
              datag = {
                key: 'erid',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'krayons':
              datag = {
                key: 'kcclick',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'youmi':
              datag = {
                key: 'yerid',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'trafficCompany':
              datag = {
                'key': 'etracker',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'ArmorAds':
              datag = {
                key: 'earmclick_id',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;
            case 'MOBIPIUM':
              datag = {
                key: 'merid',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };

              break;
            case 'Propellar':
              datag = {
                key: 'visitor_id',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };

              break;
            case 'Mobidea':
              datag = {
                key: 'click_id',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };

              break;
            case 'Rich':
              datag = {
                key: 'key',
                company: localStorage.getItem("trackerCompany"),
                value: localStorage.getItem('tracker'),
                identifierKey: 'id',
                identifierValue: op_id,
                phoneNum: data.CGMSISDN
              };
              break;

            default:
              break;
          }
          datag = {
            key: 'parameter',
            company: localStorage.getItem("trackerCompany"),
            value: localStorage.getItem('tracker'),
            identifierKey: 'id',
            identifierValue: op_id,
            phoneNum: data.CGMSISDN
          };
          
        }
        await saveTracker(datag);

      }
     //console.log("11111");
      localStorage.setItem("EGYPToperatorID", data.operatorID)
      const res = await axios.post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
        .then(res => {
          var loged = setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
          if (loged) {
            window.location.href = "/";
          } else {

          }

        }).catch(error => {
         //console.log(error);

        }
        )



    } else {
      window.location.href = "/";
    }
  }

  render() {

    return (
      <EgyLoginPageDisplay >
        <div className="continer">
          <a href="/"><div class="logo"></div></a>
          <div className="msg-block">
            <p><Trans>EGYPT.signMsg</Trans></p>
            <a href="/login">
            <button
              className="btn"><Trans>EGYPT.login</Trans></button>
               </a>
            <a href="/">
              <button
                className="btn" > <Trans>EGYPT.goToHome</Trans></button>
            </a>
          </div>
        </div>
      </EgyLoginPageDisplay>

    );
  }
}
export default withTranslation()(EgyLoginPage)

