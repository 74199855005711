
const gameCounter = {
    count:0,
    enditInfo:false,
    resitPassword:false
}

const reducer = (state = gameCounter, action) => {
    if (action.type == 'INCREASE') {
        return { count: state.count + 1 }
    }
    else if (action.type == 'DECREASE') {
        return { count: state.count - 1 }
    }
    else if (action.type == 'EDITINFO') {
        return {
            count:0,
             enditInfo:true,
            resitPassword:false 
        }
    }
    else if (action.type == 'RESITPASSWORD') {
        return {
            count:0,
            enditInfo:false,
           resitPassword:true 
       }
    }
    return state
}


export default reducer