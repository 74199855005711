import axios from 'axios';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import FloatingAlert from '../../components/FloatingAlert/FloatingAlert';
import './Rules.scss';
import RulesDisplay from './Rules.styled';
class Rules extends Component {
	constructor() {
		super();
		this.state = {
			
		};
	}

	componentDidMount() {
		
	}
	// async getTerms(operatorId, lang) {
	// 	const params = {
	// 		"operator_id": operatorId,
	// 		"locale": lang
	// 	};
	// 	try {
	// 		const res = await axios.get(`${apiUrl}/playit/terms`, { params });
	// 		if (!res.data) return false;
	// 		const data = res.data;
	// 		if (data.status !== "success") {
	// 			return false;
	// 		}
	// 		if(data.data){
	// 			return this.setState({
	// 				content: data.data.term
	// 			});
	// 		}
	// 	} catch (err) {
	// 		if (err.response && err.response.status !== 200) {
	// 			this.setState({
	// 				errors: true,
	// 				errorMsg: this.props.t("network_failed")
	// 			});
	// 		}
	// 		console.log(err);
	// 		return false;
	// 	}
	// }
	render() {
		const { } = this.state;
		return (
			<>
				<RulesDisplay>
					<h2 className="titel_header"><Trans>side_nav.rules</Trans></h2>
					<div className="content"><span className="title">1 </span><Trans>rule.1</Trans></div>
					<div className="content"><span className="title">2 </span > <Trans>rule.2</Trans></div>
					<div className="content"><span className="title">3 </span > <Trans>rule.3</Trans></div>
					<div className="content"><span className="title">4 </span > <Trans>rule.4</Trans></div>
					<div className="content">{}</div>
					
				</RulesDisplay>
			</>
		)
	}
}
export default withTranslation()(Rules)