import React from "react";
import "../../../components/Slider/Slider.scss";
import "../../../assets/scss/pages/_home.scss";
import { Link } from "react-router-dom";
export default function Competition(props) {
    const lang = localStorage.getItem("i18nextLng");
    var imgSrc = "";
    if (lang == 'ar') {
        imgSrc = "/assets/images/HomepagecomGamezroid/Main-Banner.png";
    }
    else {
        imgSrc = "/assets/images/HomepagecomGamezroid/Main-Banner-en.png";
    }
    return (
        <div className="container">
            <Link to="/">

                <div className="banner gamezroidbanner">
                    <img src={imgSrc} width="366" height="170"/>

                </div>
            </Link>


        </div>



    );

}