import styled from "styled-components";
const JawwalSignupPageDisplay = styled.div`
	background: ${p => p.bgImg};
  background-size: cover;
  .links-Section{
    position: absolute;
      top: 30%;
  }
  .links-Section a {
    color:white
  }
  .links-Section button{
    margin-bottom: 15px;
    display:block;
  }
  .formsLayout {
    margin: 0 20px;
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }
  }
`;	export default JawwalSignupPageDisplay;
