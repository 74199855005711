import axios from "axios";
import envo from "../environments/environmentProd";
import { clearLoginData } from "../helpers/helpers";

const apiUrl = envo.apiUrl;
async function logout() {
  const options = {
    method: "GET",
    headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` },
    url: `${apiUrl}/logout`
  };
  try {
    const res = await axios(options);
    if (!res.data) return false;
    if (res.data.status === true) {
     //console.log("logout success");
      clearLoginData();
      return true;
    }
  } catch (err) {
   //console.log("logout faild")

    clearLoginData();
   //console.log(err);
    return false;
  }
}

export default logout;
