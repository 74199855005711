import styled from "styled-components";
const SubscribeCompletePageDisplay = styled.div`
  background: ${p => p.bgImg};
  background-size: cover;
  .center {
    display: flex;
    justify-content: flex-end;
    img {
      width: 209px;
      display: inline-block;
      margin-left: 40px;
    }
  }
  .footer {
    div {
      margin-bottom: 10px;
    }
    p {
      text-transform: uppercase;
    }
  }
`;
export default SubscribeCompletePageDisplay;
