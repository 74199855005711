import React from "react";
import ExclusiveGame from "../../../components/ExclusiveGame/ExclusiveGame";


export default function MainExclusiveGame({ sliders, setActiveCategory }) {
//  return false;
  const catsSliders2 = sliders.map((i, k) => {
   /*i.games.map((j) =>{
     //console.log(j)
    })
    /*console.log("test k" + k)*/
    if (i.games.length > 0) {
      return (

        <ExclusiveGame
          setActiveCategory={setActiveCategory}
          {...i}
          key={`ExclusiveGame-${k}`}
        />
      );
    }
   return null;
  });
  return <>{catsSliders2}</>;
}
