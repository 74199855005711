import styled from "styled-components";
const NcellSignupPageDisplay = styled.div`
	background: ${p => p.bgImg};
  background-size: cover;
  .formsLayout {
    margin: 0 20px;
    .inputs {
      select {
        width: 50%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }
  }
`;	export default NcellSignupPageDisplay;
