import React, { Component } from "react";
import "./NotFoundPage.scss";
import NotFoundPageDisplay from "./NotFoundPage.styled";

export default class NotFoundPage extends Component {
  render() {
    return (
      <NotFoundPageDisplay>
        <div className="notFound"></div>
      </NotFoundPageDisplay>
    );
  }
}
