import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";
import { callTrackerCompany } from "../../../helpers/agencyTracker";
export async function getPhoneByHeader(linkheader = "") {
 if(linkheader == ""){
  linkheader = "http://ethio.playit.mobi/checkheader3.php"; 
 } 
   try {
    
    const res = await axios.get(linkheader);
   //console.log('resdata',res);
    
    if (res.data && res.data !== "") {
      return res.data;
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

export async function getHeaderethio(data){
  const url = "http://www.api.playit.mobi/api/v2/ethio/msisdnDecryption";
  const res = await axios({
    method: 'post',
    url: url,
    data: data,
    config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    
  //const res = await axios.post(url,data);
 //console.log('res',res);
 //console.log('res',res.data);
 //console.log('res',res.data.code);
 //console.log('res',res.data.postback);
  // if( res.data.code === 2001 ){
  //   return setLoginData(
  //     res.user_id,
  //     res.token,
  //     res.user_name,
  //     res.photo,
  //     res.operator_id,
  //     res.user_email
  //   );
  //   //console.log('res');
  // }
  // if(res.data.code === 1001){
  //   return res.msidn
  // }
  if (res.data.postback == true) {
   //console.log('res');
    const trackerCompany = localStorage.getItem("trackerCompany");
    const tracker = localStorage.getItem("tracker");
   //console.log(trackerCompany);
   //console.log(tracker);
    
    callTrackerCompany(trackerCompany, tracker);
  }

}
// export async function getPostBack(phone, trackerCompany, tracker) {
//   try {
//     const options = {
//       phone: phone,
//       tracker: tracker,
//       trackerCompany: trackerCompany
//     };
//     const url = "http://www.api.playit.mobi/api/v2/ethio/msisdnDecryption";
//     const data = await axios.post(url, options);
//     if (data && data.status === 200) {
//       if (data.data) {
//         if (data.data.postback === true) {
//           callTrackerCompany(trackerCompany, tracker);
//         }
//       }
//     }
//   } catch (err) {
//    //console.log(err);
//   }
// }
export async function signup(phone,cp_var=false) {
  try {
    const params = {
      msisdn: phone
    };
    if(cp_var != false){
      params.cp = cp_var
    }
    const headers = {
      EthAuthorization: "Basic RXRoaW9TREFwaTpLdGFmV0x3OFdFRDhWR3d3eXk="
    };
    const url = "http://www.api.playit.mobi/api/v2/Ethio/subscribe";
    const res = await axios.post(url, params, { headers });
    if (res.data && res.data !== "") {
      const data = res.data;
      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        "",
        data.msisdn
      );
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}
export async function ethLogin() {
  try {

    const url = "http://ethio.playit.mobi/headerlogin.php";
    const res = await axios.get(url);
    if (res.data && res.data !== false) {
      const data = res.data;
      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        data.user_email
      );
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }

}


export async function nigeriaLogin() {
  try {

    const url = "http://mtnng.playit.mobi/checkheader.php?loginng=1";
    const res = await axios.get(url);
          //setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);

    if (res.data && res.data.status !== false) {
      const data = res.data;
     //console.log("task" , data)
      // setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);

      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        data.user_email,
        "",
        data.msisdn
      );
    }
  } catch (err) {
   //console.log(err);
    return false;
  }
}


function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
async function sleep(fn, ...args) {
  await timeout(3000);
  return fn(...args);
}
