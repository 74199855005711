import styled from "styled-components";
const ForgetPasswordPageDisplay = styled.div`
  .txt {
    padding: 0 20px;
  }
  .formsLayout 
  {
    .footer
    {
      margin-bottom:30vh;
    }
  }
  .countryCode{
  width:25%;
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 4px;
  text-align: center;
  margin-right: 4px;
  }
    .number-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    width: 100%;
  }
`;
export default ForgetPasswordPageDisplay;
