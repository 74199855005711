import React from "react";
import GameSlider from "../../../components/GameSlider/GameSlider";


export default function MainCategorySliders({ sliders, setActiveCategory }) {
  //console.log(sliders);
  const urlEthio = window.location.hostname.includes("ethio");
  if(urlEthio)
  {
    sliders = sliders.reverse();

  }
  const catsSliders = sliders.map((i, k) => {
   //console.log(i)
   /*i.games.map((j) =>{
     //console.log(j)
    })
    /*console.log("test k" + k)*/
    if (i.games.length > 0) {
      return (

        <GameSlider
          setActiveCategory={setActiveCategory}
          {...i}
          key={`gameSlider-${k}`}
        />
      );
    }
    return null;
  });
  return <>{catsSliders}</>;
}
