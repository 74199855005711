import axios from "axios";
import React, { useRef, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import anon from "../../assets/icons/profile/lg-anonymous.svg";
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import ProfileFooter from "./ProfileFooter";
import "./ProfilePage.scss";
import { connect } from 'react-redux';
import ProfilePageDisplay from "./ProfilePage.styled";
const apiUrl = envo.apiUrl;
function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    setValue(e.target.value);
  }
  return { value, onChange: handleChange };
}
function GamezroidcomProfilePage(props) {
  const info = {
    name: localStorage.getItem("userName"),
    email: localStorage.getItem("email"),
    userPhoto: localStorage.getItem("userPhoto")
  };

  const name = useFormInput(info.name);
  const email = useFormInput(info.email);


  const [editMode, setEditMode] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [imagePreview, setImagePreview] = useState(info.userPhoto);
  const [errors, setError] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const imageFileEl = useRef(null);
  const [nameCancel, setNameCancel] = useState(localStorage.getItem("userName"));
  const [emailCancel, setEmailCancel] = useState(localStorage.getItem("email"));
  const [phoneCancel, setphoneCancel] = useState(localStorage.getItem("phoneNum"));

  const onImageClick = () => {
    imageFileEl.current.click();
  };


  const handleImageChange = e => {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) return;

    reader.onloadend = () => {
      setImageFile(file);
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);

  };
  const handleProfileEdit = async e => {
    e.preventDefault();
    const options = {
      headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
    };
    let formObj = new FormData();
    formObj.append("name", name.value);
    formObj.append("email", email.value);
    formObj.append("photo", imageFile);
    try {
      const res = await axios.post(
        `${apiUrl}/playit/user/profile/update`,
        formObj,
        options
      );
      if (!res.data) return false;
      const data = res.data;
      if (data.status !== true) {
        return false;
      }
      localStorage.setItem("userName", data.name);
      localStorage.setItem("email", data.email);
      localStorage.setItem("userPhoto", data.photo);


      props.editeInfoFun();
     //console.log("dd")

      // setNameCancel(localStorage.getItem("userName").toString())
      // setEmailCancel( data.email)
      setEditMode(false);
      window.location.reload();
      return data;
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        setError(true);
        setErrorMsg("Password And " + err.response.data.message);

      }
      return false;
    }

  };

  const cnacelBtn = () => {
    setNameCancel(localStorage.getItem("userName"))
    setEmailCancel(localStorage.getItem("email"));


    setEditMode(false);
  }
  const { t } = props;

  return (
    <ProfilePageDisplay>
      <div className="profileTop profileTopcom">

      {localStorage.getItem('showdouble') == 'true' ?
                <div className="globalcoins" style={{ top: '22%' }}>
                  <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
                </div>
                : ""}


        {editMode && props.enditInfo ? (
          <div className="edit">
            <div className="button">
              <div
                className="save-icon"
                onClick={e => {
                  handleProfileEdit(e);
                }}
              ></div>
            </div>
            <div className="img" >
              <div className="avatar">
                <div className="frame"></div>
                <div className="camera" onClick={onImageClick}></div>
                {imagePreview ? (
                  <img
                    className="imgPreview"
                    src={imagePreview !== "null" && imagePreview!== "" ? imagePreview : "./assets/images/avatar.png"}
                    // src={imagePreview !== "null" ? imagePreview : anon}
                    alt="profileimage"
                    onClick={onImageClick}
                  />
                ) : (
                  <div className="anon"></div>
                )}
              </div>
            </div>

            <div className="inputs">
              { //REVIEW: if we can check with another way
                name.value === 'null' ?
                  <input
                    type="text"
                    className="input"
                    placeholder={t("profile.enter_your_name")}
                    {...``}
                  /> :
                  <input
                    type="email"
                    className="input"
                    placeholder={t("profile.enter_your_name")}
                    {...name}
                  />
              }

              <input
                type="text"
                className="input"
                placeholder={t("profile.enter_your_email")}
                {...email}
              />
              <div className="button buttonSubmit">
                <div
                  className="btn save-btn"
                  onClick={e => {
                    handleProfileEdit(e);
                  }}
                > <Trans>profile.edit</Trans></div>

                <div
                  className="btn cancel-btn"
                  onClick={e => {
                    cnacelBtn();
                  }}
                > <Trans>profile.cancel</Trans></div>
              </div>



              <input
                ref={imageFileEl}
                type="file"
                id="imageFile"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </div>

          </div>
        ) : (
          <div className="show">
            <div className="button">
              <div
                className="edit-icon"
                onClick={() => {
                  setEditMode(true);
                  props.editeInfoFun();
                }}
              ></div>
            </div>
            <div className="img">
              <div className="avatar">
                <div className="frame"></div>
                <img
                  className="imgPreview"
                  // src={imagePreview !== "null" ? imagePreview : anon}
                  src={imagePreview !== "null" && imagePreview!== "" ? imagePreview : "./assets/images/avatar.png"}
                  alt="profileimage"
                />
              
              </div>

              {/* <div
                className="edit-icon"
                onClick={() => {
                  setEditMode(true);
                  props.editeInfoFun();
                }}
              ></div> */}
              
            </div>
            <div className="info">
              <div className="name">{name.value !== "null" ? nameCancel : 'Hi There!'}</div>
              <div className="phone">{phoneCancel}</div>
              {/* <div className="email">{emailCancel}</div> */}
            </div>
          </div>
        )}
      </div>
      <div className="profileBottom"></div>
      {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
      <ProfileFooter />
    </ProfilePageDisplay>
  );
}


/* Start Update State Use REDUX */
function mapStateToProps(state) {
  return {
    enditInfo: state.enditInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editeInfoFun: () => dispatch({ type: 'EDITINFO' }),
    resitPassword: () => dispatch({ type: 'RESITPASSWORD' })
  }
}

/* End Update State Use REDUX */
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GamezroidcomProfilePage))
