import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import axios from "axios";
import { setLoginData } from "../../helpers/helpers";
import queryString from "query-string";
import { setAgencyData, callTrackerCompany, saveTracker } from "../../helpers/agencyTracker";

import "./Thank-you-page.scss";
const urlksa= window.location.hostname.includes("ksa");
const urlGamesroid = window.location.hostname.includes("gamezroid");


//const urlksa= window.location.hostname.includes("local");
//const urlGamesroid = window.location.hostname.includes("local");





class gamezoneThankYouPage extends Component {
  constructor() {
    super();

  }
  async componentDidMount() {
    // const data = queryString.parse(window.location.search);
    // let routing = localStorage.getItem('routing');
    // if(urlksa && data.trafficsource == 'GrandMarketing')
    // {
    //   window.gtag('event', 'conversion', {'send_to': 'AW-593016567/7XFmCM-gyPEBEPft4poC'});

    // }
    // //http://ksa.playit.mobi/thank-ksa?trafficsource=
    // if(urlksa && data.trafficsource != 'GrandMarketing'){
    //   //window.fbq('track', 'Subscribe')
    // }
    
    // if(urlGamesroid && routing != 'signup6')
    // {
    //  //console.log("not signup 6")
    //   window.fbq('track', 'Purchase', {currency: "EGP", value: 1});
  
    // }
    // if(urlGamesroid && routing == 'signup6' )
    // {
    //  //console.log("signup 6")
    //   window.fbq('track', 'Purchase', {currency: "EGP", value: 1});
  
    // }

    // if(urlGamesroid && routing == 'signup6')
    // {
    //  //console.log("in signup 6")
    //    window.gtag('event', 'page_view', {
    //     'send_to': 'AW-965279051',
    //     'user_id': 'replace with value'
    //   });
    // }
    
    setTimeout(() => {
      this.fireAPI();
    }, 5000);

  }

  fireAPI = async () => {
     window.location.href = "/";
  
  }
  render() {

    return (
      <div className="container-loadaing">
        <div className="loadingLayout">
        <div className="keep-waiting">
          <div>
         
        <div className="sk-chase">
        
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        </div>
        <div style={{marginTop: '80px'}}>
          <h1><Trans>wait</Trans></h1>
          <p> <Trans>transfer</Trans></p>
          </div>
        </div>

       
        </div>

      </div>


    );
  }
}
export default withTranslation()(gamezoneThankYouPage);
