import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { Loader } from "../../components";
import envo from "../../environments/environmentProd";
import h from "../../helpers/helpers";
import Modal from 'react-awesome-modal';
import "../HomePage/HomePage.scss";
import HomePageDisplay from "../HomePage/HomePage.styled";

import CompetitionGames from "../../components/CompetitionGames/CompetitionGames"
function HomePageCompetition() {



  return (
    <HomePageDisplay>

        <CompetitionGames/>
     


    </HomePageDisplay>
  );
}
export default withTranslation()(HomePageCompetition);

// function getSubTabs(subTabsObject) {
//   const subTabsArray = Object.keys(subTabsObject);

//   const subTabs = subTabsArray.map(i => {
//     return { name: h.slugifyReverse(i), slug: i };
//   });
//   return subTabs;
// }
// const countryName = localStorage.getItem('country_name');
// const operatorName = localStorage.getItem('operator_name');

// const getCategoryGames = async (slug, main) => {
//   const api = `${envo.apiUrl}/category/getgames?category=${slug}&type=${main}&country=${countryName}&operator=${operatorName}`;
//   const res = await axios.get(api);
//   const data = res.data;
//   if (data.status === "success") {
//     const resGame = data.games.map(i => {
//       return {
//         img: i.game_thumb,
//         name: i.game_name,
//         nameAr: i.game_name_ar,
//         id: i._id
//       };
//     });
//     return resGame;
//   }
//   return [];
// };
