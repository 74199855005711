import React from "react";
import { Trans, withTranslation } from "react-i18next";

function Confirm({ setConfirmState,apk,name }) {
  return (
    <div className="confirm">
      <div className="downloadQues">
        <Trans>download.You're_about_download</Trans>
        <p>{name}</p>
      </div>
      <div className="normBtn">
        <button
          className="btn"
          onClick={() => {
            window.location.href = apk;
            setConfirmState(false);
          }}
        >
          <Trans>download.confirm</Trans>
        </button>
      </div>
      <div>
        <button
          className="btn secondary"
          onClick={() => setConfirmState(false)}
        >
          <Trans>download.cancel</Trans>
        </button>
      </div>
    </div>
  );
}
export default withTranslation()(Confirm);
