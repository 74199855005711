import styled from "styled-components";
const ConfirmDownloadDisplay = styled.div`
height:100vh;
display:flex;
flex-direction:column;
justify-content: center;
	.downloadQues{
		font-size: 18px;
		font-weight: bold;
		line-height: 1.28;
	}
`;	export default ConfirmDownloadDisplay;
