import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../helpers/bgSliders";
import "./GamezroidComLoginPage.scss";
import Modal from 'react-awesome-modal';
import GamezroidComLoginPageDisplay from "./GamezroidComLoginPage.styled";

const randomBg = bgSliders.randomBgGamezroidcom();
console.log("random", randomBg)
const url = window.location.hostname.includes("ksa");
const urlEgy = window.location.hostname.includes("egypt");
const urlgamezone = window.location.hostname.includes("ao.playit");

const urlGamesroid = window.location.hostname.includes("gamezroid");
const urlcompetition = window.location.hostname.includes("competition");
const urlethio = window.location.hostname.includes("ethio");

const urlzainiq = window.location.hostname.includes("local");


//const urlethio = window.location.hostname.includes("local");



class GamezroidComLoginPage extends Component {
  constructor() {
    super();
    this.state = {
      phone: "",
      password: "",
      Img: "",
      errors: false,
      errorMsg: "",
      show: true,
      visibleModalgamezone: false,
      showLoginPage: true,
      termscheck: false,
      phonePlaceholder: "enter_phone_number"

    };

  }

  async openModalgamezone() {
    const check = await this.termsValue();
    const { phone, password } = this.state;
    if (!check && this.isValid(phone, password)) {
      this.setState({
        visibleModalgamezone: true
      });

    }
    else {
      if (localStorage.getItem('gameURL')) {
       //console.log('gameID', localStorage.getItem('gameURL'))
        let gameURL = localStorage.getItem('gameURL');
        this.props.history.push(gameURL)
      } else {
        this.props.history.push("/");
      }

    }
  }

  closeModalgamezone() {
    this.setState({
      visibleModalgamezone: false
    });

  }

  async termsValue() {
    const { phone } = this.state;
    const termscheck = await client.Termscheck(phone);
    return termscheck

  }

  async componentDidMount() {
    if (localStorage.getItem('i18nextLng') == 'ar') {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/login-logo.png"

      })

    }
    else {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/final-logo-en.png"

      })

    }
    if (urlGamesroid) {
      this.setState({
        phonePlaceholder: "gamesplaceholder"
      })
    }



    if (await this.checkAuth()) {
      this.props.history.push("/");
    }
    /* check if portal = zain-iq  to hidden login form use show stats prop*/
    var url = window.location.href
    if (url.includes('zain-iq')) { this.setState({ show: false }) }
  }



  async login(e) {
    e.preventDefault();
    const { phone, password } = this.state;
    if (!this.isValid(phone, password)) {
      this.setState({
        errors: true,
        errorMsg: this.props.t("Empty Phone Number Or Password")
      });
      return;
    }
    const hasAccess = await client.login(phone, password);

    if (!hasAccess) {
      if (urlGamesroid) {
        return this.setState({
          errors: true,
          errorMsg: this.props.t("invalid_phone_password_gamees")
        });

      }
      else if (urlgamezone) {
        return this.setState({
          errors: true,
          errorMsg: this.props.t("invalid_phone_password_gz")
        });

      }
      else {
        return this.setState({
          errors: true,
          errorMsg: this.props.t("invalid_phone_password")
        });
      }
    }

    else {
      if (urlgamezone) {
        this.openModalgamezone()
      }

      else {
        // start redirecting here
        if (localStorage.getItem('gameURL')) {
         //console.log('gameID', localStorage.getItem('gameURL'))
          let gameURL = localStorage.getItem('gameURL');
          this.props.history.push(gameURL)
        } else {
          this.props.history.push("/");
        }

      }
    }

  }


  checkTerms(e) {
    e.preventDefault();
    const { phone } = this.state;
    const terms = client.Terms(phone);
    if (terms) {
      this.login(e);
    }
  }

  condition(e) {
    this.closeModalgamezone()
    this.props.history.push("/");
  }

  async checkAuth() {
   //console.log(this.props.gamesCo, " this.props.gamesCo")

    const auth = await client.auth();
    return auth;
  }
  isValid(phone, password) {
    return phone && password ? true : false;
  }

  onInputChange(name, value) {

    var newValue = value;
    // add number '2' to phone number in egypt case 
    var phoneEgy = urlEgy && !value.startsWith('2')

    if (phoneEgy && value.slice(0, 4).includes('011')
      || phoneEgy && value.slice(0, 4).includes('012')) {
      newValue = '2' + newValue;
     //console.log("add 2")

    }

    // add number '251' to phone number in ethio case
    var phoneEthio = urlethio && value.startsWith('0')
    if (phoneEthio && name === 'phone') {
      newValue = newValue.substr(1)
    }

    if (urlethio && name === 'phone' && !value.startsWith('251')) {
      newValue = '251' + newValue
    }
    // remove number '2' from number in gamesroid case
    var phoneGamesroid = urlGamesroid && value.startsWith('2')

    if (phoneGamesroid && value.slice(0, 4).includes('011')
      || phoneGamesroid && value.slice(0, 4).includes('012')
      || phoneGamesroid && value.slice(0, 4).includes('010')
      || phoneGamesroid && value.slice(0, 4).includes('015')) {
     //console.log("remove 2")
      newValue = newValue.substr(1)
    }


   //console.log("send phone", newValue)
   //console.log(this.state)
    this.setState({
      [name]: newValue,
      errors: false
    });
   //console.log(this.state)
  }
  render() {


    const { errors, errorMsg, phonePlaceholder, showLoginPage, Img } = this.state;
    const { t } = this.props;
    return (
      <GamezroidComLoginPageDisplay className="">
        {showLoginPage ? (
          <div className="formPage formpagecom">
            <form
              onSubmit={e => {
                this.login(e);
              }}
            >

              {localStorage.getItem('showdouble') == 'true' ?
                <div className="globalcoins" style={{ top: '22%' }}>
                  <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
                </div>
                : ""}
              <div className="formsLayout ramdanformsLayout" >
                <div className="header" style={{ marginTop: "11px" }}>
                  <Link to="/">
                    <div className="logo" />
                  </Link>
                </div>

                {localStorage.getItem('showdouble') == 'true' ?
                  <div className="globalcoins" style={{ top: '22%' }}>
                    <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
                  </div>
                  : ""}
                <div style={{ marginTop: '42px', marginBottom: '-32px' }}>
                  <img src={Img} width="260" height="140" />
                </div>
                {/* <div className="smsText" style={this.state.show ? { display: 'none' } : {}}>
                <p >يجب عليك الدخول عن طريق الرابط الموجود في SMS </p>
              </div> */}
                <div className="center gamezroidLoginCenter centerramdan">

                  <div className="form" style={this.state.show ? {} : { display: 'none' }}>
                    <div className="iconInput iconInputcom">
                      <i className="icon phone iconphoneCom" />
                      <input
                        type="text"
                        className="input"
                        autoComplete="phone"
                        placeholder={t(this.state.phonePlaceholder)}
                        onChange={e =>
                          this.onInputChange("phone", e.target.value)
                        }
                      />
                    </div>
                    <div className="iconInput iconInputcom">
                      <i className="icon lock iconlockCom" />
                      <input
                        type="password"
                        className="input"
                        placeholder={t("enter_password")}
                        autoComplete="password"
                        onChange={e =>
                          this.onInputChange("password", e.target.value)
                        }
                      />
                    </div>
                    {!urlGamesroid ?
                      <div className="forgotpass" >
                        <Link to="forget-password" style={{ color: '#c7d9e9' }}>
                          <Trans>login.forgot_password</Trans>
                        </Link>
                      </div> : ""}



                  </div>
                </div>

                <div className="footer" style={this.state.show ? {} : { display: 'none' }}>
                  <div className="normBtn">
                    <button className="btn" style={{ background: "#0099FF" }}>
                      <Trans>sign_in</Trans>
                    </button>
                  </div>

                  {(

                    <p>
                      <Trans>login.don’t_have_account</Trans>{" "}
                      <Link to="Ramdanksignup" style={{ color: "#0099ff" }}>
                        <Trans>sign_up</Trans>
                      </Link>
                    </p>
                  )}

                  <Modal
                    visible={urlgamezone && this.state.visibleModalgamezone}
                    width="350"
                    height="500px"
                    effect="fadeInUp"
                    padding="0"
                    onClickAway={() => this.closeModalgamezone()}
                  >
                    <div className="gamezonemodal">
                      <h2><Trans>GameZone</Trans></h2>
                      <p><Trans>DEAR SUBSCRIBER GAME ZONE, ACCEPT THE TERMS AND CONDITIONS</Trans></p>
                      <img src="/assets/images/gamepopup.png" alt="img" />
                      <p><a href="http://ao.playit.mobi/tos" target="_blank"><Trans>TERMS AND CONDITIONS</Trans></a></p>
                      <div>
                        <button className="btn" onClick={(e) => { this.checkTerms(e) }}><Trans>ACCEPT</Trans></button>
                        <button className="btn" onClick={(e) => { this.condition(e) }}><Trans>TERMS AND CONDITIONS</Trans></button>

                      </div>

                    </div>
                  </Modal>

                </div>
              </div>
            </form>
            {errors ? <FloatingAlert type="error">{errorMsg}</FloatingAlert> : ""}
          </div>
        ) : null}
      </GamezroidComLoginPageDisplay>
    );
  }
}



/* End Update State Use REDUX */
export default withTranslation()(GamezroidComLoginPage)


