import styled from "styled-components";
import xsImg from "../../assets/images/leaderboard/xs-anonymous.svg";
import smImg from "../../assets/images/leaderboard/sm-anonymous.svg";

const RankingPageDisplay = styled.div`
	.xsAvatar {
		height: 54px;
		width: 31px;
        background: url(${xsImg}) center center no-repeat;
	}
	.smAvatar {
		background: url(${smImg}) center center no-repeat;
		height: 68px;
		width:55px;
	}
	.rankImg{
		position: absolute;
		top: 3px;
		left: 3px;
	}
`;	export default RankingPageDisplay;

export const Header = styled.div`
	display:flex;
	align-items:flex-end;
	justify-content: space-around;
	min-height:200px;
	.first,.second ,.third{
		display:flex;
		flex-direction:column;
		align-items:center;
	}

	.name{
		width: 80px;
		padding-bottom: 5px;
		white-space: nowrap;
		color: var(--sidebardetailscolor)
	}
	.points{
		color:black;
		font-weight:bold;
		color: var(--sidebardetailscolor)
	}
`;

export const Rank = styled.div`
	 display:flex;
	 justify-content: space-around;
	 align-items: center;
	 padding-top:40px;
	 background: none;
	 .thumb{
		 border: solid 1px #dddddd;
		 border-radius:50%;
		 width: 37px;
		 height: 37px;
		 position: relative;
	 }
	 .xsAvatar {
		height: 36px;
		width: 36px;
	 }
`;

export const Img = styled.div`
	width: 31px;
	height: 30px;
	background: url('${p => p.img}') center center no-repeat;
	background-size: cover;
	border-radius:50%;
`;
