import styled from "styled-components";
const SearchDisplay = styled.div`
  position: relative;
  
  .searchInput {
    background-color: var(--lightBgColor);
    padding: 0 25px;
    display: flex;
    align-items: center;
    input {
      background-color: var(--lightBgColor);
      width: 100%;
      height: 50px;
      outline: none;
      border: none;
      color: var(--textColor);
      padding: 0 35px;
    }
  }
  .erroeMss{
    line-height:2.25 !important;
    height:none !important
}
  .results {
    box-sizing: border-box;
    height: calc(100vh - 112px);
    width: 100%;
    overflow-y: auto;
    background: var(--darkBgColor);
    padding: 20px 25px;
    text-align: justify;
    .sres {
      padding: 15px 30px;
      a{
        color:var(--textColor);
      }
    }
  }
`;
export default SearchDisplay;
