import styled from "styled-components";
const NewAsiacellSubscribePageDisplay = styled.div`
background: ${p => p.bgImg};
  border-radius: 25px;

  .links-Section{
    position: absolute;
      top: 13%;
      width: 75%;
  }
  .header_stckwuit
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid var(--primaryColor);

  }
  .links-Section a {
    color:white
  }
  .links-Section h3 {
    color:white;
    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important
    font-size:14px;
    font-weight:400;
    text-transform: capitalize;
    text-align:center;


  }
  .links-Section button{
    
    display:block;
   
    margin: 40px auto;
  }
.btn_model
  {
    margin: 50px auto!important
  }

  .formsLayout {
    margin: 0 20px;
    min-height: 135vh;
    .header {
      margin-top: 30px;
  }
    .footer
    {
      margin-bottom: 35px;
    }
  }
  .formPage
  {
      background:rgba(0, 0, 0, 0.78)
  }
  .selectGame
  {
    width: 60%;
    border-bottom: 1px solid #868282;
    margin: 15px 10px;

  }
  .btn_org,.btn_ooredoo,.btn_zainjordan,.btn_gamezroid,.btn_stckwuit,.btn_sabafon
  {
    margin: 130px auto!important;
  }

  .iconInputGame
  {
    width: 60%!important;
  }
  

`
export default NewAsiacellSubscribePageDisplay;
