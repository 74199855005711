import styled from "styled-components";
const WinnerssDisplay = styled.div`
min-height: 140vh;
background: var(--insideBg);
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top: 0;
width: 100%;
@media(max-width: 1024px){
  background-position: center;
}
.winnerscontent
{
  display: flex;
  overflow: auto;
  max-height: 70vh;
}

`; 
export default WinnerssDisplay;



export const Rank = styled.div`
width: 100%;
	
`;



