import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Trans, withTranslation } from "react-i18next";
import "./packagesPage.scss";
import PackagesDisplay from "./packagesPage.styled";
import { client } from "../../auth/Client";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";




class packagesPage extends Component {

  constructor() {
    super();
    this.state = {
      errors: false,
      loading: false,
      showPoint: false,
      verify: false,
      phone: "",
      Img: "",
      textPoint: "",
      errorMsg: ""
    }
  }

  componentDidMount() {
    let lang = localStorage.getItem('i18nextLng');
    if (lang == 'ar') {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/packages.png"

      })

    }
    else {
      this.setState({
        Img: "./assets/images/HomepagecomGamezroid/packages-en.png"

      })

    }
    this.checkAuth();
    this.checkphone();
    this.checksocialApi();

  }

  firApi = async (socialAction, point) => {
    let product = "";
    if (socialAction == 'share') {
      product = "share";
    }
    else {
      product = "like"
    }
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)

    const options = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product,
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/fbShare';
    try {
      const res = await axios.post(apiUrl, options);
     //console.log("res", res.data.status)
      if (res.data.status == true) {
        if (product == 'share') {
          document.getElementById('sharactive').removeAttribute('hidden');
          document.getElementById('share').style.pointerEvents = 'none';
          window.open("https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fgamezroid.com", "_blank");
        }
        else {
          //pop like
          document.getElementById('likeactive').removeAttribute('hidden');
          document.getElementById('like').style.pointerEvents = 'none';
          const self = this;
          this.setState({
            showPoint: true,
            textPoint: point
          })
          document.getElementsByClassName('popdiscount')[0]
            .addEventListener('click', function (event) {
              // do something
              self.setState({
                showPoint: false,
              })
            });
          // setTimeout(() => {
          //   this.setState({
          //     showPoint: false,
          //   })

          // }, 5000);
        }
      }
      else {
        this.setState({
          errors: true,
          errorMsg: res.data.message

        })
      }

    } catch (err) {
     //console.log(err)

    }
  }

  verifyphone = async () => {
    let msisdn = this.state.phone;
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)


    const verifyoptions = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      msisdn,
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkmobile';
    try {
      const res = await axios.post(apiUrl, verifyoptions);
     //console.log("res", res.data)
      if (res.data.status == true) {
        this.setState({
          verify: false
        })
      }
      else {
        this.setState({
          errors: true,
          errorMsg: res.data.message
        })


      }
    } catch (err) {
     //console.log(err)

    }

  }
  checkphone = async () => {

    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)

    const phoneoptions = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product: "checkNum",
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkUserPhone';
    try {
      const res = await axios.post(apiUrl, phoneoptions);
     //console.log("res", res.data)
      if (res.data.showPOP == true && res.data.status == false) {
        this.setState({
          verify: true
        })
        // document.getElementsByClassName('.package-details').style.pointerEvents = 'none';

      }
    } catch (err) {
     //console.log(err)

    }

  }


  checksocialApi = async () => {
    this.setState({
      loading: true,

    })
    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)

    const shareoptions = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product: 'share',
      timestamp,
      authcode
    };

    const likeoptions = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product: 'like',
      timestamp,
      authcode
    };
    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/checkshare';
    try {
      const res = await axios.post(apiUrl, shareoptions);
     //console.log("res", res.data)
      if (res.data.status == true) {
       //console.log("hhhh")
        this.setState({
          loading: false,
        });
        document.getElementById('sharactive').removeAttribute('hidden');
        document.getElementById('share').style.pointerEvents = 'none';

      }
      else {
        this.setState({
          loading: false,
        });

      }
    } catch (err) {
     //console.log(err)

    }

    try {
      const res = await axios.post(apiUrl, likeoptions);
     //console.log("res", res.data)
      if (res.data.status == true) {
        this.setState({
          loading: false,
        });
        document.getElementById('likeactive').removeAttribute('hidden');
        document.getElementById('like').style.pointerEvents = 'none';

      }
      else {
        this.setState({
          loading: false,
        });

      }

    } catch (err) {
     //console.log(err)

    }
  }


  firpointApi = async (money, point) => {
    this.setState({
      errors: false,
    })
    let product = "";
    let contentMoney = "";
    let contentDetails = "";
    let contentPoint = "";
    if (money == '2.5') {
      product = "05p";
      contentMoney = "smallmoney";
      contentDetails = "smallpoint_details";
      contentPoint = "smallpoint";
    }
    else if (money == '10') {
      product = "10p";
      contentMoney = "middelmoney";
      contentDetails = "middelpoint_details";
      contentPoint = "middelpoint";
    }
    if (money == '20') {
      product = "20p";
      contentMoney = "bigmoney";
      contentDetails = "bigpoint_details";
      contentPoint = "bigpoint";
    }

    let timestamp = new Date().getTime();
    const username = "gamezroid_ramadank";
    const password = "45rvsv5fd0dsfds12";
    var md5 = require('md5');
    let authcode = md5(username + timestamp + password)
    const options = {
      subscriptionContractId: localStorage.getItem('subscriptionContractId'),
      product,
      timestamp,
      authcode
    };
    // document.getElementById(contentDetails).style.display = "contents";
    document.getElementById(contentDetails).style.pointerEvents = 'none';
    document.getElementById(contentPoint).setAttribute('hidden', 'hidden');
    document.getElementById(contentMoney).removeAttribute('hidden');

    const apiUrl = 'http://api.playit.mobi/api/v1/tpay/on_demand';
    try {
      const res = await axios.post(apiUrl, options);
      if (res.data.status == true) {
        document.getElementById(contentDetails).style.pointerEvents = '';
        document.getElementById(contentPoint).removeAttribute('hidden');
        document.getElementById(contentMoney).setAttribute('hidden', 'hidden');
        const self = this;
        this.setState({
          showPoint: true,
          textPoint: point
        })

        document.getElementsByClassName('popdiscount')[0]
          .addEventListener('click', function (event) {
            self.setState({
              showPoint: false,
            })
          });

      }
      else {
        document.getElementById(contentDetails).style.pointerEvents = '';
        document.getElementById(contentPoint).removeAttribute('hidden');
        document.getElementById(contentMoney).setAttribute('hidden', 'hidden');



        this.setState({
          errors: true,
          errorMsg: res.data.message

        });

      }

    } catch (err) {
     //console.log(err)

    }
  }

  checkAuth = async () => {

    const authorized = await client.auth();
    if (!authorized) {
      this.props.history.push("/login");

    }
    // else {
    // }
  }

  onInputChange(value) {
   //console.log(value);

    this.setState({
      phone: value,
    })

  }
  render() {
    const { errors, errorMsg, loading, showPoint, textPoint, Img, verify } = this.state;
    let lang = localStorage.getItem('i18nextLng');

    return (
      <PackagesDisplay>
        {loading ?
          <div>
            <div className="spinerC">
              <div className="spinner"></div>
            </div>
          </div> :
          <>
            <div className="packages_content">
              <div className="bannerpack">
                <img src={Img} width="220" height="190" />
              </div>

              {localStorage.getItem('showdouble') == 'true' ?
								<div className="globalcoins" style={{top:'21%'}}>
									<img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
								</div>
								: ""}
              <div className="packages">
                <div className="package-details" id="smallpoint_details"
                  onClick={() => {
                    this.firpointApi('2.5', '100');
                  }}>
                  <div className="loadMoney" id="smallmoney" hidden>
                  </div>

                  <div id="smallpoint">
                    <img
                      src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/smallpoint.png" : "./assets/images/HomepagecomGamezroid/smallpoint-en.png"}
                      width="100"
                      height="106" />

                  </div>

                </div>

                <div className="package-details" id="middelpoint_details"
                  onClick={() => {
                    this.firpointApi('10', '500');
                  }}>
                  <div className="loadMoney" id="middelmoney" hidden>
                  </div>

                  <div id="middelpoint">
                    <img
                      src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/middlepoint.png" : "./assets/images/HomepagecomGamezroid/middlepoint-en.png"}
                      width="100"
                      height="106" />

                  </div>

                </div>

                <div className="package-details" id="bigpoint_details"
                  onClick={() => {
                    this.firpointApi('20', '1200');
                  }}>
                  <div className="loadMoney" id="bigmoney" hidden>
                  </div>

                  <div id="bigpoint">
                    <img
                      src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/bigpoint.png" : "./assets/images/HomepagecomGamezroid/bigpoint-en.png"}
                      width="100"
                      height="106" />

                  </div>

                </div>




              </div>



              <div className="social">
                <div className="share" id="share"
                  onClick={() => {
                    this.firApi('share', '50');
                  }}>
                  <div className="noactiveshare" id="sharactive" hidden>
                  </div>

                  <div>
                    <img
                      src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/newshare.png" : "./assets/images/HomepagecomGamezroid/newshare-en.png"}

                      width="102" height="77" />
                  </div>

                </div>


                <div className="like" id="like"
                  onClick={() => {
                    this.firApi('like', '50');
                  }}>
                  <div className="noactivelike" id="likeactive" hidden> </div>

                  <div>
                    <img
                      src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/newlike.png" : "./assets/images/HomepagecomGamezroid/newlike-en.png"}

                      width="102" height="77" />
                  </div>


                </div>
                <Link to="/invite" >
                  <div className="invite" id="invite">
                    <div>
                      <img
                        src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/invite.png" : "./assets/images/HomepagecomGamezroid/invite-en.png"}
                        width="102" height="77" />
                    </div>



                  </div>

                </Link>

                <Link to="/home" >
                  <div className="playgame" id="playgame">
                    <div>
                      <img
                        src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/playgame.png" : "./assets/images/HomepagecomGamezroid/playgame-en.png"}
                        width="102" height="77" />
                    </div>



                  </div>

                </Link>



              </div>
              <div className="notes">
                <div style={{ height: '4vh' }}></div>
                <p> <Trans>packageFooter</Trans> </p>

              </div>
            </div>
          </>}


        {showPoint ?
          <div className="popdiscount">
            <div className="poptext poptextpackages">
              <p><Trans>added</Trans> <span className="winpoint">{textPoint}</span> <Trans>point</Trans></p>
              <p><Trans>point_hour</Trans></p>

            </div>

          </div>
          : null}

        {verify ?
        <div className="verifypopphone">
          <div className="phone_verify">
            <p style={{ fontWeight: '200' }}><Trans>phone_number</Trans></p>
            <input
              type="text"
              className="input inputpack"
              autoComplete="phone"
              onChange={e =>
                this.onInputChange(e.target.value)
              }
            />
            <button className="btn" onClick={() => {
              this.verifyphone();
            }}>
              <Trans>Verify_code</Trans>

            </button>
          </div>
          </div>
          : null}
        {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""}

      </PackagesDisplay>
    );
  }
}

/* End Update State Use REDUX */
export default withTranslation()(packagesPage)
