import React, { Component } from 'react';
import './SearchPage.scss';
import SearchPageDisplay from './SearchPage.styled';

export default class SearchPage extends Component {
	render() {
		return (
			//TODO: Mandatory : Priority #1
			<SearchPageDisplay>
				SearchPage_Component
			</SearchPageDisplay>
		)
	}
}
