import React, { Component } from "react";
import './WheelPage.scss';
import WheelPageDisplay from './WheelPage.styled';
import axios from "axios";
import { useHistory } from "react-router";
import Modal from 'react-awesome-modal';
import { client } from "../../auth/Client";

import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class WheelPage extends Component {
	constructor() {
		super();
		this.state = {
			errors: false,
			loading: false,
			showPrize: false,
			showwheel: false,
			visable: false,
			chargeMsg: "",
			prize: "",
			wheels: 0,
			index: 0,
			errorMsg: ""
		}
	}

	componentDidMount() {
		this.checkAuth();

	}

	closemodel() {
		this.setState({
			visable: false
		})

	}


	checkwheel = async () => {
		let timestamp = new Date().getTime();
		const username = "gamezroid_ramadank";
		const password = "45rvsv5fd0dsfds12";
		var md5 = require('md5');
		let authcode = md5(username + timestamp + password)

		const options = {
			userId: localStorage.getItem('userId'),
			timestamp,
			authcode
		};
		const apiUrl = 'http://api.playit.mobi/api/v1/tpay/numberOfReturns';
		try {
			const res = await axios.post(apiUrl, options);
			if (res.data.status == true) {
				this.setState({
					showwheel: false,
				})
			}
			else {

				document.getElementById('canvas').style.pointerEvents = 'none';
				this.setState({
					showwheel: true,
				
				})
				if(res.data.message != "")
				{
					this.setState({
						visable: true,
						chargeMsg: res.data.message
					})

				}
			}
			this.setState({
				wheels: res.data.wheels,
			})

		} catch (err) {
			console.log(err)

		}
	}
	checkAuth = async () => {

		const authorized = await client.auth();
		if (!authorized) {
			this.props.history.push("/login");
		}
		else {
			this.checkwheel();
			this.wheel()
		}
	}

	wheel() {
		this.setState({
			loading: true
		})
		let canvas = document.getElementById("canvas");
		let sections =
			[`50 ${this.props.t("card")}`,
			`5  ${this.props.t("point")}`,
			`500 ${this.props.t("cash")}`,
			`10 ${this.props.t("point")}`,
			`100 ${this.props.t("card")}`,
			`20 ${this.props.t("point")}`,
			`1000 ${this.props.t("cash")}`,
			`50 ${this.props.t("point")}`,
			`200 ${this.props.t("card")}`,
			`100 ${this.props.t("point")}`,
			`200 ${this.props.t("point")}`]

		let colors = ["#08e900", "#005cff", "#dc1209", "#b303d4"];
		let wheels = null;
		let frame = null;
		let g;
		let walk;


		function repaint(angle) {
			walk = document.querySelector('.newwheel')
			// let r = Math.min(window.innerWidth, window.innerHeight) / 2.25 | 0;
			// let r = Math.min(walk.clientWidth, walk.clientHeight) / 2.25 | 0;
			let r = Math.min(window.innerWidth, window.innerHeight) / 3.00 | 0;
			// r = 100;


			if (wheels === null) {
				console.log("wheels")
				wheels = [];
				for (let selected = 0; selected < sections.length; selected++) {
					let c = document.createElement("canvas");
					// c.width = c.height = 2 * r + 10;
					c.width = c.height = 2 * r + 5;
					let ctx = c.getContext("2d"), cx = 5 + r, cy = 5 + r;
					let g = ctx.createRadialGradient(cx, cy, 0, cx, cy, r);
					g.addColorStop(0, "rgba(0,0,0,0)");
					g.addColorStop(1, "rgba(0,0,0,0.5)");
					for (let i = 0; i < sections.length; i++) {
						let a0 = 2 * Math.PI * i / sections.length;
						let a1 = a0 + 2 * Math.PI / (i == 0 ? 1 : sections.length);
						let a = 2 * Math.PI * (i + 0.5) / sections.length;
						ctx.beginPath();
						ctx.moveTo(cx, cy);
						ctx.arc(cx, cy, r, a0, a1, false);
						ctx.fillStyle = colors[i % 4];
						ctx.fill();
						ctx.fillStyle = g;
						//ctx.fillStyle = 'yellow';
						ctx.fill();
						ctx.save();
						if (i == selected) {
							ctx.fillStyle = "#FFF";
							ctx.shadowColor = "#FFF";
							ctx.shadowBlur = r / 20;

						} else {
							// لون الكلام
							ctx.fillStyle = "#AAA";
							ctx.shadowColor = "#000";
							ctx.shadowBlur = r / 100;
							ctx.font = "10px Arial";

						}
						// ctx.font = "bold " + r / sections.length * 1.6 + "px serif";
						ctx.font = "bold " + r / sections.length * 1.2 + "px serif";

						ctx.textAlign = "center";
						ctx.textBaseline = "middle";
						ctx.translate(cx, cy);
						ctx.rotate(a);
						ctx.fillText(sections[i], r * 0.62, 0);
						ctx.restore();
					}
					wheels.push(c);
				}
			}
			if (frame === null) {
				console.log("frame")

				frame = document.createElement("canvas");
				// frame.width = frame.height = 10 + 2 * r * 1.25 | 0;
				frame.width = frame.height = 10 + 2 * r * 2.50 | 0;

				let ctx = frame.getContext("2d"), cx = frame.width / 2, cy = frame.height / 2;
				ctx.shadowOffsetX = r / 80;
				ctx.shadowOffsetY = r / 80;
				ctx.shadowBlur = r / 40;
				// ctx.shadowColor = "rgba(0,0,0,0.5)";
				ctx.shadowColor = "rgba(0,0,0,0.1)";

				ctx.beginPath();
				ctx.arc(cx, cy, r * 1.025, 0, 2 * Math.PI, true);
				ctx.arc(cx, cy, r * 0.975, 0, 2 * Math.PI, false);
				//border
				//ctx.fillStyle = "#444";
				ctx.fillStyle = "#ffb800";

				ctx.fill();
				ctx.shadowOffsetX = r / 40;
				ctx.shadowOffsetY = r / 40;
				g = ctx.createRadialGradient(cx - r / 7, cy - r / 7, 0, cx, cy, r / 3);
				// g.addColorStop(0, "#FFF");
				// g.addColorStop(0.2, "#F44");
				// g.addColorStop(1, "#811");

				g.addColorStop(0, "#FFF");
				g.addColorStop(0.2, "#ffb800");
				g.addColorStop(1, "#ddb100b5");

				//ddb100b5
				ctx.fillStyle = g;
				ctx.beginPath();
				// ctx.arc(cx, cy, r / 3.5, 0, 2 * Math.PI, false);
				ctx.arc(cx, cy, r / 3.5, 0, 2 * Math.PI, false);

				ctx.fill();

				// ctx.translate(cx, cy);
				// ctx.rotate(Math.PI - 1.6);

				// // ctx.rotate(Math.PI - 0.2);
				// ctx.beginPath();

				// // ctx.moveTo(- r * 1.1, - r * 0.05);
				// ctx.moveTo(- r * 1.2, - r * 0.2);

				// // ctx.lineTo(- r * 0.9, 0);
				// // ctx.lineTo(- r * 1.1, r * 0.08);

				// ctx.lineTo(- r * 0.9, 0);
				// ctx.lineTo(- r * 1.2, r * 0.09);
				// // سهم
				// ctx.fillStyle = "red";
				// ctx.fill();


				var logoImg = new Image();
				logoImg.onload = function () {
					var logo = {
						img: logoImg,
						x: (canvas.width / 2) - (logoImg.width / 2),
						y: (canvas.height / 2) - (logoImg.height / 2)
					}
					// ctx.drawImage(logo.img, (logo.x), (logo.y), logo.img.width, logo.img.height);
					ctx.drawImage(logo.img, logo.x + 150, logo.y - (wheels[selected].height / 2 -2), logo.img.width, logo.img.height);

					// ctx.drawImage(logo.img, 150, 150, 20, 20);
				}
				logoImg.src = "./assets/images/HomepagecomGamezroid/ic_cursor.png";






			}




			canvas.width = window.innerWidth;
			canvas.height = window.innerHeight;
			let cx = window.innerWidth / 2, cy = window.innerHeight / 2;
			let ctx = canvas.getContext("2d");
			let selected = (Math.floor((- 1.5 - angle) * sections.length / (2 * Math.PI))
				% sections.length);
			if (selected < 0) selected += sections.length;
			ctx.save();
			ctx.translate(cx, cy);
			ctx.rotate(angle);
			ctx.translate(-wheels[selected].width / 2, -wheels[selected].height / 2);
			ctx.drawImage(wheels[selected], 0, 0);
			ctx.restore();
			ctx.drawImage(frame, cx - frame.width / 2, cy - frame.height / 2);



			var logoImg = new Image();
			logoImg.onload = function () {
				var logo = {
					img: logoImg,
					x: (canvas.width / 2) - (logoImg.width / 2),
					y: (canvas.height / 2) - (logoImg.height / 2)
				}
				// ctx.drawImage(logo.img, (logo.x), (logo.y), logo.img.width, logo.img.height);
				ctx.drawImage(logo.img, logo.x + 5, logo.y - (wheels[selected].height / 2 + 13), logo.img.width, logo.img.height);

				// ctx.drawImage(logo.img, 150, 150, 20, 20);
			}
			logoImg.src = "./assets/images/HomepagecomGamezroid/ic_cursor.png";
		}

		let angle = 0, running = false;
		function spinTo(winner, duration) {
			let final_angle = (-1.5) - (0.5 + winner) * 2 * Math.PI / sections.length;
			let start_angle = angle - Math.floor(angle / (2 * Math.PI)) * 2 * Math.PI - 5 * 2 * Math.PI;
			let start = performance.now();
			function frame() {
				let now = performance.now();
				let t = Math.min(1, (now - start) / duration);
				t = 3 * t * t - 2 * t * t * t; // ease in out
				angle = start_angle + t * (final_angle - start_angle);
				repaint(angle);
				if (t < 1) requestAnimationFrame(frame); else running = false;
			}
			requestAnimationFrame(frame);
			running = true;
		}
		canvas.onmousedown = () => {
			console.log("test")
			if (!running) {
				this.checkwheel();
				//spinTo(Math.random() * sections.length | 0, 5000);
				var wheel = this.state.wheels;
				console.log("whell", wheel)
				if (wheel > 0) {
					getPrize();
				}
				// spinTo(12 | 0, 5000);
			}
		};

		let getPrize = async () => {
			let timestamp = new Date().getTime();
			const username = "gamezroid_ramadank";
			const password = "45rvsv5fd0dsfds12";
			var md5 = require('md5');
			let authcode = md5(username + timestamp + password)

			const options = {
				userId: localStorage.getItem('userId'),
				//userId: '5XFjT10hp4',
				timestamp,
				authcode
			};
			const apiUrl = 'http://api.playit.mobi/api/v1/tpay/getPrize';
			try {
				const res = await axios.post(apiUrl, options);
				if (res.data.status == true) {
					spinTo(res.data.index, 5000);
					let index = res.data.index;
					for (let i = 0; i < sections.length; i++) {
						if (i == index) {
							this.setState({
								prize: sections[i]
							})
						}
					}

					setTimeout(() => {
						this.setState({
							showPrize: true
						})
						const self = this;

						document.getElementsByClassName('popdiscount')[0]
							.addEventListener('click', function (event) {
								// do something
								self.setState({
									showPrize: false,
								});
								window.location.reload();
							});

					}, 5000);



				}
				// spinTo(4, 5000);




			} catch (err) {
				console.log(err)

			}

		}

		repaint(angle);

		let csz = null;
		setInterval(function () {
			let sz = window.innerWidth + "/" + window.innerHeight;
			if (csz !== sz) {
				csz = sz;
				wheels = frame = null;
				repaint(angle);
			}
		}, 10);



	}

	render() {
		const { loading, showPrize, prize, showwheel, wheels, chargeMsg, visable } = this.state;
		let lang = localStorage.getItem("i18nextLng")

		return (

			<WheelPageDisplay className="newwheel">
				{loading ?
					<>
						<div className="wheel">
							<div className="roll">
								<img width="200" height="100"
									src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/roll.png" : "./assets/images/HomepagecomGamezroid/roll-en.png"}
								/>
								{/* <img src="./assets/images/HomepagecomGamezroid/ic_cursor.png" /> */}
							</div>

							{localStorage.getItem('showdouble') == 'true' ?
								<div className="globalcoins" style={{ top: '22%' }}>
									<img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
								</div>
								: ""}
							<div style={{ marginTop: '-5px' }}>
								<p><Trans>You_have</Trans> <span className="wheelnumber">{wheels}</span> <Trans>roll</Trans></p>
								<p><Trans>wheel_once</Trans></p>
							</div>

							<div className="walk">
								<img width="240" height="116"
									src={lang == 'ar' ? "./assets/images/HomepagecomGamezroid/updatewalk.png" : "./assets/images/HomepagecomGamezroid/updatewalk-en.png"}
								/>

								{showwheel ?
									<div className="block">
										<div>
											<img src="./assets/images/HomepagecomGamezroid/block2.png" alt="block" />
										</div>

									</div>
									: null}



							</div>



						</div>
						<canvas id="canvas"></canvas>
					</>


					: <div>
						<div className="spinerC">
							<div className="spinner"></div>
						</div>
					</div>}


				{showPrize ?
					<div className="popdiscount">
						<div className="poptext popuptextprize">
							<p><Trans>prize</Trans> {prize}</p>
						</div>

					</div>
					: null}

				{visable ?
					<div className="renewal">
						<div className="renewaltext chargemsg">
							<p style={{ fontWeight: '200' }}><Trans>{chargeMsg}</Trans> </p>
							<button className="btn" onClick={() => {
								this.closemodel();
							}}>
								<Trans>ok</Trans>

							</button>
						</div>
					</div>
					: ""}

				{/* {errors ? <FloatingAlert type="error"><Trans>{errorMsg}</Trans></FloatingAlert> : ""} */}

			</WheelPageDisplay>
		)
	}
}

export default withTranslation()(WheelPage)
