import styled from "styled-components";
const OoredoopsSignupPageDisplay = styled.div`
	background: ${p => p.bgImg};
  background: rgb(0 0 0);
  background-size: cover;
  .operatorSignupGamez {
    min-height: 117vh !important;
  }
  .headerooredoo , .headerasiacell
      {
        margin-top: 5px;
        width: 100%;
        border-bottom: 2px solid rgb(242, 200, 48);
      }
      .links-SectionOredoo {
        top: 9% !important;
        width:85%
        
    }
  .links-Section{
    position: absolute;
      top: 30%;
  }

  .links-Section a {
    color:white
  }
  .links-Section button{
    margin-bottom: 15px;
    display:block;
  }
  .formsLayoutgamezorid {
    min-height: 126vh;
}
  .formsLayout {
    margin: 0 20px;
    .inputs {
      select {
        width: 65%;
        height: 43px;
      }
      input {
        width: 65%;
      }

    .footer{
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }
    
  }
  

  .iconSelect .icon.money {
    
    left: -15px !important;
}  

`;	export default OoredoopsSignupPageDisplay;
