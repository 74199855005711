import styled from "styled-components";
const PackagesDisplay = styled.div`
min-height: 121vh;
background: var(--insideBg);
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top: 0;
width: 100%;
@media(max-width: 1024px){
  background-position: center;
}
.packages_content
{
  display: flex;
    flex-flow: column;
    width: 40%;
    margin: auto;
    align-items: center;
}

}
.packages
{
  // display: flex;
  //   flex-flow: row;
  //   align-items: center;

  //   .package-details
  //   {
  //     display: flex;
  //   align-items: center;
  //   }
}

`;
export default PackagesDisplay;



