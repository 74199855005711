
/* check if portal = zain-iq  to hidden login form use show stats prop*/


export const list5 = [
    {
      url: "/profile",
      text: "side_nav.profile",
      logged: true
    }, 
    
    {
      url: "/my-games",
      text: "my_games",
      logged: true
    },
    {
      url: "/leaderboard",
      text: "side_nav.ranking"
    },
    {
      url: "/tos",
      text: "terms_and_conditions"
    },
    /*{
      url: "/videoPlay",
      text: "side_nav.videos_tutorials"
    },*/
    {
      url: "/change-language",
      text: "side_nav.language"
    },
    // {
    //   url: "/logout",
    //   text: "side_nav.logout",
    //   logged: true
    // },
    {
      url: "/unsubscribe",
      text: "unsubscribe",
      logged: true
    }
    // {
    //   url: "/women-day",
    //   text: "side_nav.Women_day",
    //   logged: true
    // },


    // {
    //   url: "/valentine-pastime",
    //   text: "side_nav.valentine_pastime",
    //   logged: true
    // },
    // {
    //   url: "/payment",
    //   text: "payment_methods",
    //   logged: true
    // },
  ];
  