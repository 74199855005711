import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import envo from "../../environments/environmentProd";
import FloatingAlert from "../../components/FloatingAlert/FloatingAlert";
import Confirm from "./components/Confirm";
import GameDetails from "./components/GameDetails";
import Play from "./components/Play";
import PlayCompetition from './components/playCompetition'
import "./GameFramePage.scss";
import GameFramePageDisplay from "./GameFramePage.styled";
import Fullscreen from "fullscreen-react";



const apiUrl = envo.apiUrl;
const urlcompetition =  window.location.href.includes("competition");
//const urlcompetition =  window.location.href.includes("local");

 



//TODO: Add solid star icon for favourite game
function GameFramePage(props) {
  const [confirmState, setConfirmState] = useState(false);
  const [playState, setPlayState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [gameState, setGameState] = useState({});
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEnter, setIsEnter] = useState(false);

  const gameID = props.match.params.gameID;



  useEffect(() => {
    async function fetchData() {
      setLoadingState(true);
      const options = {
        headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
      };

      const params = {
        id: gameID
      };
      try {

       
        const res = await axios.post(`${apiUrl}/playit/games`, params, options);
        if (!res.data) return false;
        const data = res.data;
      
        if (data.status !== "success") {
          return false;
        }
        
        
        setGameState(data.game);
        setLoadingState(false);
      } catch (err) {
        setLoadingState(false);
        setErrorState(true);
        setErrorMsg(props.t("network_failed"));
      }
    }
    fetchData();
  }, [gameID]);

   
  
  

  return (
    <GameFramePageDisplay className={urlcompetition ? "PlayingComp":"playing "}>
  
    {!urlcompetition ? 
    (<Play gameIframe={gameState.game_folder} gameID={gameID} />)
    :
    (<PlayCompetition gameFreeIframe={gameState.game_folder} gameID={gameID} />)
    //  (
    //   <div>
    //   <button
    //     onClick={() => {
    //       setIsEnter(true);
    //     }}
    //   >
    //     Go Fullscreen
    //   </button>
  
    //   <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
    //     <div className="full-screenable-node" id="fullscreen">
    //     <PlayCompetition gameFreeIframe={gameState.game_folder} gameID={gameID} />
    //     </div>
    //   </Fullscreen>
    // </div>
    // )
    // <PlayCompetition gameFreeIframe={gameState.game_folder} gameID={gameID} />)
    }
    


          
    </GameFramePageDisplay>

  );
}

export default withTranslation()(GameFramePage);
