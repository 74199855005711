import styled from "styled-components";
import img from "../../assets/images/voucher.svg";
const VivaPageeDisplay = styled.div`
.voucherTitle {
	font-size:22px;
	text-align:left;
	padding: 20px 25px;
}
`;	export default VivaPageeDisplay;

export const Voucher = styled.div`
display:flex;
flex-direction:column;
align-items:center;
min-height:50vh;
justify-content: space-evenly;
.voucher {
	background: url(${img}) center center no-repeat;
	width:190px;
	height:175px;
}
.input{
	width:315px;
}
`