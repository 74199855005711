import axios from "axios";
import React, { Component } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import "./FAQPage.scss";
import FAQPageDisplay from "./FAQPage.styled";
import opreators from "../../operators/operators.json";

const apiUrl = envo.apiUrl;
const globalurl = window.location.href
const urlgamezroid = window.location.href.includes('gamezroid') && !globalurl.includes('asiacell') && !globalurl.includes('jawwal') && !globalurl.includes('zainiq') && !globalurl.includes('ethio')


class FAQPage extends Component {
  constructor() {
    super();
    this.state = {
      faqs: [],
      errors: false,
      errorMsg: ""
    };
  }

  

  componentDidMount() {
    const mainURL = window.location.hostname;
    const firstDot = mainURL.indexOf(".");
    const baseURL = mainURL.slice(0,firstDot)
    const operatorId = opreators[baseURL]

    // const operatorId = localStorage.operatorId;
    const lang = localStorage.i18nextLng;
    this.getFaqs(operatorId, lang);
  }
  async getFaqs(operatorId, lang) {
    const params = {
      operator_id: operatorId,
      locale: lang
    };
    try {
      const res = await axios.get(`${apiUrl}/playit/faqs`, { params });
      if (!res.data) return false;
      const data = res.data;
      if (data.status !== "success") {
        return false;
      }
      return this.setState({
        faqs: data.faqs,
        firstOpen: data.faqs[0]
      });
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        this.setState({
          errors: true,
          errorMsg: this.props.t("network_failed")
        });
      }
     //console.log(err);
      return false;
    }
  }
  render() {
    const { faqs } = this.state;
    const faq = faqs.map((i, k) => {
      return (
        <AccordionItem key={`faq-${k}`}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className="icon">
                <span></span>
              </div>
              <div className="txt" style={{color : urlgamezroid? '#fff': ''}}>{i.question}</div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{i.answer}</p>
          </AccordionItemPanel>
        </AccordionItem>
      );
    });
    return (
      <FAQPageDisplay>
        <div className="title" style={{color : urlgamezroid? '#0a2631eb': ''}}><Trans>faq</Trans></div>
        <Accordion allowZeroExpanded={true}>{faq}</Accordion>
      </FAQPageDisplay>
    );
  }
}
export default withTranslation()(FAQPage);