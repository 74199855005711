import axios from "axios";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import ConfirmDownload from "../../components/ConfirmDownload/ConfirmDownload";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import envo from "../../environments/environmentProd";
import FloatingAlert from "./../../components/FloatingAlert/FloatingAlert";
import "./GamePage.scss";
import GamePageDisplay, { Img } from "./GamePage.styled";
const apiUrl = envo.apiUrl;
class GamePage extends Component {
  constructor() {
    super();
    this.state = {
      game: {},
      confirm: false,
      loading: false,
      errors: false,
      errorMsg: ""
    };
  }
  componentDidMount() {
    const gameID = this.props.match.params.gameID;
    this.getGameDetails(gameID);


  }
  async getGameDetails(id) {
    const params = {
      id: id
    };
    try {
      this.setState({ loading: true });
      const res = await axios.get(`${apiUrl}/playit/games`, { params });
      if (!res.data) return false;
      const data = res.data;

      if (data.status !== "success") {
        return false;
      }

      return this.setState({
        game: data.game,
        loading: false
      });
    } catch (err) {
      if (err.response && err.response.status !== 200) {
        this.setState({
          errors: true,
          errorMsg: this.props.t("network_failed")
        });
      }
      return false;
    }
  }
  btnToggle() {
    const type = this.state.game.game_type;
    if (type === "andriod") {
      this.setState({
        confirm: !this.state.confirm
      });
    }
    if (type === "easy") {
      //this.props.history.push('pathToPlayGame')
    }
  }
  render() {
    const { game, confirm, loading, errors, errorMsg } = this.state;
    const selectedLang = localStorage.i18nextLng;
    return (
      <GamePageDisplay>
        {confirm ? (
          <ConfirmDownload apk={game.game_folder} btnToggle={() => { this.btnToggle() }} />
        ) : (
            <>
              <Navbar />

              {loading ? (
                <Loader type="gamePage" />
              ) : (
                  <>
                    <div className="gameHeader">
                      <Img alt={game.game_name} img={encodeURI(game.game_thumb)}></Img>
                      <div className="titleDetails">
                        <div className="title">
                          {selectedLang === "ar"
                            ? game.hasOwnProperty("game_name_ar") &&
                              game.game_name_ar !== "null"
                              ? game.game_name_ar
                              : game.game_name
                            : game.game_name}
                        </div>
                        <div className="details">
                          <div>By {game.game_dev} | </div>
                          <div> {game.game_cat} | </div>
                          <div className="fav"></div>
                        </div>
                      </div>
                    </div>
                    <div className="gameBody">
                      <div className="desc">
                      {selectedLang === "en" ? (game.hasOwnProperty("game_desc") &&
            game.game_desc !== null &&
            game.game_desc !== "undefined"
            ? game.game_desc
            : game.game_desc)
            : null

          }

          {selectedLang === "pt" ? (game.hasOwnProperty("game_desc_pr") &&
            game.game_desc_pr !== null &&
            game.game_desc_pr !== "undefined"
            ? game.game_desc_pr
            : game.game_desc)
            : null

          }



          {selectedLang === "ar" ? (game.hasOwnProperty("game_desc_ar") &&
            game.game_desc_ar !== null &&
            game.game_desc_ar !== "undefined"
            ? game.game_desc_ar
            : game.game_desc) : null
          }

                      </div>
                      <div className="images"></div>
                      <div className="gameStatistics">
                        <div className="likes">
                          <div>{game.likes_count}</div>
                          <div>
                            <Trans>gameDetails.game_likes</Trans>
                          </div>
                        </div>
                        <div className="hours">
                          <div>{game.play_count}</div>
                          <div>
                            <Trans>gameDetails.total_hours</Trans>
                          </div>
                        </div>
                        {game.hasOwnProperty("download_counter") ? (
                          <div className="users">
                            <div>{game.download_counter}</div>
                            <div>
                              <Trans>gameDetails.total_users</Trans>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="playBtn"
                      onClick={() => {
                        this.btnToggle();
                      }}
                    >
                      <i className="icon" />
                      <button className="btn">
                        <Trans>gameDetails.{game.btn_value}</Trans>
                      </button>
                    </div>
                  </>
                )}
            </>
          )}
        {errors ? (
          <FloatingAlert type="error">
            {errorMsg}{" "}
            <a className="reload" href=" ">
              <Trans>try_again</Trans>
            </a>
          </FloatingAlert>
        ) : (
            ""
          )}
      </GamePageDisplay>
    );
  }
}
export default withTranslation()(GamePage);
