import styled from "styled-components";
const HelpPageDisplay = styled.div`
	padding: 20px 30px;
	text-align:left;
	min-height: 100vh;
	p
	{
		font-weight: 400;
	}
	.title{
		color:#efbb0f;
		font-size: 20px;
		font-weight: 800;
	}
	.content{
		font-size: 16px;
		line-height: 1.44;
		padding-top: 15px;
	}
`;	export default HelpPageDisplay;
