import axios from "axios";
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Loader from "../../components/Loader/Loader";
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import './RankingPage.scss';
import RankingPageDisplay, { Header, Img, Rank } from './RankingPage.styled';
const apiUrl = envo.apiUrl;

class GamezroidcomRankingPage extends Component {
	constructor() {
		super();
		this.state = {
			ranks: [],
			topThree: [],
			loading: false,
			noRanking: false,
			errors: false,
			errorMsg: ""
		};
	}

	componentDidMount() {
		if (!localStorage.userId) {
			this.getAnonLeaderboard();
			return;
		}
		this.getLeaderboard(localStorage.operatorId,localStorage.userId);
	}
	async getAnonLeaderboard() {
		try {
			this.setState({ loading: true });
			 const res = await axios.get(`${apiUrl}/playit/leaderboard`);
			//const res = await axios.get(`http://testing.playit.mobi/api/v2/LeaderBoard/getTopPlayers?operatorId=&userId=guest`);
			console.log(res + "res")
			if (!res.data) return false;
			const data = res.data;
			if (data.status !== true) {
				return false;
			}
			return this.setState({
				ranks: data.data.splice(3),
				topThree: data.data.slice(0, 3),
				loading: false
			});
		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			////console.log(err);
			return false;
		}
	}

	async getLeaderboard(operatorId,userId) {

		const params = {"operatorId": operatorId,"userId": userId };
		try {
			this.setState({ loading: true });
			const res = await axios.get(`${apiUrl}/playit/leaderboard`, { params });
			if (!res.data) return false;

			const data = res.data;

			if (data.status !== true) {
				return false;
			}
			if (data.data.length >= 1) {
				return this.setState({
					noRanking: false,
					loading: false,
					ranks: data.data.splice(3),
					topThree: data.data.slice(0, 3),
					loading: false
				});
			}

		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
		//console.log(err);
			return false;
		}

	}
	render() {

		const { ranks, topThree, loading, noRanking, errors, errorMsg } = this.state;
		const rank = ranks.map((i, k) => {
		
			return (
				<Rank
					key={`rank-${k}`}
				>
					<div className="thumb">
						<Img className={i.photo === null ? 'xsAvatar' : 'rankImg'} img={encodeURI(i.photo)}></Img>
						</div>
					<div className="name">{i.name}</div>
					<div className="name" style={{width: '8vh'}}></div>
					<div className="points">{i.count} pt</div>
				</Rank>

			);
		});
		if (noRanking) {
			return (
				<RankingPageDisplay>
					<div className="noResults"><Trans>no_results</Trans><a href="/"><Trans>back_home</Trans></a></div>
				</RankingPageDisplay>)
		} else {
			return (
				<RankingPageDisplay>
					{loading ? (
						<Loader type="ranking" />
					) : (
							<>
								<div className="rankingHeader rankingHeadercom"> 
								
									<Header>
										<div className="second">
											<div className="secondcomFrame"></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'xsAvatar' : 'rankImg'}></div>
											<div className="name" style={{padding: '15px 0'}}>{topThree[1] !== undefined ? topThree[1].name : null}</div>
											<div className="smallPlatecom points">{topThree[1] !== undefined ? topThree[1].count : null} pt</div>
										</div>
										<div className="first">
											<div className="firstcomFrame"></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'smAvatar' : 'rankImg'}></div>
											<div className="name" style={{padding: '9px 0'}}>{topThree[0] !== undefined ? topThree[0].name : null}</div>
											<div className="bigPlatecom points">{topThree[0] !== undefined ? topThree[0].count : null} pt</div>
										</div>
										<div className="third">
											<div className="thirdcomFrame"></div>
											<div className={topThree[1] !== undefined && topThree[1].photo === null ? 'xsAvatar' : 'rankImg'}></div>
											<div className="name" style={{padding: '15px 0'}}>{topThree[2] !== undefined ? topThree[2].name : null}</div>
											<div className="smallPlatecom points">{topThree[2] !== undefined ? topThree[2].count : null} pt</div>
										</div>
									</Header>

								</div>
								{rank}

							</>
						)}
					{errors ? <FloatingAlert type="error">{errorMsg} <a className="reload" href=" "><Trans>try_again</Trans></a></FloatingAlert> : ""}
				</RankingPageDisplay>
			)
		}
	}

}

export default withTranslation()(GamezroidcomRankingPage)

















