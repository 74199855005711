import styled from "styled-components";
import sad from "../../assets/images/sad.svg";

const UnsubscribePageDisplay = styled.div`
min-height: 100vh;
  .formPage{
    background:transparent;
    .formsLayout {
      min-height: 80vh;
      align-items: unset;
  }

`;
export default UnsubscribePageDisplay;

export const Unsubscribe = styled.div`
    min-height: 80vh;
    display:flex;
    flex-direction: column;
  .sad{
    background: url(${sad}) center center no-repeat;
    height: 82px;
    padding: 56px 0;
  }
  p.confirmQues{
    font-size: 16px;
    font-weight: bold;
    color: var(--gamename);
  }
  a.unsubscribe,a.unsubscribe:hover{
    text-decoration: none;
    opacity: 0.46;
    font-size: 16px;
  }
  .link {
    cursor: pointer;
  }
`;
