import axios from 'axios';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import './VoucherPage.scss';
import VoucherPageDisplay, { Voucher } from './VoucherPage.styled';

const apiUrl = envo.apiUrl;

class VoucherPage extends Component {
	constructor() {
		super();
		this.state = {
			voucher: "",
			errors: ""
		};
	}
	async addVoucher(id, voucher) {
		const options = {
			id: id,
			voucher: voucher
		};
		try {
			const res = await axios.post(`${apiUrl}/playit/voucher`, options);
			if (!res.data) return false;
			const data = res.data;

			if (data.status !== "success") {
				return false;
			}
			return;
		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			return false;
		}
	}

	handleClick(e) {
		e.preventDefault();
		const userId = localStorage.userId;
		const { voucher } = this.state;
		this.addVoucher(userId, voucher);
	}

	onInputChange(key, value) {
		this.setState({
			[key]: value,
			errors: false
		});
	}
	render() {
		const { t } = this.props;

		return (
			<VoucherPageDisplay>
				<form
					onSubmit={e => {
						this.handleClick(e);
					}}
				>
					<div className="voucherTitle">
						<Trans>pay_with_voucher</Trans>
					</div>
					<Voucher>
						<div className="voucher"></div>
						<div>
							<input
								type="text"
								className="input"
								placeholder={t("voucherPay.Scratch_the_voucher")}
								onChange={e =>
									this.onInputChange("voucher", e.target.value)
								}
							/>
						</div>
						<div className="normBtn">
							<button className="btn" type="submit">
								<Trans>add</Trans>
							</button>
						</div>
					</Voucher>
				</form>
			</VoucherPageDisplay>
		)
	}
}
export default withTranslation()(VoucherPage)