import styled from "styled-components";

const FAQPageDisplay = styled.div`
min-height: 100vh;

    .title{
        font-size: 19px;
        font-weight: 400;
        margin: 25px 20px 7px;
        text-align:left;
        color : var(--sidebardetailscolor);
    }
`;	export default FAQPageDisplay;
