import styled from "styled-components";
import love from "../../assets/icons/love.svg";
const GamePageDisplay = styled.div`
  .gameHeader,
  .gameBody {
    padding: 25px;
  }
  .gameHeader {
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    .titleDetails {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 0 5px 0 25px;
      .title {
        font-size: 20px;
        line-height: 0.97;
        padding-bottom: 5px;
      }
      .details {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 1.33;
        color: #787878;
        .fav {
          height: 15px;
          width: 15px;
          background: url(${love}) center center no-repeat;
        }
      }
      .details div {
        padding-right: 3px;
      }
    }
  }
  .gameBody {
    padding: 25px 25px ;
    .desc {
      text-align: left;
      line-height: 1.5;
      opacity: 0.75;
      font-size: 14px;
      padding-bottom: 14px;
    }
    .images {
      display: flex;
      padding-bottom: 14px;
    }
    .gameStatistics {
      display: flex;
      justify-content: space-evenly;

      .likes,
      .hours,
      .users {
        display: flex;
        flex-direction: column;
        div:first-child {
          font-size: 31px;
          opacity: 0.75;
          padding-bottom: 7px;
        }
        div:last-child {
          font-size: 12px;
          color: var(--primaryColor);
        }
      }
    }
  }
`;
export default GamePageDisplay;

export const Img = styled.div`
		border: 1px solid #fff;
  		border-radius: 12px;
		width:80px;
		height:80px;
		background: url('${p => p.img}') center center no-repeat;
		background-size: cover;
`;
