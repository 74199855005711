import styled from "styled-components";
const TrueMoveSignupPageDisplay = styled.div`
  background: ${(p) => p.bgImg};
  background-size: cover;
  .formsLayoutjawal {
    min-height: 130vh;
  }
  .gamezonekeyph {
    width: 12%;
    display: inline-block;
    margin-right: 2px;
    font-size: 1rem;
    float: left;
    margin-left: 12%;
    text-align: center;
  }
  .gamezonenumph {
    width: 54%;
    display: inline;
    float: left;
    text-align: left;
  }

  .gamezoneSignup {
    background: rgb(238 90 17);
  }
  .header_gamezone {
    margin-top: 5px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: between;

    border-bottom: 2px solid #65378b;
  }
  .logo-header {
    width: 50%;
  }

  .btn-header {
    width: 50%;
    display: flex;

    justify-content: end;
  }
  .button-header {
    padding-right: 0.5rem;
    padding-left: 0.5rem;

    background: red;
   color: white !important; 
    border-radius: 0.3rem;
  }
  .links-Section {
    position: absolute;
    //border: 1px solid red;

    top: 30%;
  }
  .links-Sectionjawwalgamezorid {
    top: 9% !important;
    width: 85%;
    .play {
      background: var(--games_icon) center center no-repeat;
      height: 260px;
      background-size: var(--background-size) !important;
    }
    input {
      margin-top: 10px;
    }
    .gamezoneInput {
      background: white;
      color: black;
      padding: 1px 8px;
    }
  }

  .links-Section a {
    color: white;
  }
  .links-Section button {
    margin-bottom: 15px;
    display: block;
  }
  .links-Sectionjawwalgamezorid button {
    display: block;
    margin: 30px auto;
    min-width: 190px;
  }
  .formsLayout {
    margin: 0 20px;
    .verfiySection {
      top: 26% !important;
      width: 61%;
      position: absolute;
    }
    .gamezoneLogo {
      max-width: 163px !important;
    }
    .header {
      margin-top: 60px;
      .logo {
        background: var(--navbarLogo) center center no-repeat;
        height: var(--loginLogoHeight);
        width: var(--loginLogoWidth);
        max-width: 350px;
        background-size: var(--background-size);
        //background-size: 160px;
        margin-left: 0;
      }
    }
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    }

    .footer {
      .ncellTos {
        font-size: 13px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
      }
    }
    .zainiqFooter {
      p {
        font-size: 12px;
      }
    }

    .btn-thai {
      max-height: 30px !important ;
      background: var(--primaryColor);
      color: var(--textColor);
      padding: 10px;
      min-height: 30px;
      min-width: 230px;
      border: 1px solid transparent;
      border-radius: 15px;
      cursor: pointer;
      outline: none;
      font-size: 20px;
      font-family: SourceSansPro-Bold;
      transition: all ease 0.4s;
    }
  }
`;	export default TrueMoveSignupPageDisplay;
