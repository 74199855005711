import styled from "styled-components";
const YmenSignupPageDisplay = styled.div `
background: ${p => p.bgImg};
background-size: cover;
.formsLayoutMtn
{
  margin: 0 !important;

}

.inputradio{
  width:18px !important;
  size:15px;
}
#radiodiv{
  display:inline-block;
  margin-right: 5%;
}

.operatorSignupMtn
{
   background : none!important;
}
.links-Section{
  position: absolute;
    top: 30%;
    width: 75%;
    h3 {
      font-weight: 200;
  }
}

.links-Section a {
  color:white
}
.links-Section button{
  margin-bottom: 15px;
  display:block;
}
.links-Sectionmtn
{
  top:50%
}
.links-Sectionmtnverify
{
  top:40%
}

.phone
{
  border: 1px solid #ffffff73;
  padding: 12px;
  width: 80%;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 6px;
}
.btn_mtn
{
  background-color: #FFCB05;
  width: 75%;
}
.formsLayoutniger
{
  //min-height: 120vh!important;
}
.selectMtn
{
  width: 90%;
    background-color: white;
    color: black;
}

.selectMtn2
{
  margin-bottom: 10px;

}




.formsLayout {
  margin: 0 20px;
  .header
  {
    margin-top: 5px;
  width: 100%;
  border-bottom: 2px solid rgb(242, 200, 48);
  }
  .verfiySection
  {
    top: 26%!important;
  width: 61%;
  position: absolute;
  }
  .gamezoneLogo
  {
    max-width: 163px!important;
  }
  .inputs {
    selectUmnia {
      width: 65%;
      height: 43px;
    }
    input {
      width: 50%;
    }
    .inputumnia
    {
      width: 100%;

    }
  .footer{
    .ncellTos {
      font-size: 13px;
      margin-top: 20px;
      display:flex;
      flex-direction:column;
    }
  }

 
}



`;
export default YmenSignupPageDisplay;