import styled from "styled-components";
const ConfirmationCodeComponentDisplay = styled.div`
  background: ${p => p.bgImg};
  background-size: cover;
  .inputs {
    direction: ltr;
  }
  color: var(--textColor);
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  form {
    margin: 0 !important;
  }
  p {
    font-size:15px;
  }
  .resend p {
    margin: 5px;
  }
  .stitle {
    display: flex;
    p {
      font-size: 20px;
      text-align:left;
      padding: 5px 30px;
    }
  }
  .verificationTxt{
    p:last-child{
      color:var(--primaryColor);

    }
  }
`;
export default ConfirmationCodeComponentDisplay;
