import styled from "styled-components";
const VerifyPageDisplay = styled.div`
  background: ${p => p.bgImg};
  background-size: cover;
  .inputs {
    direction: ltr;
  }
  color: var(--textColor);
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  form {
    margin: 0 !important;
  }
  .resend p {
    font-size: 14px;
    margin: 5px;
  }
`;
const InputDisplay = styled.input`
  background: transparent;
  border: 1px solid #707070;
  border-radius: 13px;
  margin: 0.32em;
  width: 1.2em;
  height: 2em;
  color: var(--textColor);
  padding: 0.24em;
  outline: none;
  font-size: 1.2em;
  text-align: center;
`;
export { VerifyPageDisplay, InputDisplay };

