import styled from "styled-components";
const LanguagePageDisplay = styled.div`
height: 60vh;
min-height: 100vh;
display: flex;
flex-direction: column;
.title
{
	color: var(--sidebardetailscolor)
}
img{
	width:38px;
}
.selected {
	background:var(--primaryColor);
	color: var(--sidebardetailscolor)

}
.notSelected {
	background:transparent;
	color: var(--sidebardetailscolor)

}
`; export default LanguagePageDisplay;

export const Title = styled.div`
	font-size: 18px;
	line-height: 1.22;
	padding: 20px 25px;
	text-align:left;
`;

export const LangGroup = styled.div`
	display:flex;
	padding: 11px 25px;
	p{
		font-size: 14px;
		text-align: left;
		padding: 0px 10px;
	}
	.wrapper{
		display: flex;
		align-items: center;
		.circle {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			opacity: 0.2;
			background-color: #ffffff;
		}
		.lang{
			line-height: 22px;
			font-size: 14px;
			text-align: center;
			position: absolute;
			left: 35px;
			text-transform:capitalize;
		}
	}
`;