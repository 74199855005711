


export const list9 = [
    {
      url: "/profile",
      text: "side_nav.profile",
      logged: true
    },
    {
      url: "/my-games",
      text: "my_games",
      logged: true
    },
    {
      url: "/leaderboard",
      text: "side_nav.ranking"
    },

    // {
    //   url: "/leaderboard",
    //   text: "side_nav.leader"
    // },
    // {
    //   url: "/packages",
    //   text: "side_nav.packages",
    //   logged: true
    // },
    // {
    //   url: "/wheel",
    //  text: "side_nav.wheel",
    //  logged: true
    // },
   
    // {
    //   url: "/",
    //   text: "side_nav.my_coins",
    //   logged: true
    // },
    {
      url: "/faqs",
      text: "side_nav.faq"
    },
    // {
    //   url: "/tos",
    //   text: "terms_and_conditions"
    // },
    // {
    //   url: "/rules",
    //   text: "side_nav.rules"
    // },
    
    // {
    //   url: "/videoPlay",
    //   text: "side_nav.videos_tutorials"
    // },
   
    {
      url: "/change-language",
      text: "side_nav.language"
    },
  
  
    {
      url: "/unsubscribe",
      text: "unsubscribe",
      logged: true
    },
    {
      url: "/logout",
      text: "side_nav.logout",
      logged: true
    },
  ];
  