import styled from "styled-components";
const RulesDisplay = styled.div`
	padding: 20px 30px;
	.title{
		color:var(--primaryColor);
		font-size: 20px;
		font-weight: 400;
	}
	.content{
		font-size: 16px;
		line-height: 1.44;
		padding-top: 15px;
	}
`;	export default RulesDisplay;
