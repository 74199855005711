import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bgSliders from "../../../helpers/bgSliders";
import { Trans, withTranslation } from "react-i18next";
import "./SignupPage.scss";
import { setAgencyData } from "../../../helpers/agencyTracker";
import OoredoopsSignupPageDisplay from "../OoredoopsSignupPage/SignupPage.styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



const randomBg = bgSliders.getRandomBg();
function asiacellSignupPage() {
  const signup = async e => {
    e.preventDefault();

    const url = window.location.search;
      
    var asiacelurl = 'http://galaxylp.mobi-mind.net/?Id=1498,GG,964,2695,885,http://asiacell.gamezroid.com/catch-asiacell,0,41805,12242';
      
    if (url !== "") {
      setAgencyData(url);
      if (localStorage.getItem('tracker')) {
        //const urlSearch = "?ID=806,00eef37b,6,8049,3,PlayIT,http%3A%2F%2Fstckuwait.playit.mobi%2Fcatch-stckuwait"
        //setAgencyData(urlSearch);
        var trackerCompanyt = localStorage.getItem('trackerCompany');
        var trackert = localStorage.getItem('tracker');
        if(trackert != ""){
          asiacelurl = asiacelurl + "&clickid=" + trackert + "&clickcompany=" + trackerCompanyt;
        }

      }
    }
    window.location.href = asiacelurl;
  };


  return (
    <OoredoopsSignupPageDisplay className="" bgImg={randomBg}>


      <div className='operatorSignup operatorSignupGamez operatorSignupasiacell'>
        <div className="formsLayout formsLayoutasiacell">

          <div className='headerasiacell'>
            <Link to="/">
              <div className='logogamezroid logo' />
            </Link>
          </div>
          <>
            <div className="links-Section links-SectionOredoo" style={{width: "84%"}}>

              <h3 style={{ paddingBottom: '1px' , color:'#000'}} className="gamezoridcontent" id="gamezoridcon">
                <Trans>Your content is ready!</Trans>
              </h3>


              {/* <div className="play"></div> */}
              <Carousel>
                <div>
                    <img src="./assets/images/slider/02.png" />
                </div>
                <div>
                    <img src="./assets/images/slider/03.png" />
                </div>
                <div>
                    <img src="./assets/images/slider/04.png" />
                </div>
            </Carousel>
              <form>
                <div>
                  
                  
                  <button
                id="btnsub"
                className="btn gamezroid btn_gamezroid"
                style={{width:"60%" , marginTop:"50px" , marginBottom:"30px"}}
                onClick={(e) => {
                  signup(e);
                }}
              >
                <Trans>Signup</Trans>
              </button>

                </div>
              </form>
              <br></br>

              <div className="row text-center" style={{backgroundColor:"red" , paddingTop:"12px"}}>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                    <p><Trans>evaluation</Trans></p>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                    <p><Trans>game</Trans></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-4 px-5">
                  <div className="games-info">
                    <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                    <p><Trans>user</Trans></p>
                  </div>
                </div>
              </div>
            </div>
          </>

        </div>
      </div>

      {/* <div className="operatorSignup">
        <div className="formsLayout formsLayoutasiacell">
          <div className="header">
            <Link to="/">
              <div className="logo" />
            </Link>
          </div>

          <div className="center">
          <button
                id="btnsub"
                className="btn"
                onClick={(e) => {
                  signup(e);
                }}
              >
                <Trans>Signup</Trans>
              </button>
            </div>
           
        </div>
      </div> */}

    </OoredoopsSignupPageDisplay>
  );
}
export default withTranslation()(asiacellSignupPage)
