import styled from "styled-components";
const HomePageDisplay = styled.div`
.container {
  max-width:1200px;
  margin:auto
}
  .input {
    margin: 50px 0px;
  }
  .mainTabs {
    display: var(--displaytabs);
    justify-content: center;
    align-items: center;
    color : var(--mainTabsColor);
    margin-top : var(--paddingTaps);
    background: var(--homeTabs);
    > div {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        margin: 0 5px;
      }
    }
    > div.isActive {
      border-bottom: 3px solid var(--borderActiveTab);
      background : var(--activebgTaps);
      color : var(--colorActive)

    }
    
  }
  .subTabs {
    display: flex;
    margin: 20px 15px 0;
    height: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    height: var(--heightSubTabs)
    justify-content: flex-start;
    align-content: center;
    overflow: hidden;
    overflow-x: scroll;
    padding-bottom:2px;
  }
 
  .onlinesubTabs
  {
    display: flex;
    margin: 20px 16px 0;
    // margin: 20px;
    // margin-bottom: 0;
    
    height: calc(50px + (80 - 10) * ((100vw - 300px) / (1600 - 300)));
    height: var(--heightSubTabs)
    justify-content: flex-start;
    align-content: center;
    overflow: hidden;
     overflow-x: scroll;
     padding-bottom:2px;
    

  }
  .sSubTab {
    height: calc(25px + (60 - 25) * ((100vw - 300px) / (1600 - 300)));
    padding: 4px 10px;
    border: 1px solid var(--subBorderColor);
    color: var(--sSubtabColor);
    border-radius: var(--borderRadiusSize);
    margin: 0 5px;
  //  color: var(--text-color);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(12px + (20 - 12) * ((100vw - 300px) / (1600 - 300)));
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    // text-transform: capitalize;
    background-color: var(--backgroundcat);
  }
 
  .sSubTab.isActive {
    color: var(--activeColor);
    border-color: var(--borderActiveColor);
    background-color: var(--activeBackground)

  }
  .categoryTitle {
    max-width: 1200px;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--titleColor);
    padding: 10px 20px 5px;
    img {
      width: 20px;
      margin: 0 10px;
    }
  }
`;
export default HomePageDisplay;

export const Title = styled.div`
  font-size: 18px;
  line-height: 1.22;
  padding: 20px 25px;
  text-align: left;
`;
