import styled from "styled-components";
const FavouritesPageDisplay = styled.div`
min-height: 100vh;
	.favTitle{
		font-size: 20px;
		line-height: 1.22;
		text-align: left;
		padding:20px 25px 0;
	}
`;	export default FavouritesPageDisplay;
