import styled from "styled-components";

const ExclusiveGameDisplay = styled.div`
  padding:0 14px 12px 14px;
  //padding : 20px
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .exculsiveBanner
{
    /*background: var(--exclusiveGame) center center no-repeat;*/
    width: 100%;
    background-color: var(--primaryColor);
    position: relative;
    z-index: 1;
    top: 60px;
    margin-bottom: 75px;

    
    
}
.clusivelogo
{
    background: var(--exclusiveGame) center center no-repeat;
    width: 100%;
    height: 63px;
    position: absolute;
    z-index: 2;
    top: -59px;
    height: 7em;

}
.logoPart2
{
    background: var(--exclusiveGamePart2) center center no-repeat;
    width: 100%;
    height: 63px;

}

  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--titleColor);
    img {
      width: 20px;
      margin: 0 10px;
    }
  }
  .clusgame
  {
    padding-top: 40px;  }
.exculsiveText
  {
    text-transform: uppercase;
    padding: 10px!important;
  }
  .more {
    font-size: 16px;
    text-decoration: underline;
    color: var(--Allcolor);
  }
  .games {
    display: flex;
    overflow: auto;
    color:black!important;
    border-bottom : var(--gameBorderB);
    
    
  }
`;
export default ExclusiveGameDisplay;
