import React, { Component } from 'react';
import './CategoryPage.scss';
import CategoryPageDisplay from './CategoryPage.styled';

export default class CategoryPage extends Component {
	render() {
		return (
			<CategoryPageDisplay>
				CategoryPage_Component
			</CategoryPageDisplay>
		)
	}
}
