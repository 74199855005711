import styled from "styled-components";
const WheelPageDisplay = styled.div`
min-height: 100vh;
background: var(--insideBg);
//background-size: contain;
background-repeat: no-repeat;
position: absolute;
top: 0;
width: 100%;
background-size: cover;
@media(max-width: 1024px){
  background-position: top;
  //max-height: 120vh;

  //background-size: cover;

}
.wheel
{
	display: flex;
    flex-flow: column;
    align-items: center;
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-around;
	margin-top: 128px;
	.wheelnumber
	{
		color: #ffe32c;
    font-size: 23px;
    font-weight: bold;
	}

}
.wheel p 
{
	margin : 5px
}

	.operatorSignup
	{
	  min-height: 117vh !important;
	}
	.links-Section-women{
	  position: absolute;
		top: 6%;
		width: 62%;
  
	   
		.iconinputphone
		{
		  background-color: white;
		  border-radius: 7px;
		}
	}

   
	.links-Section-women a {
	  color:white
	}
	.links-Section-womenGamezorid button{
	  margin-bottom:  42px;
	  display: block;
	}
	.links-Section-women h3
	{
	  font-weight: 200;
	}
	
	.formsLayout
	{
	  min-height: 107vh;
	  .headetwomen
	  {
	 display:flex;
	 flex-flow: column;
	  align-items:flex-end;
	  justify-content: space-around;
	  min-height:200px;
	  width: 100%;
	  p
	  {
		font-size: 13px;
		font-weight: 500;
		color: #ffffffeb;
		width:100%
	  }
  
	  }
  
	  .footer p
	  {
		line-height: 18px;
	  }
	}
	.formsLayout {
	  margin: 0 20px;
	  .header
	  {
		margin-top: 5px;
		width: 100%;
		border-bottom: 2px solid #f2c830;
	   .logogamezroid
	   {
		height: 50px;
	   }
	  
	  }
	  .input-women {
		textarea {
		//	width: 80%;
			border: none;
			background-color: transparent;
			color: #12151D;
			outline: red;
			height: 80%;
		}
	  .footer{
		margin-bottom: 10px!important;
		.ncellTos {
		  font-size: 13px;
		  margin-top: 20px;
		  display:flex;
		  flex-direction:column;
		}
	
	  }
	}
`; export default WheelPageDisplay;
