import queryString from "query-string";
import axios from "axios";
import Operators from "../operators/operators.json";
const setAgencyData = search => {
    if (search) {
        const data = queryString.parse(search);
        if (data.trafficsource) {
            localStorage.setItem('tracker', data.parameter);
            localStorage.setItem('trackerCompany', data.trafficsource);
           //console.log("finish tracker")
           // logtracker(data.trafficsource,data.parameter);
        } else if(data.ID)
        {
            localStorage.setItem('ID', data.ID);
            //console.log("finish tracker" , data.ID)
        }
        else if (data.eoliclick) {
            localStorage.setItem('trackerCompany', 'OlimobCPA');
            localStorage.setItem('tracker', data.eoliclick);
        } else if (data.erid) {
            localStorage.setItem("trackerCompany", "digiTantra");
            localStorage.setItem("tracker", data.erid);
        } else if (data.kcclick) {
            localStorage.setItem("trackerCompany", "krayons");
            localStorage.setItem("lead", data.lead);
            localStorage.setItem("payout", data.payout);
            localStorage.setItem("tracker", data.kcclick);
        } else if (data.yerid) {
            localStorage.setItem("trackerCompany", "youmi");
            localStorage.setItem("tracker", data.yerid);
        } else if (data.etracker) {
            localStorage.setItem('trackerCompany', 'trafficCompany');
            localStorage.setItem('tracker', data.etracker);
        } else if (data.earmclick_id) {
            localStorage.setItem('trackerCompany', 'ArmorAds');
            localStorage.setItem('tracker', data.earmclick_id);
        }
        else if (data.visitor_id) {
            localStorage.setItem('trackerCompany', 'Propellar');
            localStorage.setItem('tracker', data.visitor_id);
        }
        else if (data.key) {
            localStorage.setItem('trackerCompany', 'Rich');
            localStorage.setItem('tracker', data.key);
        }
        else if (data.click_id) {
            localStorage.setItem('trackerCompany', 'Mobidea');
            localStorage.setItem('tracker', data.click_id);
        }
        else if (data.merid) {
            localStorage.setItem('trackerCompany', 'MOBIPIUM');
            localStorage.setItem('tracker', data.merid);
        }
        else if (data.adacts_id) {
            localStorage.setItem('trackerCompany', 'Adacts');
            localStorage.setItem('tracker', data.adacts_id);
        }
        else if (data.inhouse1_click_id) {
            localStorage.setItem('trackerCompany', 'InhouseSource1');
            localStorage.setItem('tracker', data.inhouse1_click_id);
            localStorage.setItem("payout", data.payout);

        }
        else if (data.InhouseSource2) {
            localStorage.setItem('trackerCompany', 'InhouseSource1');
            localStorage.setItem('tracker', data.InhouseSource2);
        }
        
    }

}
async function logtracker(company,tracker,urredir="")
{
    const apiUrl="http://api.playit.mobi/api/v2";
  
      const options = { 
        operatorId:localStorage.getItem("operator_name"),
        tracker : tracker,
        trackerCompany : company
       };
      if(urredir == ""){
        if(localStorage.getItem("tracker") != ""){
            const res = axios.post(`${apiUrl}/playit/agency/log`, options);
        }
      }else{
        if(localStorage.getItem("tracker") != ""){
        const url = `${apiUrl}/playit/agency/log`;
        const res =  await axios({
            method: 'post',
            url: url,
            data: options    
            });
           //console.log(res);
            if(res){
                window.location.href = urredir;
            }
        }
           
                window.location.href = urredir;
           
      }
       

      
    

  

}
const callTrackerCompany = (trackerCompany, tracker) => {
   //console.log(trackerCompany);
    switch (trackerCompany) {
        case 'OlimobCPA':
            verifyOlimobTracker(tracker);
            break;
        case 'digiTantra':
            verifyDigitantraTracker(tracker);
            break;
        case 'krayons':
            verifyKrayonsTracker(tracker, localStorage.getItem('lead'), localStorage.getItem('payout'));
            break;
        case 'youmi':
            verifyYoumiTracker(tracker, 0.30);
            break;
        case 'trafficCompany':
            verifyTrafficCompanyTracker(tracker, 0.25);
            break;
        case 'ArmorAds':
            verifyArmorAdsCompanyTracker(tracker);
            break;

        case 'Propellar':
            verifyPropellarCompanyTracker(tracker, 0.15);
            break;

        case 'Rich':
            verifyRichCompanyTracker(tracker, 0.15);
            break;

        case 'Mobidea':
            verifyMobideaCompanyTracker(tracker, 0.15);
            break;

        case 'MOBIPIUM':
            verifyMOBIPIUMCompanyTracker(tracker);
        break;
    
        case 'InhouseSource1':
            verifyInhouseSource1CompanyTracker(tracker, localStorage.getItem('payout'));
        break;
        
        case 'InhouseSource2':
            verifyInhouseSource2CompanyTracker(tracker);
        break;
        
        case 'Adacts':
            verifyAdactsTracker(tracker);
        break;

        case 'Marketshareagency':
            verifyMarketshareagencyTracker(tracker);
        break;
        
          default:
            break;
    }
};

async function verifyOlimobTracker(tracker) {
   //console.log("verifyOlimobTracker");
    axios.get('http://olimob.net/pb/?adv=GrandTechnology&oliclick='+tracker);
}

async function verifyDigitantraTracker(tracker) {
   //console.log("verifyDigitantraTracker");
    axios.get('http://track.mobileadsco.com/postback?cid='+tracker);
}

async function verifyKrayonsTracker(tracker, lead, payout) {
   //console.log("verifyKrayonsTracker");
    axios.get('http://track.krayons.mobi/click.php?cnv_id='+tracker+'&lead='+lead+'&payout='+payout);
}

async function verifyYoumiTracker(tracker, payout) {
   //console.log("verifyYoumiTracker");
    axios.get('http://track.miadx.net/aff_lsr?transaction_id='+tracker+'&payout='+payout);
}

async function verifyTrafficCompanyTracker(tracker, payout) {
   //console.log("verifyTrafficCompanyTracker");
    axios.get('https://postback.level23.nl/?currency=USD&handler=10423&hash=358c505d85a024cf4d6133cb2eca8c63&payout=' + payout + '&tracker=' + tracker);
}

async function verifyArmorAdsCompanyTracker(tracker) {
   //console.log("verifyArmorAdsCompanyTracker");
    axios.get('http://secure-conversion.com/conversion_get/pixel.jpg?kp='+tracker);
}
async function verifyMOBIPIUMCompanyTracker(tracker) {
   //console.log("verifyMOBIPIUMCompanyTracker");
    axios.get('https://smobipiumlink.com/conversion/index.php?jp='+tracker);
}

async function verifyPropellarCompanyTracker(tracker,payout) {
   //console.log("verifyMOBIPIUMCompanyTracker");
    axios.get(`http://ad.propellerads.com/conversion.php?aid=782276&pid=&tid=68452&visitor_id=${tracker}&payout=${payout}`);
}

async function verifyRichCompanyTracker(tracker,payout) {
   //console.log("verifyMOBIPIUMCompanyTracker");
    axios.get(`http://rtb.adx1.com/log?action=conversion&key=${tracker}&price=${payout}`);
}

async function verifyMobideaCompanyTracker(tracker,payout) {
   //console.log("verifyMOBIPIUMCompanyTracker");
    axios.get(`https://s2s.mobideapush.com/conversion?click_id=${tracker}&amount=${payout}`);
}
async function verifyAdactsTracker(tracker) {
   //console.log("verifyAdactsTracker");
    axios.get(`https://tracking.adacts.com/postback?clickid=${tracker}`);
}
async function verifyMarketshareagencyTracker(tracker) {
   //console.log("Marketshareagency");
    axios.get(`https://plannedy-cornance.com/postback?cid=${tracker}&event=1st_dep`);
}

async function verifyInhouseSource1CompanyTracker(tracker,payout) {
   //console.log("verifyInhouseSource1CompanyTracker");
    axios.get(`https://s2s.mobideapush.com/conversion?click_id=${tracker}&amount=${payout}`);
}
async function verifyInhouseSource2CompanyTracker(tracker) {
   //console.log("verifyInhouseSource2CompanyTracker");
    axios.get(`http://rtb.adx1.com/log?action=conversion&key=${tracker}&price=REVENUE_IN_USD`);
}

async function saveTracker(data) {
  
    const url = "http://www.api.playit.mobi/api/v2/agencyTracker/store";
  const res = await axios({
    method: 'post',
    url: url,
    data: data    
    });

   //console.log(res);

    if(res.data.postback){

        callTrackerCompany(data.company,data.value);
    }
    
    //axios.post('http://www.api.playit.mobi/api/v2/agencyTracker/store, data');
 }

export {
    setAgencyData,
    callTrackerCompany,
    saveTracker,
    logtracker
}