import styled from "styled-components";

const GameSliderDisplay = styled.div`
  padding:0 14px 12px 14px;
  //padding : 20px
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: capitalize;
    color: var(--titleColor);
    img {
      width: 20px;
      margin: 0 10px;
    }
  }
  .more {
    font-size: 16px;
    text-decoration: underline;
    color: var(--Allcolor);
    cursor: pointer;

  }
  .games {
    display: flex;
    overflow: auto;
    color:black!important;
    border-bottom : var(--gameBorderB);
    
    
  }
`;
export default GameSliderDisplay;
