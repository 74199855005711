import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;

async function sendVerficationCode(userId) {
  const options = { userId };
  try {
    const res = await axios.post(`${apiUrl}/sendCodeVerification`, options);
    if (!res.data) return false;
    const data = res.data;
    return data;
  } catch (err) {
   //console.log(err);
    return false;
  }
}
export default sendVerficationCode;
