import axios from "axios";
import { clearLoginData } from "../../../helpers/helpers";

export function UnsubscribeRedirect(hostName,props) {
  const userId = localStorage.userId;
  const phone_nu = localStorage.phoneNum;
  
  if(hostName === "ethio.playit.mobi") {
    //ethioUnsubscribe(userId,props);
    window.location.href = 'sms:6040;?&body=Stop';
    
  }
  if(window.location.hostname.includes("asiacell")) {
    //ethioUnsubscribe(userId,props);
    window.location.href = 'sms:2231;?&body=0';
    
  }
  if(hostName === "omantel.playit.mobi") {
    omantelUnsubscribe(userId,props);
  }
  if(hostName === "umniah.playit.mobi") {
    umniahUnsubscribe(userId,props);
  }
  if(hostName === "jawwal.playit.mobi") {
    jawwalUnsubscribe(userId,props);
  }

  if(hostName === "mtnng.playit.mobi") {
    nigeriaUnsubscribe(userId,props);
  }

  if(hostName === "jawwal.gamezroid.com") {
    jawwalGamezroidUnsubscribe(userId,props);
  }

  if(hostName === "ooredoops.playit.mobi") {
    ooredoopsUnsubscribe(userId,props);
  }
  if(hostName === "korek.gamezones.mobi") {
    KorekUnsubscribe(userId,props);
  }

  if(hostName === "zainiq.gamezroid.com") {
    zainiqUnsubscribe(userId,props);
  }
  if(hostName === "ao.playit.mobi") {
    aoUnsubscribe(phone_nu,props); //hostName === "ao.playit.mobi"
  }
  if(hostName === "zainbh.playit.mobi" || hostName === "stcbh.playit.mobi") {
    zainBhUnsubscribe(userId,props);
  }

   if(hostName === "batelco.playit.mobi") {
    batelcoUnsubscribe(userId,props);
  }

  if(hostName === "ymobile.playit.mobi") {
    ymobileUnsubscribe(userId,props);
  }

  if(hostName === "sabafon.playit.mobi") {
    sabafonUnsubscribe(userId,props);
  }


  

  // ymobileUnsubscribe(userId,props);
}

async function ethioUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    const headers = {
      EthAuthorization: "Basic RXRoaW9TREFwaTpLdGFmV0x3OFdFRDhWR3d3eXk="
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });

   const url = "http://www.api.playit.mobi/api/v2/Ethio/unSubscribe?objectId="+id;
   const config = {
      method: 'get',
      url: url,
      headers: { 'EthAuthorization': 'Basic RXRoaW9TREFwaTpLdGFmV0x3OFdFRDhWR3d3eXk=' }
    }
    const res = await axios(config)

    if (res.data && res.data !== "") {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function umniahUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });

   const url = "http://www.api.playit.mobi/api/v2/umniah/unSubscribe?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function zainBhUnsubscribe(id,props) {
  try {
   const url = `http://www.api.playit.mobi/api/v2/bahrain/deleteSubDetails?uuid=${id}`;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function batelcoUnsubscribe(id,props) {
 //console.log("task")
  try {
   const url = `http://www.api.playit.mobi/api/v2/batelcoMW/mobile/advertising/unsubscribe?userId=${id}`;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function ymobileUnsubscribe(id,props) {
 //console.log("task")
  try {
   const url = `http://www.api.playit.mobi/api/v2/yemen-mobile/unsubscribe?userId=${id}`;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function sabafonUnsubscribe(id,props) {
 //console.log("task")
  try {
   const url = `http://www.api.playit.mobi/api/v2/sabafon/unsubscribe?userId=${id}`;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}
async function KorekUnsubscribe(id,props) {
  
   try {
    const url = `http://api.playit.mobi/api/v2/korek-trendy/unsubscribe?userId=${id}`;
    const config = {
       method: 'get',
       url: url
     }
     const res = await axios(config)
 
     if (res.data && res.data !== "" && res.data.status) {
       clearLoginData();
       return props.history.push("/login");
     }
     return false;
   } catch (err) {
  
     return false;
   }
 }
 

async function omantelUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });

   const url = "http://www.api.playit.mobi/api/v2/omantel/optoutRequest?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function zainiqUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });

   const url = "http://www.api.playit.mobi/api/v2/omantel/optoutRequest?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function jawwalUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });
   const url = "http://api.playit.mobi/api/v2/zainiq/gamezroid/unsubscribeUser?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status == "Success") {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function nigeriaUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });

  // http://api.playit.mobi//api/v2/nigeria/unsubscribe

   const url = "http://www.api.playit.mobi/api/v2/nigeria/unsubscribe?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config);
   //console.log("uns" , res)

    if (res.data && res.data !== "" && res.data.status == true) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function jawwalGamezroidUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });
  // http://api.playit.mobi/api/v2/jawwal/gamezroid/unSubscribe?objectId=I2jAdJETno

   const url = "http://www.api.playit.mobi/api/v2/jawwal/gamezroid/unSubscribe?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status == "Success") {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function aoUnsubscribe(id,props) {
  try {

   const url = "http://www.api.playit.mobi/api/v2/Unitel/unSubscribe?phone="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)
   //console.log(res.data);
    if (res.data && res.data !== "" && res.data.status === true) {
      clearLoginData();
      //return props.history.push("/");
      window.location.href = 'http://ao.playit.mobi/';
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}


async function ooredoopsUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
    // const res = await axios.get(url, {params});
   // const res = await axios.post(url, params, { headers });
   //http://api.playit.mobi/api/v2/imi/ooredoo/unSubscribe?objectId=dd
   const url = "http://api.playit.mobi/api/v2/imi/ooredoo/unSubscribe?objectId="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config)

    if (res.data && res.data !== "" && res.data.status == true) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

async function dzoUnsubscribe(id,props) {
  try {
    const params = {
      objectId: id
    };
 
   const url = "http://www.api.playit.mobi/api/v1/mondia/deleteSubDetails?uuid="+id;
   const config = {
      method: 'get',
      url: url
    }
    const res = await axios(config);
   //console.log(res)

    if (res.data && res.data !== "" && res.data.status) {
      clearLoginData();
      return props.history.push("/login");
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}




