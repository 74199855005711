import axios from "axios";
import { setLoginData } from "../../../helpers/helpers";

const headers = { 'bhmsisdn':''} ;
export async function bhrainLogin(msisdn) {
  headers.bhmsisdn = msisdn
  try {
    const url = `http://www.api.playit.mobi/api/v1/getMSIDN${headers}`;
    const res = await axios.get(url);
    if (res.data && res.data !== false) {
      const data = res.data;
      return setLoginData(
        data.user_id,
        data.token,
        data.user_name,
        data.photo,
        data.operator_id,
        data.user_email
      );
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}


export async function getPhoneByHeader(msisdn_header) {
   headers.bhmsisdn= msisdn_header
  try {
    const res = await axios.get("http://api.playit.mobi/api/v1/getMSIDN",headers);
    if (res.data && res.data !== "") {
      localStorage.setItem('user_id', res['user_id']);
        localStorage.setItem('token', res['token']);
        localStorage.setItem('user', JSON.stringify(res));
        localStorage.setItem('msidn', res['msidn']);
         //set the token to all next api request
        this.myrouter.navigate(['/playnow', res['user_id']]);
      if (res.data.MSIDN && res.data.code) {
        return res.data,res.data.headers;
      }
      return false;
    }
    return false;
  } catch (err) {
   //console.log(err);
    return false;
  }
}

export async function getRedirectUrl(phone, plan) {
  try {
    const url = "http://api.playit.mobi/api/v2/ncell/getNCellConsentPage";
    const options = {
      phone,
      plan
    };
    const res = await axios.post(url, options);
    if (res.data) {
      const data = res.data;
      if (!data.redirectionURL) {
        return false;
      }
      return data.redirectionURL;
    }
  } catch (err) {
   //console.log(err);
  }
}
export async function subscribe(phone, cpTransactionID) {
  try {
    const url = "http://api.playit.mobi/api/v2/ncell/Subscribe";
    const options = {
      phone,
      cpTransactionID
    };
    const res = await axios.post(url, options);
    if (res.data) {
      const data = res.data;
      if (!data.status) {
        return false;
      }
      return true;
    }
  } catch (err) {
   //console.log(err);
  }
}
