import React, { Component } from "react";
import "./FloatingAlert.scss";
import FloatingAlertDisplay from "./FloatingAlert.styled";

export default class FloatingAlert extends Component {
  render() {
    const { type } = this.props;
    return (
      <FloatingAlertDisplay type={type}>
        {this.props.children}
      </FloatingAlertDisplay>
    );
  }
}
