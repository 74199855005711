import styled from "styled-components";
const VideosPageDisplay = styled.div`

	.operatorSignup
	{
	  min-height: 117vh !important;
	}
	.links-Section-valntine{
	  position: absolute;
		top: 6%;
		width: 75%;
  
	   
		.iconinputphone
		{
		  background-color: white;
		  border-radius: 7px;
		}
	}

   
	.links-Section-valntine a {
	  color:white
	}
	.links-Section-valntineGamezorid button{
	  margin-bottom:  42px;
	  display: block;
	}
	.links-Section-valntine h3
	{
	  font-weight: 200;
	}
	
	.formsLayout
	{
	  min-height: 107vh;
	  .headetvalntine
	  {
	 display:flex;
	 flex-flow: column;
	  align-items:flex-end;
	  justify-content: space-around;
	  min-height:200px;
	  width: 100%;
	  p
	  {
		font-size: 13px;
		font-weight: 500;
		color: #ffffffeb;
	  }
  
	  }
  
	  .footer p
	  {
		line-height: 18px;
	  }
	}
	.formsLayout {
	  margin: 0 20px;
	  .header
	  {
		margin-top: 5px;
		width: 100%;
		border-bottom: 2px solid #f2c830;
	   .logogamezroid
	   {
		height: 50px;
	   }
	  
	  }
	  .input-valntine {
		textarea {
			//width: 70%;
			border: none;
			background-color: transparent;
			color: #12151D;
			outline: red

		}
	  .footer{
		margin-bottom: 10px!important;
		.ncellTos {
		  font-size: 13px;
		  margin-top: 20px;
		  display:flex;
		  flex-direction:column;
		}
	
	  }
	}
`; export default VideosPageDisplay;
