import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import OoredoopsSignupPageDisplay from "./SignupPage.styled";
import { setLoginData } from "../../../helpers/helpers";
import { Trans, withTranslation } from "react-i18next";
import { getPhoneByHeader, getRedirectUrl, subscribe } from "./services";
import { setAgencyData , saveTracker} from "../../../helpers/agencyTracker";



const randomBg = bgSliders.getRandomBg();
function OoredoopsSignupPage({ t }) {
  const [activeData, setActiveData] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  const [showverif, setShowverif] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [errorMesg, setErrorMesg] = useState(false);
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [errorresverify, seterrorresverify] = useState("");

  const [loading, setloading] = useState(false);
  const [errorMesgSubscribe, setErrorMesgSubscribe] = useState("");
  const [signupPlan, setSignupPlan] = useState("d");
  const [otpid, setotpid] = useState("");

  const plans = [
    {
      plan: "d",
      name: "Daily"
    },
    {
      plan: "w",
      name: "Weekly"
    },
    {
      plan: "m",
      name: "Monthly"
    }
  ];

  const [alert, setAlert] = useState(false);

  useEffect(() => {
  
    // const params = queryString.parse(window.location.search);
    const urlsearch = window.location.search;
    if (urlsearch !== "") {
     //console.log("tracker");
      setAgencyData(urlsearch);
    }
  } , []);

  // useEffect(() => {
  //   async function fetchit() {
  //     var linkheader = "http://mtnng.playit.mobi/checkheader.php";
  //     const num = await getPhoneByHeader(linkheader);
  //     var phone_h = num;
  //     setActiveData(phone_h);
  //     if (phone_h != false) {
  //       setCheckPhone(true)
  //     }
  //   }
  //   fetchit();
  // }, []);
  const Subscribe = async e => {
    e.preventDefault();
    if (activeData) {
      var phon_n = activeData.toString();
      var url = ""
      url = `http://api.playit.mobi/api/v2/imi/ooredoo/sendpin?msisdn=${phon_n}&plan=${signupPlan}`
      axios.get(url)
        .then(res => {
         //console.log(res);
          if (res.data.status == true) {
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
            }
            if (res.data.otpid) {
              setotpid(res.data.otpid)
            }
            setShowverif(true)
          }
          else {
            setErrorMesgSubscribe(res.data.msg);
            setErrorMesg(true)
            //window.location.href ="/login"
          }

        })
        .catch(function (error) {
          setErrorMesg(true)
          setShowverif(false)
        })
        .finally(function () {
         //console.log('complete');
        });


    }
  };


  const verify = async e => {
    e.preventDefault();
    if (verifyCode) {
      var phon_n = activeData;
      setloading(true);
      axios.get(`http://api.playit.mobi/api/v2/imi/ooredoo/verify?msisdn=${phon_n}&plan=${signupPlan}&pin=${verifyCode}&otpid=${otpid}`)
        .then(res => {
          if (res.data.status == true) {
            tracker(phon_n)
            setVerfiErrorMesg(false)
            setloading(false)
            if (res.data.user_id) {
              setLoginData(res.data.user_id, res.data.token, res.data.user_name, res.data.photo, res.data.operator_id, res.data.user_email, res.data.msisdn);
              // window.location.href = "/";
            }
            window.location.href = "/";
          } else {
            setloading(false)
            setVerfiErrorMesg(true);
            seterrorresverify(res.data.msg);
          }

        })
        .catch(function (error) {
         //console.log(error);
          // setVerfiErrorMesg(true);
          // seterrorresverify(res.data.msg);

        })
        .finally(function () {

         //console.log('complete');
        });
    }
  };

    // save tracker
    const tracker = async (GMSISDN) => {
      var datag = {};
      if (localStorage.getItem('tracker')) {
        var op_id = 'QMGmvVF9Vb';
  
  
        datag = {
          key: 'parameter',
          company: localStorage.getItem("trackerCompany"),
          value: localStorage.getItem('tracker'),
          identifierKey: 'id',
          identifierValue: op_id,
          phoneNum: GMSISDN
        };
       //console.log(datag)
  
      }
      await saveTracker(datag);
  
    }


  const plansOptions = plans.map((c, k) => {
    return (
      <option key={`plan-${k}`} value={c.plan}>
        {c.name}
      </option>
    );
  });

  return (
    <OoredoopsSignupPageDisplay className="" >
      {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorMesgSubscribe}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{errorresverify}</Trans>
        </FloatingAlert>
      ) : null}

      {loading ?
        <div>
          <div className="spinerC">
            <div className="spinner"></div>
          </div>
        </div>
        : ""}


      <div className='operatorSignup operatorSignupGamez'>
        <div className="formsLayout formsLayoutgamezorid formsLayoutniger">

          <div className='headerooredoo'>
            <Link to="/">
              <div className='logogamezroid logo' />
            </Link>
          </div>


          {!showverif ?
            <>
              <div className="links-Section links-SectionOredoo">

                <h3 style={{ paddingBottom: '1px' }} className="gamezoridcontent" id="gamezoridcon">
                  <Trans>Your content is ready!</Trans>
                </h3>


                <div className="play"></div>
                <form>
                  <div>
                    <input
                      type="text"
                      style={{ marginTop: '16px' }}
                      className="input"
                      placeholder={t("972xxxxxxxx")}
                      value={activeData}
                      disabled={checkPhone == true}
                      onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}

                    />
                    <br></br>
                    <br></br>
                    <button
                      className="btn gamezroid btn_gamezroid"
                      disabled={activeData ? "" : "disabled"}
                      onClick={(e) => {
                        Subscribe(e);
                        // setShowverif(true)
                      }} >
                      {t("Subscribe")}
                    </button>

                  </div>
                </form>
                <br></br>

                <div className="row text-center" >
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>98%</span><img src="/assets/images/subscribegamezriod/like.png" alt="img" className="iconsimg" />
                      <p><Trans>evaluation</Trans></p>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>1000+</span><img src="/assets/images/subscribegamezriod/Repeat.png" alt="img" className="iconsimg" />
                      <p><Trans>game</Trans></p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-4 px-5">
                    <div className="games-info">
                      <span>500k+</span><img src="/assets/images/subscribegamezriod/group.png" alt="img" className="iconsimg" />
                      <p><Trans>user</Trans></p>
                    </div>
                  </div>
                
                  <p className="" style={{marginTop:'7px',width:'100%',lineHeight: '20px' ,fontSize:'16px' , fontWeight:'300'}}><Trans>subscribeksaweekly.FREE</Trans> <span style={{fontSize:'18px' , fontWeight:'600'}}><Trans>subscribeksadaily.PRICE_ooredoops</Trans></span> <Trans>subscribeksadaily.AUTO_ooredoo</Trans> <Trans>subscribeksaweekly.AUTO</Trans></p>

                </div>
              </div>
            </>
            : null}




          {showverif ?
            <div className="verfiySection">
              <p><Trans>verifycodeSub</Trans></p>
              <input
                type="text"
                className="input"
                value={verifyCode}
                placeholder={t("Enter_verifycode")}
                onChange={(e) => setVerifyCode(e.target.value.replace(/[^0-9]/g, ""))}

              />
              <br></br>
              <br></br> <br></br>
              <button
                className="btn"
                disabled={verifyCode ? "" : "disabled"}
                onClick={(e) => {
                  verify(e);
                }} >
                {t("Verify")}
              </button>

            </div> : null
          }


          <div className="footer">
            {/* {!showverif ? <div>

            </div> : null} */}
            <div className="ncellTos">
              {/* <h3><Trans>terms_and_conditions</Trans></h3> */}

            </div>
          </div>

        </div>
      </div>








    </OoredoopsSignupPageDisplay>
  );
}
export default withTranslation()(OoredoopsSignupPage)
