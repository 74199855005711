import axios from "axios";
import envo from "../environments/environmentProd";
const apiUrl = envo.apiUrl;
  
function Terms(phone)
{
    try {
      const res = axios.get(`${apiUrl}/Unitel/terms/read?msisdn=${phone}`);
      if (res) {return true;}
    } catch (error) {
       //console.log(error)
        
    }

    

}
  
export default Terms;