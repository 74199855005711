import axios from "axios";
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import GameList from './../../components/GameList/GameList';
import Loader from './../../components/Loader/Loader';
import './FavouritesPage.scss';
import FavouritesPageDisplay from './FavouritesPage.styled';
const apiUrl = envo.apiUrl;
export default class FavouritesPage extends Component {

	constructor() {
		super();
		this.state = {
			myGames: [],
			loading: false,
			noFavs: false,
			errors: false,
			errorMsg: ""
		}
	}

	componentDidMount() {
		this.getMyGames();
	}
	async getMyGames() {
		const options = {
			headers: { AUTHORIZATION: `Bearer ${localStorage.getItem("token")}` }
		  };
		try {
			this.setState({ loading: true });
			const res = await axios.get(`${apiUrl}/playit/mygames`, options);
			if (!res.data) return false;
			const data = res.data;

			if (data.status !== true) {
				return false;
			}
			if (data.status === true) {
				if (data.games.length === 0) {
					return this.setState({
						noFavs: true,
						loading: false
					});
				}
				const favGames = data.games.map(i => {
					return {
						img: i.thumb,
						name: i.name,
						nameAr: i.name_ar,
						id: i.id
					};
				});
				this.setState({
					myGames: favGames,
					loading: false
				});
			}
		} catch (err) {
			if (err.response && err.response.status !== 200) {
				this.setState({
					errors: true,
					errorMsg: this.props.t("network_failed")
				});
			}
			return false;
		}
	}
	render() {
		const { myGames, noFavs, loading, errors, errorMsg } = this.state;
		if (noFavs) {
			return (
				<FavouritesPageDisplay>
					<div className="noResults"><Trans>no_results</Trans><a href="/"><Trans>back_home</Trans></a></div>
				</FavouritesPageDisplay>)
		} else {
			return (
				<>
					{loading ? (
						<Loader type="gamesListing" />
					) : (
							<FavouritesPageDisplay>
								<div>
									<div className="favTitle">
										<Trans>my_games</Trans>
									</div>
								</div>
								<GameList games={myGames} />
								{errors ? <FloatingAlert type="error">{errorMsg} <a className="reload" href=" "><Trans>try_again</Trans></a></FloatingAlert> : ""}
							</FavouritesPageDisplay>
						)}
				</>
			)
		}
	}
}
