import styled from "styled-components";
const NewOaTrendySignupPageDisplay = styled.div`
background: ${p => p.bgImg};
background-size: cover;
.formsLayoutjawal
{
  min-height: 130vh;
}
.gamezonekeyph{
  direction: ltr;
  width: 12%;
  display: inline-block;
  margin-right: 2px;
  font-size: 1rem;
  float: left;
  margin-left: 12%;
  text-align: center;
}
.gamezonenumph{
  width: 54%;
  display: inline;
  float: left;
  text-align:left;
}

.gamezoneSignup
{
  background: rgb(238 90 17);
}
.header_gamezone
{
  margin-top: 5px!important;
  width: 100%;
  border-bottom: 2px solid #65378b;
}
.links-Section{
  position: absolute;
  //border: 1px solid red;

    top: 30%;
}
.links-Sectionjawwalgamezorid
{
  top: 9%!important;
  width: 85%;
  .play
  {

   background: var(--games_icon) center center no-repeat;
   height: 260px;
   background-size: var(--background-size)!important;
  }
  input
  {
    margin-top: 10px;
  }
  .gamezoneInput
  {
    background: white;
    color: black;
    padding: 1px 8px;
  }
  
}



.links-Section a {
  color:white
}
.links-Section button{
  margin-bottom: 15px;
  display:block;
}
.links-Sectionjawwalgamezorid button{
  display: block;
  margin: 30px auto;
  min-width: 190px;
}
.formsLayout {
  margin: 0 20px;
  .verfiySection
  {
    top: 26%!important;
  width: 61%;
  position: absolute;
  }
  .gamezoneLogo
  {
    max-width: 163px!important;
  }
  .header {
    margin-top: 60px;
    .logo {
      background: var(--navbarLogo) center center no-repeat;
      height: var(--loginLogoHeight);
      width: var(--loginLogoWidth);
      max-width: 350px;
      background-size: var(--background-size);
      //background-size: 160px;
      margin-left: 0;
    }
  }
  .inputs {
    select {
      width: 35%;
      height: 43px;
    }
    input {
      width: 50%;
    }
  }
    
  .footer{
    .ncellTos {
      font-size: 13px;
      margin-top: 5px;
      display:flex;
      flex-direction:column;
    }

  }
  .zainiqFooter
    {
      p
      {
        font-size : 12px;
      }
    }
}
`;	export default NewOaTrendySignupPageDisplay;
