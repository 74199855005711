import React, { useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import h from "../../helpers/helpers";
import FloatingAlert from "../FloatingAlert";
import CodeInputs from "./CodeInputs";
import "./ConfirmationCodeComponent.scss";
import ConfirmationDisplay from "./ConfirmationCodeComponent.styled";


function ConfirmationCodeComponent(props) {
  const {
    pageName,
    topHeader,
    actionSuccessEndPoint,
    transText,
    history
  } = props;
  const [alertType, setAlertType] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [codeNumbers, setCodeNumbers] = useState([]);
  const [validCode, setValidCode] = useState(false);
  const handleCodeNumbersChange = codes => {
    if (h.isValideCodeNumbers(codes, 6)) {
      setValidCode(true);
    }
    setCodeNumbers([...codes]);
  };

  async function verify(e) {
    e.preventDefault();
    toggleAlert("error", false, "");
    if (!h.isValideCodeNumbers(codeNumbers, 6)) {
      toggleAlert("error", true, "Please enter the code correctly");
      return;
    }
    const useId = localStorage.getItem("registerUserId");
    const code = codeNumbers.join("");
    const res =
      pageName === "unsubscribe"
        ? await client.unsubscribe(code)
        : await client.verifyCode(useId, code);
    if (res.status) {
      //REVIEW: can't read props of undefined (this)
      return history.push(actionSuccessEndPoint);
    }
    toggleAlert("error", true, `Something went wrong: ${res.message}`);
  }

  async function resendCode() {
    toggleAlert("error", false, "");
    const useId = localStorage.getItem("registerUserId");

    const res = await client.sendVerficationCode(useId);
    if (res.status) {
      toggleAlert("info", true, res.message);
    } else {
      toggleAlert("error", true, res.message);
    }
  }

  const toggleAlert = (type, status, msg) => {
    setAlertMsg(msg);
    setAlertType(type);
    setAlertStatus(status);
  };
  return (
    <ConfirmationDisplay>
      <div className="formPage">
        {/* {topHeader === "navbar" && <Navbar />} */}
        <div className="formsLayout">
          {topHeader === "logo" ? (
            <div className="header">
              <div className="logo" />
            </div>
          ) : (
              <div className="stitle">
                <p><Trans>verfication.unsubscribe_confirmation</Trans></p>
              </div>
            )}
          <div className="center">
            <form
              onSubmit={e => {
                this.login(e);
              }}
            >
              <div className="verificationTxt">
                <p>{transText.verification_code}{" "}</p>
                <p>{localStorage.getItem("registerUserPhone")}</p>
              </div>
              <CodeInputs setCodeNumbers={handleCodeNumbersChange} />
            </form>
          </div>
          {/* TODO: add time for resend */}
          <div className="timer" ></div>
          <div className="resend">
            <p>{transText.didnotRecieve}</p>
            <p className="link" onClick={e => resendCode(e)}>
              {transText.resend_code}
            </p>
          </div>
          <div className="footer">
            <div className="normBtn">
              <button
                className="btn"
                type="submit"
                onClick={e => {
                  verify(e);
                }}
                disabled={validCode ? "" : "disabled"}
              >
                {transText.actionBtn}
              </button>
            </div>
            <p>
              <Trans>{transText.accept}</Trans>{" "}
              <Link to="tos">{transText.terms_and_conditions}</Link>
            </p>
          </div>
        </div>
        {alertStatus && (
          <FloatingAlert type={alertType}>{alertMsg}</FloatingAlert>
        )}
      </div>
    </ConfirmationDisplay>
  );
}
export default withTranslation()(ConfirmationCodeComponent);
