import styled from "styled-components";
const OmentelLoginPageDisplay = styled.div`
  background: ${p => p.bgImg};
  background-size: cover;
  a {
    font-size: 14px;
  }
  .forgotpass {
    display: flex;
    justify-content: flex-end;
  }
  .formsLayout .center .form {
    margin: 30px;
  }
`;
export default OmentelLoginPageDisplay;
