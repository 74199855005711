import React ,{Component}from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./SharedUnsubscribe.scss";
import SharedUnsubscribeDisplay, { Unsubscribe } from "./SharedUnsubscribe.styled";
import { UnsubscribeRedirect } from "./services";

class SharedUnsubscribe extends Component {
  render() {
    const hostName = window.location.hostname;

  return (
    <SharedUnsubscribeDisplay>
      <Unsubscribe>
        <div className="sad"></div>
        <p className="confirmQues">
          <Trans>unsubscribeConfirm.you_want_unsubscribe?</Trans>
        </p>
        <div className="normBtn">
          <Link className="btn" to={"/"}>
            <Trans>back_home</Trans>
          </Link>
        </div>
        <p 
        className={ hostName.includes('jawwal') ? 'unsubscribe link linkJawal' : 'unsubscribe link'}
        onClick={() => UnsubscribeRedirect(hostName,this.props)}>
          <Trans>unsubscribe</Trans>
        </p>
        <p>
        <Trans>{ hostName.includes('asiacell') ? 'unsubscribe_asiacell' : ''}</Trans>
        </p>
        
      </Unsubscribe>
    </SharedUnsubscribeDisplay>
  );
  }
}
export default withTranslation()(SharedUnsubscribe);
