import styled from "styled-components";
const LogoutPageDisplay = styled.div`
height:80vh;
min-height:100vh;
display:flex;
flex-direction:column;
justify-content: center;
 .logoutQues{
	font-size: 18px;
	font-weight: bold;
	line-height: 1.28;
	color:var(--profileTextColor);
 }
`;	export default LogoutPageDisplay;
