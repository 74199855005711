import styled from "styled-components";
const NigeriaSignupPageDisplay = styled.div`
	background: ${p => p.bgImg};
  background-size: cover;
  .operatorSignupGamez {
    min-height: 117vh !important;
}
  .gamezoneSignup
  {
    background: rgb(238 90 17);
  }
  .header_gamezone
  {
    margin-top: 5px!important;
    width: 100%;
    border-bottom: 2px solid #65378b;
  }
  .links-Section{
    position: absolute;
      top: 30%;
      width: 75%;
      h3 {
        font-weight: 200;
    }
  }

 
  
 
  
 
  .links-Section a {
    color:white
  }
  .links-Section button{
    margin-bottom: 15px;
    display:block;
  }
  .links-SectionGamezorid
  {
    top: 9%!important;
    button {
      margin-bottom: 42px;
      display: block;
  }
    .play
    {

     background: var(--play) 50% no-repeat;
     height: 164px;
     background-size: var(--background-size)!important;
    }

   
  }
  

  .formsLayoutniger {
    min-height: 100vh;
    .footer p {
      line-height: 18px;
  }
}
// .formsLayoutniger
//   {
//     min-height: 100vh;
//   }

  .formsLayout {
    margin: 0 20px;
    .header
    {
      margin-top: 5px;
    width: 100%;
    border-bottom: 2px solid rgb(242, 200, 48);
    }
    .verfiySection
    {
      top: 26%!important;
    width: 61%;
    position: absolute;
    }
    .gamezoneLogo
    {
      max-width: 163px!important;
    }
    .inputs {
      select {
        width: 35%;
        height: 43px;
      }
      input {
        width: 50%;
      }
    .footer{
      .ncellTos {
        font-size: 13px;
        margin-top: 20px;
        display:flex;
        flex-direction:column;
      }
    }

    

  }



`;	export default NigeriaSignupPageDisplay;
