import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import "./ConfirmDownload.scss";
import ConfirmDownloadDisplay from "./ConfirmDownload.styled";
class ConfirmDownload extends Component {
  render() {
    return (
      <ConfirmDownloadDisplay >
        <p className="downloadQues">
          <Trans>download.You're_about_download</Trans>
          {this.props.name}
          {/* ...ToDo: Add game Name and (game Size) */}
        </p>
        <div className="normBtn">
          <button
            className="btn"
            onClick={() => {
              window.location.href = this.props.apk;
            }}
          >
            <Trans>download.confirm</Trans>
          </button>
        </div>
        <div>
          <button
            className="btn secondary"
            onClick={() => this.props.btnToggle()}
          >
            <Trans>download.cancel</Trans>
          </button>
        </div>
      </ConfirmDownloadDisplay>
    );
  }
}
export default withTranslation()(ConfirmDownload);
