import styled from "styled-components";
const LoginPageDisplay = styled.div`
  background: ${p => p.bgImg};
  background-size: cover;
  a {
    font-size: 14px;
  }
  .forgotpass {
    display: flex;
    justify-content: flex-end;
  }
  .formsLayout .center .form {
    margin: 30px;
  }
  .number-inputs{
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    width: 100%;
  }
     .countryCode{
  width:25%;
  padding-right: 0px;
  padding-left: 0px;
  margin-left: 4px;
  text-align: center;
  margin-right: 4px;
  }
 
`;
export default LoginPageDisplay;
