import React from "react";
import { Trans } from "react-i18next";

export default function FilterTab(props) {
  const { subTabsInfo, activeSubTab, setActiveSubTab } = props;
  let active = JSON.parse(localStorage.getItem('activeLocally')).main
 ////console.log("sub"  , JSON.parse(localStorage.getItem('activeLocally')).main)
  const subTabs = subTabsInfo.map((i, k) => {
    return (
      <div
        className={`sSubTab ${activeSubTab === i.slug ? "isActive" : ""}`}
        key={`subtab${k}`}
        onClick={() => {
          setActiveSubTab(i.slug);
        }}
      >
        <div>
          <Trans>filtredSubNav.{i.slug}</Trans>
        </div>

      </div>

    );
  });
  return <div className="container"> <div className={`${active == 'online' ? "onlinesubTabs" : "subTabs"}`}>{subTabs}</div> </div>;
}
