import axios from 'axios';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import envo from "../../environments/environmentProd";
import FloatingAlert from './../../components/FloatingAlert/FloatingAlert';
import './helpPage.scss';
import HelpPageDisplay from '../helpPage/helpPage.styled';
import { Link } from 'react-router-dom';
const apiUrl = envo.apiUrl;
const globalurl = window.location.href
const urlgamezroid = window.location.href.includes('ramdan') && !globalurl.includes('asiacell') && !globalurl.includes('jawwal') && !globalurl.includes('zainiq')
class helpPage extends Component {
	constructor() {
		super();
		this.state = {
			content: "",
			errors: false,
			errorMsg: "",
		};
	}

	render() {
		const { errors, errorMsg, content } = this.state;
		return (
			<>
				<HelpPageDisplay >
					<div className="title" style={{ color: urlgamezroid ? '#0a2631eb' : '' }}><Trans>caption</Trans></div>
					{localStorage.getItem('showdouble') == 'true' ?
								<div className="globalcoins" style={{top:'8%'}}>
									<img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
								</div>
								: ""}
					<div className="content">
						<p> <span style={{ color: '#00559F' }}> - </span><Trans>HELP.step1</Trans></p>
						<p> <span style={{ color: '#00559F' }}> - </span> <Trans>HELP.step2</Trans></p>
						<p> <span style={{ color: '#00559F' }}> - </span> <Trans>HELP.step4</Trans></p>
						<p> <span style={{ color: '#00559F' }}> - </span> <Trans>HELP.step5</Trans></p>
						<p> <span style={{ color: '#00559F' }}> - </span> <Trans>HELP.step3</Trans></p>

						<div className="help">
							<p><Trans>HELP.start</Trans></p>
							<p><Trans>HELP.winner</Trans></p>
							<p><span style={{ color: '#00559F' }}> 1- </span> <Trans>HELP.pointsteps.step1</Trans></p>
							<p><span style={{ color: '#00559F' }}> 2- </span> <Trans>HELP.pointsteps.step2</Trans> <Link to ="/packages"><a><Trans>here</Trans> </a></Link> = 50 <Trans>point</Trans></p>
							<p><span style={{ color: '#00559F' }}> 3- </span> <Trans>HELP.pointsteps.step3</Trans> <Link to ="/packages"><a><Trans>here</Trans> </a></Link> = 50 <Trans>point</Trans></p>
							<p><span style={{ color: '#00559F' }}> 4- </span> <Trans>HELP.pointsteps.step4</Trans> <Link to ="/invite"><a><Trans>here</Trans> </a></Link> = 10 <Trans>pointes</Trans></p>
							<p><span style={{ color: '#00559F' }}> 5- </span> <Trans>HELP.pointsteps.step5</Trans> <Link to ="/invite"><a><Trans>here</Trans> </a></Link> <Trans>HELP.pointsteps.earn</Trans> 100 <Trans>point</Trans></p>
						</div>
						<div>
							<p><Trans>HELP.end.endcaption</Trans></p>
							<p><span style={{ color: '#00559F' }}> 1- </span> <Trans>HELP.end.step1</Trans></p>
							<p><span style={{ color: '#00559F' }}> 2- </span> <Trans>HELP.end.step2</Trans></p>
							<p><span style={{ color: '#00559F' }}> 3- </span> <Trans>HELP.end.step3</Trans></p>



						</div>
					</div>


					{errors ? <FloatingAlert type="error">{errorMsg} <a className="reload" href=" "><Trans>try_again</Trans></a></FloatingAlert> : ""}
				</HelpPageDisplay>
			</>
		)
	}
}
export default withTranslation()(helpPage)