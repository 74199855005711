import styled from "styled-components";
import anon from "../../assets/icons/profile/lg-anonymous.svg";
const ProfilePageDisplay = styled.div`
min-height: 99vh!important;
background: var(--insideBg);
background-size: cover;
background-repeat: no-repeat;
position: absolute;
top: 0;
width: 100%;
@media(max-width: 1024px){
  background-position: center;
}

.profileTopcom
{
  background-color: transparent!important;
  min-height: 0!important;
  padding-bottom: 28px!important;
  padding-top: 85px;
 // background-image: none;
 .show {
 .info {
  margin: 30px 0 0!important;
 }
}

}
  .profileTop {
    background-color: var(--primaryColor);
    background-image: linear-gradient(
      to bottom right,
      var(--primaryColor),
      var(--darkPrimaryColor),
      place
    );
    .iiIRpd .profileTop input:placeholder {
      color: red;
     
    }
    min-height: 270px;
    padding-bottom: 30px;
    .edit {
    }
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 140px;
      .avatar {
        width: 140px;
        height: 140px;
        margin-top: 20px;
        position: relative;
      }
    }
    .frame {
      background-color: transparent;
      background-image: linear-gradient(to bottom right, transparent, #ffffff);
      border-radius: 50%;
      width: 150px;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .anon {
      width: 140px;
      height: 140px;
      background: url(${anon});
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
    }
    .imgPreview {
      border-radius: 50%;
      width: 140px;
      height: 140px;
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 3;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0 0;
    }
    input {
      width: 195px;
      border-bottom: 1px solid #fff;
    }
    input:focus {
      opacity: 1;
      color: var(--textColor);
      border-color: #fff;
    }
    .show {
      .info {
        margin: 50px 0 0;
      }
      .name {
        font-size: 20px;
      }
      .email {
        font-size: 17px;
      }
    }

    .button {
      padding: 10px;
      padding-bottom: 0;
      display: flex;
      justify-content: flex-end;
     
    }
    .buttonSubmit
    {
      justify-content: space-around!important;
      width: 90%!important;

    }
  }
`;
export default ProfilePageDisplay;

export const Header = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 20vh;
  //padding: 0 30px 0px;
  width: 100%;
    margin: auto;
    flex-wrap: wrap;

    button
    {
      min-width: 152px;
    border-radius: 7px;
    font-family: 'SourceSansPro-Light';
    text-transform: uppercase;
    }
  .subscribeDate,
  .renewalDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 111px;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.29;
      color: var(--primaryColor);
      padding-bottom: 5px;
      white-space: nowrap;
    }

    .date
    {
      color: var(--profileTextColor);
    }
  }
  .form{
    padding:0 50px 35px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  a:last-child {
    color: #636363;
    padding: 15px 0;
  }
`;


