
import React, { useEffect, useState } from "react";
import "./CompetitionGames.scss";
import CompetitionGamesDisplay from "./CompetitionGames.styled";
import { Link } from "react-router-dom";
import { client } from "../../auth/Client";
import { useHistory } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import envo from "../../environments/environmentProd";
import bgSliders from "../../helpers/bgSliders";
import {checkdouble} from "./services"


const Games = bgSliders.getAllGames();


function CompetitionGames() {

    const history = useHistory();
    const [img, setImg] = useState("");
    const [moreButton, setmoreButton] = useState("");
    const [showDouble, setshowDouble] = useState(false);



    const routpush = (path) => {
        history.push(path);
        window.location.reload();
    }

    useEffect(() => {
        if (localStorage.getItem('i18nextLng') == 'ar') {
            setImg("./assets/images/HomepagecomGamezroid/final-logo.png");
            setmoreButton("url('./assets/images/HomepagecomGamezroid/more-games-ar.png')");
        }

        else {
            setImg("./assets/images/HomepagecomGamezroid/final-logo-en.png");
            setmoreButton("url('./assets/images/HomepagecomGamezroid/more-games.png')");

        }

        checkDouble();
    }, [])


    async function checkDouble() {

        const check = await checkdouble();
       //console.log(check)
        if (check) {
            setshowDouble(true);
        }
       
      }



    const gamesPlay = Games.map(game => {
        return (
            <Link to={`game/${game.id}`}>
                <div className="imgGame" >
                    <img alt="game" src={game.img} />
                    <div className="gamesName gameCompName">
                        {game.name}

                    </div>
                </div>
            </Link>
        )
    })
    return (
        <CompetitionGamesDisplay>

            <div className="container containerComp">

                <div className="links-Section links-SectionCompetition section-home">
                    <div className="compbtn">
                        <div>
                            <Link to='/leaderboard'><button className="leaderboard"></button></Link>
                        </div>
                        <div className="header headerCompetion">
                            <Link to="/">
                                <div className="logo logoComp" />
                            </Link>
                        </div>
                        <div>
                            <Link to='/help'><button className="tos"></button></Link>
                        </div>
                    </div>
                    <div style={{ width: '50px', height: '2vh' }} className="spaceTop"></div>
                    {showDouble ? 
                    <div className="coins">
                        <img src="./assets/images/HomepagecomGamezroid/Z2.png" alt="point" width='75' height="50" />
                    </div> 
                    : ""}

                    <div >
                        <img src={img} alt="play" width='260' height="140" />
                    </div>

                    <div className="btnspage">
                      
                            <button className="btnhomeramdan"  
                             onClick={() => {
                                routpush("/wheel");
                            }}> <p><Trans>wheel</Trans></p></button>
                     
                   
                            <button className="btnhomeramdan"
                            onClick={() => {
                                routpush("/leaderboard");
                            }}
                            
                            ><p><Trans>ownerpoint</Trans></p></button>
                       
                            <button className="btnhomeramdan"
                             onClick={() => {
                                routpush("/packages");
                            }}
                            ><p><Trans>packages</Trans></p></button>

                       


                    </div>

                    <div className="compGames compHomeGames">

                        {gamesPlay}


                    </div>
                    <div style={{ width: '50px', height: '3vh' }}></div>
                    <div>
                        <button className="moreGame"
                            style={{ backgroundImage: moreButton }}
                            onClick={() => {
                                routpush("/home");
                            }}></button>
                    </div>
                </div>

            </div>



        </CompetitionGamesDisplay>



    )

}

export default withTranslation()(CompetitionGames)

