import styled from "styled-components";
const EthVerifyPageDisplay = styled.div`
	background: ${p => p.bgImg};
  background-size: cover;
  //min-height: 152vh;
  .operatorSignupehtio
  {
    min-height: 170vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.85);
    margin: 0;
    padding: 0;

  }
  .formsLayout {
    margin: 0 20px;
    .lang{
      padding-top: 20px;
      align-self: end;
    }
  }
  .formsLayout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .header {
      margin-top: 60px;
      .logo {
        background: var(--loginLogo) center center no-repeat;
        height: 51px;
        width: 160px;
        background-size: 160px;
      }
    }
    .center {
      .form {
        margin: 40px;
        overflow: hidden;
      }
    }
    .footer {
      margin-bottom: 70px;
      p {
        color: var(--textColor);
        font-size: 12px;
      }
    }
    .feature-icon {
      background: url(/assets/icons/feature.svg) center center no-repeat;
      background-size: 13px;
      width: 13px;
      height: 13px;
      display: inline-block;
      padding: 0 5px;
    }
    .inputs {
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      select {
        width: 46%;
        height: 43px;
      }
      input {
        width: 46%;
      }
    }
    .list {
      display: flex;
     // width: 100vw;
      padding: 0 14px;
      box-sizing: border-box;
      flex-wrap: wrap;
      .elm {
        width: 50%;
        text-align: justify;
        margin: 5px 0;
        white-space: nowrap;
        span {
          display: inline-block;
          font-size: 13px;
          margin-left: 10px;
          color: #a4acb5;
        }
      }
    }
    .ethioTos {
      font-size: 13px;
      margin-top: 20px;
    }
  }
  .formsLayoutethio
  {
    min-height: 170vh;
    .header_ethio
    {
      width: 100%;
      border-bottom: 2px solid rgb(242, 200, 48);
          margin-top: 5px !important;
    }

    .links-SectionEthio {
      top: 5% !important;
      width: 91%;
.Contentlang
      {
        text-align: end;
    margin-top: 10px;
      }
      .gamezoridcontent {
        text-align: center;
    }
    .play {
      height: 164px;
      background-size: var(--background-size) !important;
  }
  .footer {
    margin-bottom: 5px;
  }
  }
`; export default EthVerifyPageDisplay;
