import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import "./LanguagePage.scss";
import LanguagePageDisplay, { LangGroup, Title } from "./LanguagePage.styled";

const langs = [
  {
    code: "ar",
    name: "العربية"
  },
  {
    code: "en",
    name: "English"
  },
  {
    code: "fr",
    name: "French"
  },
  {
    code: "pt",
    name: "Portuguese"
  }
];
class LanguagePage extends Component {
  constructor(props) {
    super(props);
    let locallang= localStorage.getItem("i18nextLng");
    this.state = {
      selectedLang: (locallang) ? locallang : "en"
    };
  }

  componentDidMount() {
    //const defaultLang = localStorage.getItem( "i18nextLng");
  if(  document.location.hostname.includes('ao.playit.mobi')){
    this.setState({selectedLang: "pt"} )
    localStorage.setItem("i18nextLng","pt");
  }
  else{
    let locallang= localStorage.getItem("i18nextLng");
    this.setState({
      //selectedLang: defaultLang ? defaultLang : "en";
      selectedLang: (locallang) ? locallang : "en"
    })
    localStorage.setItem("i18nextLng",(locallang) ? locallang : "en");
    ////console.log("lang = en");
  }
   
  }

  changeLanguage(e) {
    e.preventDefault();
    const lang = this.state.selectedLang;
    i18n.changeLanguage(lang);
    // document.body.dir = lang === "en" ? "ltr" : "rtl";
    if( lang === "en" || lang === "fr" ||lang === "pt"  ){
      document.body.dir ="ltr"
    }else{
      document.body.dir ="rtl"
    }
    this.props.history.push('/');
    window.location.reload();

    
  }
  changeSelectedLang(lang) {
    this.setState({
      selectedLang: lang
    });
    localStorage.setItem("i18nextLng",lang);
   //console.log("lang = ",lang);
  }
  render() {
    const { selectedLang } = this.state;
    const languages = langs.map((l, k) => {
      return (
        <LangGroup
          key={`language-${k}`}
          className={selectedLang === l.code ? "selected" : "notSelected"}
          onClick={() => this.changeSelectedLang(l.code)}
        >
          <div className="wrapper">
            <div className="circle" />
            <span className="lang">{l.code}</span>
          </div>
          <p>{l.name}</p>
        </LangGroup>
      );
    });
    return (
      <LanguagePageDisplay>
        <form
          onSubmit={e => {
            this.changeLanguage(e);
          }}
        >
          <Title className="title">
            <Trans>languageChange.select_language</Trans>
          </Title>
          {languages}
          <div className="normBtn">
            <button className="btn">
              <Trans>apply</Trans>
            </button>
          </div>
        </form>
      </LanguagePageDisplay>
    );
  }
}
export default withTranslation()(LanguagePage);
