// eslint-disable-next-line no-unused-vars
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import FloatingAlert from "../../../components/FloatingAlert/FloatingAlert";
import bgSliders from "../../../helpers/bgSliders";
import "./SignupPage.styled";
import YmenSignupPageDisplay from "./SignupPage.styled";
import { setLoginData, getPhoneByHeader } from "../../../helpers/helpers";
import { setAgencyData } from "../../../helpers/agencyTracker";
import { Trans, withTranslation } from "react-i18next";
import { client } from "../../../auth/Client";
import "./YmenSignupPage.scss";

const plans = [
  {
    plan: "d",
    name: "mtndaily2",
  },
  {
    plan: "w",
    name: "mtnWeekly2",
  },
];

function YmenSignupPage({ props, t }) {
  const [activeData, setActiveData] = useState("");
  const [showverif, setShowverif] = useState(false);
  const [verify_msisdn, setVerify_msisdn] = useState("");
  const [errorMesg, setErrorMesg] = useState(false);
  const [signupPlan, setSignupPlan] = useState("d");
  const [hearderPhone, setHearderPhone] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [showHeaderPhone, setshowHeaderPhone] = useState(true);
  const [verify_code, setVerifyCode] = useState("");
  const [verfiErrorMesg, setVerfiErrorMesg] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(false);

  const [errMesg, setErrMesg] = useState("");
  const [waitingMesg, setWaitingMesg] = useState("");
  const [subErrorMesg, setsubErrorMesg] = useState("");
  const [checkPhone, setCheckPhone] = useState(false);

  async function fetchYmn() {
    
    var gamesstore1 = window.location.href.includes("gamesstore.you-tele.com");
    var gamesstore2 = window.location.href.includes("gamesstore.you.com.ye");
    var linkheader = "http://gamesstore.you-tele.com/headercheckyemen.php";
    if(gamesstore2){
      linkheader = "http://gamesstore.you.com.ye/headercheckyemen.php";
    }
    const num = await getPhoneByHeader(linkheader);
   //console.log(num);
    var phone_h = num;
    //setHearderPhone('1245')
    if (phone_h != false) {
      setHearderPhone(phone_h);
      setActiveData(phone_h);
      setshowHeaderPhone(true);
      loginYmnByHeader(phone_h);
      setCheckPhone(true);
    }

    //loginYmnByHeader(123)

    const url = window.location.search;
    if (url !== "") {
     //console.log("tracker");
      setAgencyData(url);
    }
  }

  async function loginYmnByHeader(phone) {
    const dataOject = {
      MSISDNCG: phone,
    };
    const headers = {
      headers: {
        egauthorization: "Basic RWd5cHRBcGk6S3N0cmZXTHc4V0VEOFZHd3d5eQ==",
        "Content-Type": "application/json",
      },
    };

   //console.log(headers, dataOject);

    const res = await axios
      .post(`http://www.api.playit.mobi/api/v2/egylogin`, dataOject, headers)
      .then((res) => {
        if(res.data.status){
          var loged = setLoginData(
            res.data.user_id,
            res.data.token,
            res.data.user_name,
            res.data.photo,
            res.data.operator_id,
            res.data.user_email,
            res.data.msisdn
          );
          if (loged) {
            window.location.href = "/";
          } else {
        }
      }

      })
      .catch((error) => {
       //console.log(error);
      });
  }

  useEffect(() => {
    fetchYmn();

    //subscribeUser();
  }, []);

  const signup = async (e) => {
    e.preventDefault();
    if (activeData) {
      let msisdn = activeData;

      setVerify_msisdn(msisdn);
      setdisabledBtn(true);
      // document.getElementById("btnsub").setAttribute("disabled", "disabled");
      // setWaitingMesg(true);
      var url = "";
      if (checkPhone == true) { 

        url = `http://api.playit.mobi/api/v2/mtnyemn/subscribe?msisdn=${msisdn}&plan=${signupPlan}&headerenrich=yes`

        var company_value = localStorage.getItem("trackerCompany");
        var tracker_value = localStorage.getItem('tracker');
        if (tracker_value != "") {
          url = `http://api.playit.mobi/api/v2/mtnyemn/subscribe?msisdn=${msisdn}&plan=${signupPlan}&headerenrich=yes&company_value=${company_value}&tracker_value=${tracker_value}`
        }
        axios.get(url)
          .then(res => {
           //console.log(res);
            if (res.data.status == true) {
              if (res.data.userId) {
                //tracker(msisdn);
                setLoginData(res.data.loginData.user_id, res.data.loginData.token, res.data.loginData.user_name, res.data.loginData.photo, res.data.loginData.operator_id, res.data.loginData.user_email, res.data.loginData.msisdn);
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');
              }
              else {
                setTimeout(() => {
                  window.location.href = "/";
                }, 5000);
                //history.push('/thank-gamezone');
              }
            } else {
              setsubErrorMesg(res.data.message);
              setErrorMesg(true)
              //window.location.href ="/login"
            }

            setshowLoading(false);

          })
          .catch(function (error) {
            setErrorMesg(true)
            setShowverif(false)
            setshowLoading(false);

          })
          .finally(function () {
           //console.log('complete');
          });

      }
      else {
        url = `http://api.playit.mobi/api/v2/mtnyemn/subscribe?msisdn=${msisdn}&plan=${signupPlan}&headerenrich=no`
        axios.get(url)
          .then(res => {
           //console.log(res.data.status);
            if (res.data.status == true) {
              setShowverif(true)
              setErrorMesg(false)
            } else {
              setsubErrorMesg(res.data.message);
              setErrorMesg(true)
              //setShowverif(true);
              //window.location.href = "/login"
            }
            setshowLoading(false);



          })
          .catch(function (error) {
            setErrorMesg(true)
            setShowverif(false)
           setshowLoading(false);

          })
          .finally(function () {
           //console.log('complete');
          });


      }

    }
  };
 

  const plansOptions = plans.map((c, k) => {
   //console.log('plan',c.plan , c.name)

    return (
      <div id="radiodiv"> <input class="inputradio" type="radio" key={`plan-${k}`} name='planval' checked={(c.plan===signupPlan)}  onChange={(e) => setSignupPlan(e.target.value)} value={c.plan}   />{t(c.name)}</div>
    );
  });

  const verify = async (e) => {
    e.preventDefault();
    ////console.log("task" , verify_msisdn)
    if (verify_code) {
      setshowLoading(true);
      const res = await client.verifyCodeyemen(
        verify_msisdn,
        signupPlan,
        verify_code
      );
      if (res.status) {
        if (res.user_id) {
          // tracker(verify_msisdn);
          setLoginData(
            res.user_id,
            res.token,
            res.user_name,
            res.photo,
            res.operator_id,
            res.msisdn
          );
        }
        setVerfiErrorMesg(false);
        setshowLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      } else {
        setshowLoading(false);
        setVerfiErrorMesg(true);
        setErrMesg(res.msg);
      }
    }
  };

  return (
    <YmenSignupPageDisplay className="">
      {/* {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>register.SUBSCRIBEError</Trans>
        </FloatingAlert>
      ) : null} */}

      {/* {alert && (
        <FloatingAlert type={"danger"}>
          In order to signup please turn your mobile data.
        </FloatingAlert>
      )} */}

      {errorMesg ? (
        <FloatingAlert type={"error"}>
          <Trans>{subErrorMesg}</Trans>
        </FloatingAlert>
      ) : null}

      {verfiErrorMesg ? (
        <FloatingAlert type={"error"}>
          {/* <Trans>register.VERIFYERROR</Trans> */}
          <Trans> {errMesg} </Trans>
        </FloatingAlert>
      ) : null}
      {waitingMesg ? (
        <FloatingAlert>
          <Trans>sendCode</Trans>
        </FloatingAlert>
      ) : null}

      <div className="operatorSignup operatorSignupMtn">
        <div className="formsLayout formsLayoutMtn">
          <div className="header headerSignupMtn">
            <Link to="/">
              <div className="logo" />
            </Link>
          </div>
          {!showverif ? (
            <>
              <div className="links-Section links-Sectionmtn">
                <form>
                  <div>
                    {/* <p className={hearderPhone ? 'phone': '' } >{hearderPhone}</p> */}
                    <div className="inputs">
                      {/* <select
                        className="select selectUmnia selectMtn2"
                        defaultValue={signupPlan}
                        onChange={(e) => setSignupPlan(e.target.value)}
                      >
                        {plansOptions}
                      </select> */}
                      {plansOptions}
                      
                      <input
                        type="text"
                        className="input inputumnia"
                        placeholder={t("Enter your phone number")}
                        disabled={checkPhone == true}
                        value={activeData}
                        onChange={(e) => setActiveData(e.target.value.replace(/[^0-9]/g, ""))}
                      />
                    </div>


                    {/* 
                    {showHeaderPhone ? hearderPhone : activeData}
                    <div className="iconSelect">
                    <select
                      className="select selectMtn"
                      defaultValue={signupPlan}
                      onChange={e => setSignupPlan(e.target.value)}
                    >
                      {plansOptions}
                      
                    </select>
                  </div> */}
                  </div>
                </form>

                <div>
                  <button
                    id="btnsub"
                    className="btn gamezroid btn_gamezroid btn_mtn"
                    onClick={(e) => {
                      signup(e);
                    }}
                  >
                    <Trans>Signup</Trans>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="links-Section links-Sectionmtn links-Sectionmtnverify">
                <form>
                  <div>
                    <div className="inputs">
                      <input
                        type="text"
                        className="input inputumnia"
                        value={verify_code}
                        id="verifyInput"
                        maxLength="6"
                        placeholder={t("Enter_verifycode")}
                        onChange={(e) => setVerifyCode(e.target.value)}
                      />
                      <br></br>
                      <br></br>

                      <button
                        className="btn gamezroid btn_gamezroid btn_mtn"
                        disabled={activeData ? "" : "disabled"}
                        onClick={(e) => {
                          verify(e);
                        }}
                      >
                        <Trans>Verify_code</Trans>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showLoading ? (
            <div className="load">
              <div className="spinerC">
                <div className="spinner"></div>
              </div>
            </div>
          ) : null}

          <div className="footer footerMtn">
            <div className="ncellTos ncellTosmtn">
              <p>
                <Trans>terms_and_conditions</Trans>
              </p>
              <p>
                <Trans>condtionMtn.condtion1</Trans>
              </p>
              <p>
                <Trans>condtionMtn.condtion2</Trans>
              </p>
              <p>
                <Trans>condtionMtn.condtion3</Trans>
              </p>
              <p>
                <Trans>condtionMtn.condtion4</Trans>
              </p>
              <p>
                <Trans>condtionMtn.condtion5</Trans>
              </p>
              {/* <p>
                <Trans>condtionMtn.condtion6</Trans>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </YmenSignupPageDisplay>
  );
}
export default withTranslation()(YmenSignupPage);
